import { gql, useQuery } from "@apollo/client";
import { useMemo } from "react";

export interface GroupAccessDetails {
  name: string;
  created: string;
  groupId: string;
  animalCount: number;
  users: GroupUserAccess[];
  organisation: {
    name: string;
    orgId: string;
    vetIntegration?: string;
  };
}

export interface GroupUserAccess {
  access: string;
  created: string;
  user: {
    email: string;
    username: string;
  };
}

export const adminGetGroupDetailsQuery = `
query GetGroupDetails($groupId: ID!) {
  adminGetGroup(groupId: $groupId) {
    groupId
    created
    name
    users {
      access
      user {
        email
        username
      }
      created
    }
    animalCount
    updated
    organisation {
      name
      orgId
      vetIntegration
    }
  }
}
`;

export const useGroupDetails = (groupId?: string, userId?: string) => {
  const { data, refetch, ...query } = useQuery(gql(adminGetGroupDetailsQuery), {
    variables: { groupId },
  });

  const groupDetails: GroupAccessDetails = useMemo(
    () => data?.adminGetGroup,
    [data]
  );
  return { groupDetails, ...query, refresh: refetch };
};
