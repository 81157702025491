import React, { FC, PropsWithChildren } from "react";
import { Box, BoxProps, Link, Tooltip, Typography } from "@mui/material";

import {
  LogRecordChange,
  LogRecord,
  LogRecordObjectState,
  LogUser,
  LogObjectType,
  LinkOrgVetLinkLogRecord,
  LinkAnimalVetLinkLogRecord,
  AccessLevel,
} from "./LogRecord";
import {
  Add,
  Delete,
  Edit,
  QuestionMark,
  Person,
  ArrowForward,
  Pets,
  Apartment,
  Group,
  AddLink,
  LinkOff,
  SupervisorAccount,
} from "@mui/icons-material";

export const LogObjectRow: FC<{ log: LogRecord }> = ({ log }) => (
  <Box display="flex" justifyContent="stretch" width="1600px">
    <LogUserCell user={log.user} />
    <LogAction action={log.action} />
    <LogRowBody log={log} />
    <LogTimestamp timestamp={log.timestamp} />
  </Box>
);

export const LogUserRow: FC<{ log: LogRecord }> = ({ log }) => (
  <Box display="flex" justifyContent="stretch" width="1600px">
    <LogObject type={log.object} id={log.objectId} />
    <LogAction action={log.action} />
    <LogRowBody log={log} />
    <LogTimestamp timestamp={log.timestamp} />
  </Box>
);

const LogCell: FC<{ grow?: boolean; width?: number } & BoxProps> = ({
  grow = false,
  width = 12,
  children,
  ...props
}) => (
  <Box
    flex={`${grow ? 1 : 0} 0 ${width}rem`}
    borderTop="1px solid rgba(0, 0, 0, 0.12)"
    overflow={grow ? "auto" : "hidden"}
    textOverflow="ellipsis"
    paddingY={2}
    paddingX={1}
    {...props}
  >
    {children}
  </Box>
);

const LogRowBody: FC<{ log: LogRecord }> = ({ log }) => {
  switch (log.action) {
    case "link-animal":
    case "unlink-animal":
      return (
        <LogBodyCell>
          <LogObjectLink type="animal" id={log.log.animalId} />
          Access: <em>{log.log.access}</em>
        </LogBodyCell>
      );
    case "link-user":
    case "unlink-user":
      return (
        <LogUserLinkCell
          type={log.object}
          id={log.log.userId}
          access={log.log.access}
        />
      );
    case "link-group":
    case "unlink-group":
      return (
        <LogBodyCell>
          <LogObjectLink type="group" id={log.log.groupId} />
          {"access" in log.log && (
            <>
              Access: <em>{log.log.access}</em>
            </>
          )}
        </LogBodyCell>
      );
    case "add-vet-link":
      return <LogVetLink log={log} />;
    case "remove-vet-link":
      return <LogVetLink log={log} remove />;
    case "update-animal":
    case "update-group":
    case "update-org":
      return <LogChangeCell log={log.log} />;
    case "create-animal":
    case "create-group":
    case "create-org":
    case "remove-animal":
    case "remove-group":
    case "remove-org":
      return <LogStateCell log={log.log} />;
    default:
      return <LogCell grow width={52} />;
  }
};

const LogTimestamp: FC<{ timestamp: string }> = ({ timestamp }) => {
  const date = new Date(timestamp);
  return (
    <LogCell width={15}>
      <Tooltip title={date.toUTCString()}>
        <Typography>{date.toLocaleString()}</Typography>
      </Tooltip>
    </LogCell>
  );
};

const LogObjectIcon: FC<{ type: LogObjectType }> = ({ type }) => {
  switch (type) {
    case "animal":
      return <Pets color="inherit" sx={{ marginRight: "0.25rem" }} />;
    case "group":
      return <Group color="inherit" sx={{ marginRight: "0.25rem" }} />;
    case "org":
      return <Apartment color="inherit" sx={{ marginRight: "0.25rem" }} />;
  }
};

const LogObjectLink: FC<{ type: "animal" | "group" | "org"; id: string }> = ({
  type,
  id,
}) => {
  switch (type) {
    case "animal":
      return (
        <Link
          href={`/implant/${id}`}
          underline="none"
          display="flex"
          sx={{ alignContent: "center" }}
        >
          <LogObjectIcon type={type} />
          {id}
        </Link>
      );
    case "group":
      return (
        <Link
          href={`/groups/${id}`}
          underline="none"
          display="flex"
          sx={{ alignContent: "center" }}
        >
          <LogObjectIcon type={type} />
          {id}
        </Link>
      );
    case "org":
      return (
        <Link
          href={`/orgs/${id}`}
          underline="none"
          display="flex"
          sx={{ alignContent: "center" }}
        >
          <LogObjectIcon type={type} />
          {id}
        </Link>
      );
    default:
      return <span>{id}</span>;
  }
};

const LogObject: FC<{ type: "animal" | "group" | "org"; id: string }> = ({
  type,
  id,
}) => (
  <LogCell width={17}>
    <LogObjectLink type={type} id={id} />
    {type}
  </LogCell>
);

const LogUserLink: FC<{ user: LogUser }> = ({ user }) =>
  user.userpool === "admin" ? (
    <Typography color="action">
      <SupervisorAccount color="inherit" sx={{ marginRight: "0.25rem" }} />{" "}
      {"Admin"}
    </Typography>
  ) : (
    <Link
      href={`/users/${user.userpool}/${user.username}/${user.email}`}
      underline="none"
      display="flex"
      sx={{ alignContent: "center" }}
    >
      <Person color="inherit" sx={{ marginRight: "0.25rem" }} /> {user.email}
    </Link>
  );

const LogUserCell: FC<{ user: LogUser }> = ({ user }) => (
  <LogCell width={17}>
    <LogUserLink user={user} />
  </LogCell>
);

const LogActionIcon: FC<{ action: string }> = ({ action }) => {
  switch (action) {
    case "link-animal":
    case "link-user":
    case "link-group":
      return <AddLink color="action" sx={{ marginRight: "0.25rem" }} />;
    case "unlink-animal":
    case "unlink-user":
    case "unlink-group":
      return <LinkOff color="action" sx={{ marginRight: "0.25rem" }} />;
    case "update-animal":
    case "update-group":
    case "update-org":
      return <Edit color="action" sx={{ marginRight: "0.25rem" }} />;
    case "create-animal":
    case "create-group":
    case "create-org":
    case "add-vet-link":
      return <Add color="action" sx={{ marginRight: "0.25rem" }} />;
    case "remove-animal":
    case "remove-group":
    case "remove-org":
    case "remove-vet-link":
      return <Delete color="action" sx={{ marginRight: "0.25rem" }} />;
    default:
      return <QuestionMark />;
  }
};

const LogActionText: FC<{ action: string }> = ({ action }) => {
  switch (action) {
    case "link-animal":
      return (
        <Typography color="action" component="span">
          Link Animal
        </Typography>
      );
    case "link-user":
      return (
        <Typography color="action" component="span">
          Link User
        </Typography>
      );
    case "link-group":
      return (
        <Typography color="action" component="span">
          Link Group
        </Typography>
      );
    case "unlink-animal":
      return (
        <Typography color="action" component="span">
          Unlink Animal
        </Typography>
      );
    case "unlink-user":
      return (
        <Typography color="action" component="span">
          Unlink User
        </Typography>
      );
    case "unlink-group":
      return (
        <Typography color="action" component="span">
          Unlink Group
        </Typography>
      );
    case "add-vet-link":
    case "remove-vet-link":
      return (
        <Typography color="action" component="span">
          Vet Link
        </Typography>
      );
    case "create-animal":
      return (
        <Typography color="action" component="span">
          Create Animal
        </Typography>
      );
    case "update-animal":
      return (
        <Typography color="action" component="span">
          Edit Animal
        </Typography>
      );
    case "remove-animal":
      return (
        <Typography color="action" component="span">
          Remove Animal
        </Typography>
      );
    case "create-group":
      return (
        <Typography color="action" component="span">
          Create Group
        </Typography>
      );
    case "update-group":
      return (
        <Typography color="action" component="span">
          Edit Group
        </Typography>
      );
    case "remove-group":
      return (
        <Typography color="action" component="span">
          Remove Group
        </Typography>
      );
    case "create-org":
      return (
        <Typography color="action" component="span">
          Create Org
        </Typography>
      );
    case "update-org":
      return (
        <Typography color="action" component="span">
          Edit Org
        </Typography>
      );
    case "remove-org":
      return (
        <Typography color="action" component="span">
          Remove Org
        </Typography>
      );
    default:
      return (
        <Typography color="action" component="span">
          {action}
        </Typography>
      );
  }
};

const LogAction: FC<{ action: string }> = ({ action }) => (
  <LogCell width={10} display="flex" alignContent="center">
    <LogActionIcon action={action} />
    <LogActionText action={action} />
  </LogCell>
);

const LogFieldLabel: FC<PropsWithChildren> = ({ children }) => (
  <Box
    flex="0 0 9rem"
    overflow="hidden"
    sx={{ textOverflow: "ellipsis" }}
    paddingX="0.5rem"
  >
    {children}
  </Box>
);

const LogFieldValue: FC<{ wide?: boolean } & PropsWithChildren> = ({
  wide = false,
  children,
}) => (
  <Box
    flex={`0 0 ${wide ? "42" : "19"}rem`}
    overflow="hidden"
    sx={{ textOverflow: "ellipsis" }}
    paddingX="0.5rem"
  >
    {children}
  </Box>
);

const LogFieldChangeValue: FC<PropsWithChildren> = ({ children }) => (
  <Box
    flex="0 0 19rem"
    overflow="hidden"
    sx={{ textOverflow: "ellipsis" }}
    paddingX="0.5rem"
  >
    {children}
  </Box>
);

const LogFieldChangeIcon: FC = () => (
  <ArrowForward display="block" sx={{ flex: "0 0 2rem" }} />
);

const LogChangeCell: FC<{ log: LogRecordChange }> = ({ log }) => {
  const keys = Object.keys(log).sort();
  return (
    <LogCell
      grow
      width={52}
      sx={{
        "> :nth-child(odd)": {
          backgroundColor: (theme) =>
            theme.palette.mode === "light" ? "#def" : "#234",
        },
      }}
    >
      {keys.map((key) => (
        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          lineHeight={2}
          key={key}
        >
          <LogFieldLabel>{key}</LogFieldLabel>
          <LogFieldChangeValue>
            {log[key].before != null ? (
              JSON.stringify(log[key].before)
            ) : (
              <em>null</em>
            )}
          </LogFieldChangeValue>
          <LogFieldChangeIcon />
          <LogFieldChangeValue>
            {log[key].after != null ? (
              JSON.stringify(log[key].after, null, 2)
            ) : (
              <em>null</em>
            )}
          </LogFieldChangeValue>
        </Box>
      ))}
    </LogCell>
  );
};

const LogStateCell: FC<{ log: LogRecordObjectState }> = ({ log }) => {
  const keys = Object.keys(log).sort();
  return (
    <LogCell
      grow
      width={52}
      sx={{
        "> :nth-child(odd)": {
          backgroundColor: (theme) =>
            theme.palette.mode === "light" ? "#def" : "#234",
        },
      }}
    >
      {keys.map((key) => (
        <Box
          display="flex"
          justifyContent="flex-start"
          lineHeight={2}
          key={key}
        >
          <LogFieldLabel>{key}</LogFieldLabel>
          <LogFieldValue>{JSON.stringify(log[key])}</LogFieldValue>
        </Box>
      ))}
    </LogCell>
  );
};

const LogVetLink: FC<{
  log: LinkOrgVetLinkLogRecord | LinkAnimalVetLinkLogRecord;
  remove?: boolean;
}> = ({ log, remove = false }) => {
  const vetId = (
    <strong>
      <em>{log.log.vetId}</em>
    </strong>
  );
  if ("orgId" in log.log) {
    return (
      <LogBodyCell>
        <LogObjectLink type="org" id={log.log.orgId} />
        {remove ? <s>{vetId}</s> : vetId}
      </LogBodyCell>
    );
  }
  if ("animalId" in log.log) {
    return (
      <LogBodyCell>
        <LogObjectLink type="animal" id={log.log.animalId} />
        {remove ? <s>{vetId}</s> : vetId}
      </LogBodyCell>
    );
  }
  return null;
};

const LogUserLinkCell: FC<{
  id: string;
  type: LogObjectType;
  access: AccessLevel;
}> = ({ id, type, access }) => {
  let userpool = type === "animal" ? "consumer" : "professional";
  return (
    <LogBodyCell>
      <LogUserLink
        user={{
          userpool,
          username: id,
          email: id,
        }}
      />
      <br />
      Access:{" "}
      <strong>
        <em>{access}</em>
      </strong>
    </LogBodyCell>
  );
};

const LogBodyCell: FC<PropsWithChildren> = ({ children }) => {
  return (
    <LogCell grow width={52}>
      {children}
    </LogCell>
  );
};
