import {
  Box,
  Grid,
  Skeleton,
  Stack,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import BatteryGauge from "react-battery-gauge";
import { useAnimalCurrentReadings } from "../../hooks/animal/use-animal-current-readings";
import { AnimalReading } from "../../hooks/animal/types";
import { formatDate, formatRelativeDate, numberFormat } from "../Utils";
import Geohash from "latlon-geohash";
import { useAdminGetImplantFirmware } from "../../hooks/firmware/use-admin-get-implant-firmware";

export const ImplantStatus = (
  { implantId }: { implantId: string },
  { lastReadings }: { lastReadings: AnimalReading[] },
) => {
  const { loading, readings } = useAnimalCurrentReadings(implantId);
  const { firmware, loading: loadingFirmware } = useAdminGetImplantFirmware(
    implantId,
  );

  const theme: Theme = useTheme();

  const versionString = firmware != null
    ? `${firmware?.version}|${firmware?.hci}|${firmware?.lmp}`
    : null;

  return (
    <Box style={{ padding: 2 }}>
      <Stack direction="row" textAlign="center">
        <Typography variant="h6" color="inherit" textAlign="center">
          Implant Status
        </Typography>
      </Stack>

      {loading || loadingFirmware
        ? (
          <Stack direction="row">
            <Box sx={{ width: "50%" }}>
              <Skeleton sx={{ m: 2, mt: 2 }} />
              <Skeleton sx={{ m: 2 }} />
              <Skeleton sx={{ m: 2 }} />
              <Skeleton sx={{ m: 2 }} />
              <Skeleton sx={{ m: 2 }} />
            </Box>
            <Box sx={{ width: "50%" }}>
              <Skeleton sx={{ m: 2, mt: 2 }} />
              <Skeleton sx={{ m: 2 }} />
              <Skeleton sx={{ m: 2 }} />
              <Skeleton sx={{ m: 2 }} />
              <Skeleton sx={{ m: 2 }} />
            </Box>
          </Stack>
        )
        : (
          <Stack alignItems="left" sx={{ p: 2 }} spacing={1}>
            <BatteryGauge
              value={readings?.find((reading) =>
                reading?.type === "batteryPercent"
              )
                ?.floatValue ?? 0}
              size={90}
              aspectRatio={0.28}
              customization={{
                batteryBody: {
                  strokeColor: theme.palette.mode === "dark"
                    ? "white"
                    : "black",
                  strokeWidth: 1.5,
                  cornerRadius: 2,
                },
                batteryCap: {
                  strokeColor: theme.palette.mode === "dark"
                    ? "white"
                    : "black",
                  cornerRadius: 1,
                  strokeWidth: 1.5,
                  capToBodyRatio: 0.3,
                },
                readingText: {
                  lightContrastColor: theme.palette.mode === "dark"
                    ? "white"
                    : "black",
                  darkContrastColor: theme.palette.mode === "light"
                    ? "white"
                    : "black",
                  lowBatteryColor: "red",
                  fontFamily: "Helvetica",
                  fontSize: 14,
                  showPercentage: true,
                },
                batteryMeter: {
                  fill: "#84c46a",
                  lowBatteryValue: 15,
                  lowBatteryFill: "#e8473f",
                  outerGap: 1,
                  noOfCells: 1, // more than 1, will create cell battery
                  interCellsGap: 1,
                },
              }}
            />
            <Grid container spacing={0.5}>
              <Grid item xs={4}>
                <Typography variant="body2" color="inherit" align="left">
                  Heart Rate:{" "}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2">
                  {numberFormat(
                    readings?.find((reading) => reading?.type === "heartRate")
                      ?.floatValue,
                    0,
                    " bpm",
                  )}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2">
                  {formatRelativeDate(
                    readings?.find((reading) => reading?.type === "heartRate")
                      ?.datetime,
                  )}
                </Typography>
              </Grid>

               <Grid item xs={4}>
                <Typography variant="body2" color="inherit" align="left">
                  HRV:{" "}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2">
                  {numberFormat(
                    readings?.find((reading) => reading?.type === "heartRateVariance")
                      ?.floatValue,
                    0,
                    " ms",
                  )}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2">
                  {formatRelativeDate(
                    readings?.find((reading) => reading?.type === "heartRate")
                      ?.datetime,
                  )}
                </Typography>
              </Grid>


              <Grid item xs={4}>
                <Typography variant="body2" color="inherit" align="left">
                  Resp. Rate:{" "}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2">
                  {numberFormat(
                    readings?.find(
                      (reading) => reading?.type === "respiratoryRate",
                    )?.floatValue,
                    0,
                    " bpm",
                  )}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2">
                  {formatRelativeDate(
                    readings?.find(
                      (reading) => reading?.type === "respiratoryRate",
                    )?.datetime,
                  )}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2" color="inherit" align="left">
                  Temperature:{" "}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2">
                  {numberFormat(
                    readings?.find((reading) => reading?.type === "temperature")
                      ?.floatValue,
                    2,
                    "° C",
                  )}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2">
                  {formatRelativeDate(
                    readings?.find((reading) => reading?.type === "temperature")
                      ?.datetime,
                  )}
                </Typography>
              </Grid>

              <Grid item xs={4}>
                <Typography variant="body2" color="inherit" align="left">
                  Location:{" "}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body1">
                  {((value?: string) => {
                    if (value == null || value.trim() === "") return "-";
                    const geo = Geohash.decode(value);
                    if (geo.lat && geo.lon) {
                      return (
                        <a
                          href={`https://maps.google.com?q=${geo.lat},${geo.lon}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {`${geo.lat.toFixed(3)},${geo.lon.toFixed(3)}`}
                        </a>
                      );
                    }
                    return "<invalid>";
                  })(
                    readings?.find((reading) => reading?.type === "location")
                      ?.stringValue,
                  )}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2">
                  {formatRelativeDate(
                    readings?.find((reading) => reading?.type === "location")
                      ?.datetime,
                  )}
                </Typography>
              </Grid>
              {/* </Grid> */}
            </Grid>

            <Stack direction="row" justifyContent="space-between">
              <Typography
                variant="body2"
                color="inherit"
                align="left"
                paddingLeft={0.5}
              >
                Installed Firmware:{"\t"}
              </Typography>
              <Box>
                <Typography
                  variant="body2"
                  color="inherit"
                  align="right"
                  paddingLeft={2}
                >
                  {readings?.find(
                    (reading) => reading?.type === "softwareVersion",
                  )?.stringValue}
                </Typography>
                <Typography
                  variant="subtitle2"
                  color="inherit"
                  align="right"
                  paddingLeft={2}
                >
                  {formatDate(
                    readings?.find(
                      (reading) => reading?.type === "softwareVersion",
                    )?.datetime,
                  )}
                </Typography>
              </Box>
            </Stack>

            <Stack direction="row" justifyContent="space-between">
              <Typography
                variant="body2"
                color="inherit"
                align="left"
                paddingLeft={0.5}
              >
                Target Firmware:{"\t"}
              </Typography>
              <Box>
                {versionString != null
                  ? (
                    <Typography
                      variant="body2"
                      color="inherit"
                      align="right"
                      paddingLeft={2}
                    >
                      {versionString ===
                        readings?.find(
                          (reading) => reading?.type === "softwareVersion",
                        )?.stringValue
                        ? "Up to date"
                        : versionString}
                    </Typography>
                  )
                  : (
                    <Typography
                      variant="body2"
                      color="inherit"
                      align="right"
                      paddingLeft={2}
                    >
                      Latest
                    </Typography>
                  )}
              </Box>
            </Stack>
          </Stack>
        )}
    </Box>
  );
};
