import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import { FC } from "react";
import { useAdminUpdateFirmwareStatus } from "../../hooks/firmware/use-admin-update-firmware-status";

export const SetFirmwareStatus: FC<{
  version: string;
  onDone: (data: any) => void;
}> = ({ version, onDone }) => {
  // const [newStatus, setNewStatus] = useState("");
  const { execute } = useAdminUpdateFirmwareStatus(onDone);

  return (
    <Stack spacing={1} justifyContent={"center"}>
      <Typography variant="body1">
        Select status for firmware version {<b>{version}</b>}
      </Typography>
      <Box>
        <Stack spacing={1} sx={{ p: 2 }} alignItems={"center"}>
          <Button
            color="info"
            onClick={() => {
              execute({ variables: { version, status: "pending" } });
            }}
          >
            Pending
          </Button>
          <Button
            color="info"
            onClick={() => {
              execute({ variables: { version, status: "testing" } });
            }}
          >
            Testing
          </Button>
          <Button
            color="success"
            onClick={() => {
              execute({ variables: { version, status: "release" } });
            }}
          >
            Release
          </Button>
          <Button
            color="warning"
            onClick={() => {
              execute({ variables: { version, status: "deprecated" } });
            }}
          >
            Deprecated
          </Button>

          <TextField
            onChange={(event) => {}}
            id="custom-status-input"
            label="Custom Status"
            type="text"
          />
        </Stack>
      </Box>
    </Stack>
  );
};
