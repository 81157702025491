import {
  DataGrid,
  GridColDef,
  GridLinkOperator,
  GridRenderCellParams,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import {
  Box,
  Button,
  Chip,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { formatDate } from "../Utils";
import {
  ClearAll,
  LocalHospitalOutlined,
  NavigateNext,
} from "@mui/icons-material";
import { usePrevOrgs } from "../../hooks/use-prev-orgs";

export const orgColumns: GridColDef[] = [
  {
    field: "name",
    headerName: "Organisation Name",
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography variant="body2" sx={{ cursor: "pointer" }}>
          {params.value}
        </Typography>
      );
    },
  },
  {
    field: "vetIntegration",
    headerName: "Vet Integration",
    flex: 0.5,
    align: "center",
    headerAlign: "center",
    renderCell: (params: GridRenderCellParams) => {
      if (params.value === "EzyVet") {
        return (
          <Tooltip title="This group is linked to EzyVet">
            <Chip
              icon={<LocalHospitalOutlined />}
              color="info"
              label="EzyVet"
              variant="outlined"
              sx={{ cursor: "pointer" }}
            />
          </Tooltip>
        );
      } else {
        <Typography>N/A</Typography>;
      }
      return null;
    },
  },
  {
    field: "created",
    headerName: "Created",
    flex: 0.8,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography variant="body2" sx={{ cursor: "pointer" }}>
          {formatDate(params.value)}
        </Typography>
      );
    },
  },
  {
    field: "updated",
    headerName: "Updated",
    flex: 0.8,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography variant="body2" sx={{ cursor: "pointer" }}>
          {formatDate(params.value)}
        </Typography>
      );
    },
  },
];

export default function Orgs() {
  const [searchId, setSearchId] = useState("");

  let navigate = useNavigate();
  const { prevOrgs, clear } = usePrevOrgs();

  const handleSearchSubmit = async (search: string) => {
    if (search === null || search === "") {
      return;
    }
    navigate({ pathname: `/orgs/${search}` }, { replace: false });
  };

  function QuickSearchToolbar() {
    return (
      <Stack
        direction="row"
        spacing={3}
        justifyContent="space-between"
        sx={{
          p: 0.5,
          pb: 0,
        }}
      >
        <GridToolbarQuickFilter
          quickFilterParser={(searchInput: string) =>
            searchInput
              .split(",")
              .map((value) => value.trim())
              .filter((value) => value !== "")
          }
        />

        <Button
          variant="contained"
          color="error"
          startIcon={<ClearAll />}
          onClick={() => {
            clear();
          }}
          sx={{ fontSize: "0.8rem" }}
        >
          Clear List
        </Button>
      </Stack>
    );
  }

  return (
    <Stack
      direction="column"
      justifyItems="stretch"
      alignItems="center"
      height="100%"
    >
      <Typography
        variant="body1"
        color="inherit"
        textAlign="center"
        sx={{ mt: 3 }}
      >
        Enter an organisation's ID to navigate the its details page.
      </Typography>
      <Box alignContent="center" sx={{ m: 2, width: "30rem" }}>
        <TextField
          fullWidth
          label="Org ID"
          id="searchbox"
          value={searchId}
          onKeyPress={(ev) => {
            if (ev.key === "Enter" && searchId) {
              handleSearchSubmit(searchId);
            }
          }}
          onChange={(event) => {
            setSearchId(event.target.value);
          }}
          onSubmit={() => {
            handleSearchSubmit(searchId);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="Implant ID"
                  edge="end"
                  onClick={() => {
                    handleSearchSubmit(searchId);
                  }}
                >
                  <NavigateNext />
                </IconButton>
              </InputAdornment>
            ),
          }}
          variant="outlined"
        />
      </Box>
      <Typography variant="h5" color="inherit" textAlign="center">
        Previously Viewed Organisations
      </Typography>
      {
        <Box flex={1} width="100%" mb={2}>
          <DataGrid
            columns={orgColumns}
            rows={prevOrgs.map((org) => ({
              id: org?.orgId,
              key: org?.orgId,
              ...org,
            }))}
            initialState={{
              filter: {
                filterModel: {
                  items: [],
                  quickFilterLogicOperator: GridLinkOperator.Or,
                },
              },
            }}
            components={{ Toolbar: QuickSearchToolbar }}
            onRowClick={(params) => {
              navigate({ pathname: `/orgs/${params.id}` }, { replace: false });
            }}
          />
        </Box>
      }
    </Stack>
  );
}
