import { Box } from "@mui/material";
import { FC } from "react";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useMultiAnimalReadings } from "../../hooks/animal/use-multi-animal-readings";
import { formatDate } from "../Utils";

export const MultiAnimalReadingChart: FC<{
  animalList: string[];
  readingType: string;
}> = ({ animalList, readingType }) => {
  const { readings } = useMultiAnimalReadings(readingType, animalList);
  console.log("Series: ", readings);

  return (
    <Box width="100%" height="100%">
      <ResponsiveContainer width="100%" height="30%">
        <LineChart>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="datetime"
            tick
            tickFormatter={(tick) => formatDate(tick)}
            reversed
          />
          <YAxis dataKey="floatValue" domain={[`auto`, `auto`]} />
          <Tooltip
            labelFormatter={(label) => formatDate(label)}
            labelStyle={{ color: "black" }}
          />
          <Legend />
          {readings.map((animal) => (
            <Line
              dataKey="floatValue"
              data={animal.readings?.filter(
                (reading) => reading.type === readingType
              )}
              name={animal.animalId}
              key={animal.animalId}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};
