import { useMemo } from "react";
import { useQuery } from "../../graphql/Query";
import { HealthAlertSetting } from "../../vetchip/Alerts/AlertSettings";

export const listAlertSettingsQuery = `query GetHealthAlerts {
    adminGetHealthAlerts {
      highTrigger
      lowTrigger
      readingType
      readingVariable
      settingName
      speciesTag
    }
  }
  `;

export const useAdminListHealthAlerts = () => {
  const { data, ...query } = useQuery(listAlertSettingsQuery, {});
  const alerts: HealthAlertSetting[] = useMemo(
    () => data?.adminGetHealthAlerts ?? [],
    [data]
  );
  return { ...query, alerts };
};
