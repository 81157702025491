import { ClearAll, NavigateNext } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  GridLinkOperator,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { usePrevImplants } from "../../hooks/use-prev-implants";
import { implantColumns } from "../Users/UserImplants";

export default function Implants() {
  let navigate = useNavigate();
  const [searchId, setSearchId] = useState("");
  const { prevImplants, clear } = usePrevImplants();

  const handleSearchSubmit = async (search: string) => {
    if (search === null || search === "") {
      return;
    }
    navigate({ pathname: `/implant/${search}` }, { replace: false });
  };

  function QuickSearchToolbar() {
    return (
      <Stack
        direction="row"
        spacing={3}
        justifyContent="space-between"
        sx={{
          p: 0.5,
          pb: 0,
        }}
      >
        <GridToolbarQuickFilter
          quickFilterParser={(searchInput: string) =>
            searchInput
              .split(",")
              .map((value) => value.trim())
              .filter((value) => value !== "")
          }
        />

        <Button
          variant="contained"
          color="error"
          startIcon={<ClearAll />}
          onClick={() => {
            clear();
          }}
          sx={{ fontSize: "0.8rem" }}
        >
          Clear List
        </Button>
      </Stack>
    );
  }

  return (
    <Stack
      direction="column"
      justifyItems="stretch"
      alignItems="center"
      height="100%"
    >
      <Typography
        variant="body1"
        color="inherit"
        textAlign="center"
        sx={{ mt: 3 }}
      >
        Enter an implant's ID to navigate the its details page.
      </Typography>
      <Box textAlign="center" sx={{ m: 2, width: "30rem" }}>
        <TextField
          fullWidth
          label="Implant ID"
          id="searchbox"
          value={searchId}
          onKeyPress={(ev) => {
            if (ev.key === "Enter" && searchId) {
              handleSearchSubmit(searchId);
            }
          }}
          onChange={(event) => {
            setSearchId(event.target.value);
          }}
          onSubmit={() => {
            handleSearchSubmit(searchId);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="Implant ID"
                  edge="end"
                  onClick={() => {
                    handleSearchSubmit(searchId);
                  }}
                >
                  <NavigateNext />
                </IconButton>
              </InputAdornment>
            ),
          }}
          variant="outlined"
        />
      </Box>

      <Typography
        variant="h5"
        color="inherit"
        textAlign="center"
        padding={"1rem"}
      >
        Previously Viewed Implants
      </Typography>
      <Divider />
      <Box flex={1} width="100%" mb={2}>
        <DataGrid
          columns={implantColumns}
          rows={prevImplants.map((implant, index) => ({
            id: `${implant?.animalId}|${index}`,
            key: `${implant?.animalId}|${index}`,
            ...implant,
          }))}
          initialState={{
            filter: {
              filterModel: {
                items: [],
                quickFilterLogicOperator: GridLinkOperator.Or,
              },
            },
          }}
          components={{ Toolbar: QuickSearchToolbar }}
          onRowClick={(params) => {
            navigate(
              { pathname: `/implant/${params.id.toString().split("|")[0]}` },
              { replace: false }
            );
          }}
        />
      </Box>
    </Stack>
  );
}
