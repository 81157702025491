import { gql, useMutation } from "@apollo/client";
import { useMemo } from "react";
import {
  adminGetGroupDetailsQuery,
  GroupAccessDetails,
} from "./use-group-details";

export const useAdminAddGroupMember = (
  groupId: string,
  email: string,
  username: string,
  access: string,
  reinvite: boolean,
  onCompleted: (data: any) => void,
  onError: (data: any) => void
) => {
  const [execute, { data, ...mutationResult }] = useMutation(
    gql`
      mutation AddGroupUser(
        $groupId: ID!
        $email: String
        $username: String
        $access: GroupAccessLevel!
        $reinvite: Boolean
      ) {
        addUserToGroup(
          groupId: $groupId
          email: $email
          username: $username
          access: $access
          reinvite: $reinvite
        ) {
          groupId
          access
          notificationTypes
          owner
          created
          group {
            groupId
            name
            orgId
            created
            updated
          }
        }
      }
    `,
    {
      variables: { groupId, email, username, access, reinvite },
      onCompleted,
      onError,
      refetchQueries: [
        { query: gql(adminGetGroupDetailsQuery), variables: { groupId } },
      ],
    }
  );
  const groupDetails: GroupAccessDetails = useMemo(() => data ?? null, [data]);
  return { groupDetails, ...mutationResult, execute };
};
