import { SentimentDissatisfiedOutlined } from "@mui/icons-material";
import { Grid, Skeleton, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { useAdminGetGatewayDetails } from "../../hooks/gateway/use-gateway-details";
import { useGatewayQuery } from "../../hooks/gateway/use-gateway-query";
import { GatewayDetails } from "./GatewayDetails";

export const Gateway: FC = () => {
  const { gatewayId } = useParams();

  const { loading: loadingGateway, gatewayDetails: gateway } = useAdminGetGatewayDetails(gatewayId ?? "");

  if (loadingGateway) {
    return (
      <Grid container spacing={3} sx={{ p: 2 }}>
        <Grid item xs={12} md={6} lg={4}>
          <Skeleton
            variant="rectangular"
            sx={{ width: "100%", height: "100%" }}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Skeleton
            variant="rectangular"
            sx={{ width: "100%", height: "100%" }}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Skeleton
            variant="rectangular"
            sx={{ width: "100%", height: "100%" }}
          />
        </Grid>
      </Grid>
    );
  }

  if(!loadingGateway && !gateway) {
    return (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        width="100%"
        marginTop="5rem"
      >
        <SentimentDissatisfiedOutlined
          color="error"
          sx={{ fontSize: "60pt", mr: 1 }}
        />
        <Typography variant="h3" color="error">
          Gateway Not Found
        </Typography>
      </Stack>
    );
  }

  return <GatewayDetails gateway={gateway} />;
};
