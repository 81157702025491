import { Send } from "@mui/icons-material";
import { Chip, CircularProgress } from "@mui/material";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useAdminInviteNewUser } from "../../hooks/use-admin-invite-new-user";

export const ResendInviteButton: FC<{
  email: string;
  onDone: (data: any) => void;
}> = ({ email }) => {
  let navigate = useNavigate();

  const finish = ({ data }: any) => {
    navigate(
      {
        pathname: `/users/professional/${data.adminInviteUser.username}/${data.adminInviteUser.email}`,
      },
      { replace: false }
    );
  };

  const { loading, execute } = useAdminInviteNewUser(
    email,
    true,
    undefined,
    undefined,
    undefined,
    finish,
    () => {}
  );

  return (
    <Chip
      disabled={loading}
      size="small"
      color="warning"
      icon={
        loading ? (
          <CircularProgress size={15} color="inherit" />
        ) : (
          <Send color="inherit" />
        )
      }
      onClick={() => {
        execute();
      }}
      label={loading ? "Sending" : "Resend Invite"}
    ></Chip>
  );
};
