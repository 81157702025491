import { ClearAll, NavigateNext } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridLinkOperator,
  GridRenderCellParams,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAdminListGateways } from "../../hooks/gateway/use-admin-list-gateays";
import { CopyText } from "../Common/CopyText";
import { formatDate } from "../Utils";

export const gatewayColumns: GridColDef[] = [
  {
    field: "name",
    headerName: "Name",
    type: "string",
    align: "left",
    headerAlign: "left",
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography variant="body2" sx={{ cursor: "pointer" }}>
          {params.value}
        </Typography>
      );
    },
  },
  {
    field: "gatewayId",
    headerName: "Gateway ID",
    align: "left",
    headerAlign: "left",
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography
          variant="body2"
          sx={{ cursor: "pointer" }}
          component={"span"}
        >
          <CopyText>
            {params.value}
          </CopyText>
        </Typography>
      );
    },
  },
  {
    field: "lastOnline",
    headerName: "Last Online",
    type: "dateTime",
    align: "left",
    headerAlign: "left",
    flex: 0.6,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography variant="body2" sx={{ cursor: "pointer" }}>
          {formatDate(params.value)}
        </Typography>
      );
    },
  },
];

export default function Gateways() {
  let navigate = useNavigate();
  const [searchId, setSearchId] = useState("");

  const { gateways, loading } = useAdminListGateways();

  const handleSearchSubmit = async (search: string) => {
    if (search === null || search === "") {
      return;
    }
    navigate({ pathname: `/gateway/${search}` }, { replace: false });
  };

  function QuickSearchToolbar() {
    return (
      <Stack
        direction="row"
        spacing={3}
        justifyContent="space-between"
        sx={{
          p: 0.5,
          pb: 0,
        }}
      >
        <GridToolbarQuickFilter
          quickFilterParser={(searchInput: string) =>
            searchInput
              .split(",")
              .map((value) => value.trim())
              .filter((value) => value !== "")}
        />
      </Stack>
    );
  }

  return (
    <Stack
      direction="column"
      justifyItems="stretch"
      alignItems="center"
      height="100%"
    >
      <Typography
        variant="body1"
        color="inherit"
        textAlign="center"
        sx={{ mt: 3 }}
      >
        Enter a gateway's ID to navigate the its details page.
      </Typography>
      <Box textAlign="center" sx={{ m: 2, width: "30rem" }}>
        <TextField
          fullWidth
          label="Gateway ID"
          id="searchbox"
          value={searchId}
          onKeyPress={(ev) => {
            if (ev.key === "Enter" && searchId) {
              handleSearchSubmit(searchId);
            }
          }}
          onChange={(event) => {
            setSearchId(event.target.value);
          }}
          onSubmit={() => {
            handleSearchSubmit(searchId);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="Gateway ID"
                  edge="end"
                  onClick={() => {
                    handleSearchSubmit(searchId);
                  }}
                >
                  <NavigateNext />
                </IconButton>
              </InputAdornment>
            ),
          }}
          variant="outlined"
        />
      </Box>

      <Typography
        variant="h5"
        color="inherit"
        textAlign="center"
        padding={"1rem"}
      >
        Active Gateways
      </Typography>
      <Divider />
      <Box flex={1} width="100%" mb={2}>
        {
          <DataGrid
            rows={gateways.map((gateway, index) => ({
              id: `${gateway?.gatewayId}|${index}`,
              key: `${gateway?.gatewayId}|${index}`,
              ...gateway,
            }))}
            columns={gatewayColumns}
            initialState={{
              filter: {
                filterModel: {
                  items: [],
                  quickFilterLogicOperator: GridLinkOperator.Or,
                },
              },
            }}
            components={{ Toolbar: QuickSearchToolbar }}
            onRowClick={(params) => {
              navigate(
                { pathname: `/gateway/${params.id.toString().split("|")[0]}` },
                { replace: false },
              );
            }}
          />
        }
      </Box>
    </Stack>
  );
}
