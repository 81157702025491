import { CircularProgress } from "@mui/material";
import { Amplify } from "aws-amplify";
import { FC, useEffect, useState } from "react";
import AppAuthWrapper, { AppApollo } from "./App";

export const AppApi: FC = () => {
  const [config, setConfig] = useState<any>();

  useEffect(() => {
    fetch("/aws-exports.json")
      .then((response) => response.text())
      .then((content) => {
        const json = JSON.parse(content);
        Amplify.configure(json);
        setConfig(json);
      });
  }, []);

  if (!config) {
    return <CircularProgress />;
  }

  return (
    <AppAuthWrapper>
      <AppApollo uri={config.aws_appsync_graphqlEndpoint} />
    </AppAuthWrapper>
  );
};
