import { useQuery } from "../../graphql/Query";
import { GatewayVim } from "../animal/types";

export const adminListGatewayVimsQuery =
  `query AdminListGatewayVims($gatewayId: ID!) {
  adminListGatewayVims(gatewayId: $gatewayId) {
    animalId
    animalName
    gatewayId
    updated
    updatedImplantId
  }
}`;

export const useAdminListGatewayVims = (gatewayId: string) => {
  const { data, ...query } = useQuery(adminListGatewayVimsQuery, { gatewayId: gatewayId });
  return {
    ...query,
    vims: (data?.adminListGatewayVims ?? []) as GatewayVim[],
  };
};
