import { GroupAdd, PersonAdd } from "@mui/icons-material";
import {
  Alert,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
  TextField,
  FormControlLabel,
  Checkbox,
  DialogActions,
  CircularProgress,
  Select,
  MenuItem,
  FormGroup,
} from "@mui/material";
import { FC, useState } from "react";
import { useAdminAddGroupMember } from "../../hooks/group/use-admin-add-group-user";
import { HistoryType, TextFieldHistory } from "./TextFieldHistory";

export const AddGroupUserButton: FC<{
  groupId?: string;
  groupName?: string;
  email?: string;
  username?: string;
}> = ({ groupId, groupName, email, username }) => {
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [reinvite, setReinvite] = useState<boolean>(false);
  const [emailInput, setEmailInput] = useState<string>(email ?? "");
  const [groupIdInput, setGroupIdInput] = useState<string>(groupId ?? "");
  const [groupAccess, setGroupAccess] = useState<string>("member");

  const { loading, execute } = useAdminAddGroupMember(
    groupId ?? groupIdInput,
    email ?? emailInput,
    "",
    groupAccess,
    reinvite,
    (ret) => {
      if (ret.addUserToGroup.group != null) {
        setOpen(false);
      } else {
        setErrorMsg(
          "An error occured.\nCheck that all input parameters are correct."
        );
      }
    },
    (error) => {
      setErrorMsg(
        "An error occured.\nCheck that all input parameters are correct."
      );
      console.log(error);
    }
  );

  return (
    <div>
      <Tooltip
        title={
          groupId ? "Add user to this group." : "Add this user to a group."
        }
      >
        <Button
          variant="contained"
          onClick={() => {
            setOpen(!open);
          }}
          startIcon={groupId ? <PersonAdd /> : <GroupAdd />}
        >
          {email ? "Link to Group" : "Add Member"}
        </Button>
      </Tooltip>
      <Dialog open={open} fullWidth>
        <DialogTitle textAlign="center">Add member to Group</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>
            {groupId
              ? `Add member to ${groupName ?? groupId}.`
              : `Add ${email ?? "this user"} to a group.`}
          </DialogContentText>

          <FormGroup>
            {/* <TextField
              fullWidth
              autoFocus
              label={groupId ? "Email address" : "Group ID"}
              onChange={(event) => {
                groupId
                  ? setEmailInput(event.target.value)
                  : setGroupIdInput(event.target.value);
              }}
              sx={{ mb: 2 }}
            /> */}
            <TextFieldHistory
              type={groupId ? HistoryType.user : HistoryType.group}
              onSelect={(val) => {
                groupId ? setEmailInput(val) : setGroupIdInput(val);
              }}
            />

            <Select
              id="accessSelect"
              value={groupAccess}
              sx={{ width: "30%", mt: 2 }}
              onChange={(event) => {
                setGroupAccess(event.target.value as string);
              }}
            >
              <MenuItem value={"admin"}>Admin</MenuItem>
              <MenuItem value={"member"}>Member</MenuItem>
            </Select>

            <FormControlLabel
              control={
                <Checkbox
                  onChange={(event) => setReinvite(event.target.checked)}
                />
              }
              label="Reinvite"
            />
          </FormGroup>
        </DialogContent>
        {errorMsg !== "" && (
          <Alert
            sx={{ m: 2 }}
            severity="error"
            onClose={() => {
              setErrorMsg("");
            }}
          >
            {errorMsg}
          </Alert>
        )}
        <DialogActions sx={{ justifyContent: "space-between", p: 2 }}>
          <Button
            variant="contained"
            disabled={loading}
            onClick={() => {
              setOpen(false);
            }}
            color="error"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={
              loading ||
              (groupIdInput !== "" ? emailInput === "" : groupIdInput === "")
            }
            onClick={() => execute()}
            color="info"
          >
            {!loading ? "Ok" : <CircularProgress size={20} color="info" />}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
