import { FC, Fragment, useMemo, useState } from "react";
import { useAnimalReadingsType } from "../../hooks/animal/use-animal-readings";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import PostAddIcon from "@mui/icons-material/PostAdd";
import { GroupSurveyComment } from "./GroupSurveyComment";

export const MultiLineText: FC<{ text: string }> = ({ text }) => {
  return (
    <>
      {text
        .split("\n")
        .map((t, idx) =>
          t.trim() ? (
            <Box key={`multline-text-${idx}`}>{t}</Box>
          ) : (
            <Box key={`multline-text-${idx}`} height="0.7rem" />
          )
        )}
    </>
  );
};

export const GroupSurveyNotes: FC<{ animalId: string }> = ({ animalId }) => {
  const { readings, loading, reload } = useAnimalReadingsType(
    animalId,
    "Survey-Notes"
  );
  const [open, setOpen] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const lastReading = useMemo(() => [...(readings ?? [])].shift(), [readings]);

  return (
    <Box onClick={() => setOpen(true)} sx={{ cursor: "pointer" }}>
      {loading && <CircularProgress />}
      {!!lastReading ? (
        <>
          <strong>
            {new Date(lastReading?.datetime).toLocaleDateString()}
          </strong>
          <br />
          {lastReading.stringValue}
        </>
      ) : (
        <em>
          <PostAddIcon />
        </em>
      )}
      <Dialog open={open} fullWidth={true}>
        <DialogTitle textAlign="center">Health Notes</DialogTitle>
        <DialogContent>
          <Grid2 container>
            {readings?.map((h, idx) => (
              <Fragment key={`note-${idx}`}>
                <Grid2 xs={3}>
                  <strong>{new Date(h.datetime).toLocaleDateString()}</strong>
                </Grid2>
                <Grid2 xs={9}>
                  <MultiLineText text={h.stringValue} />
                </Grid2>
                <Grid2 xs={12}>
                  <Divider />
                </Grid2>
              </Fragment>
            ))}
          </Grid2>
          <GroupSurveyComment animalId={animalId} onAdd={reload} />
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            disabled={loading}
            onClick={(e) => {
              setOpen(false);
              e.stopPropagation();
            }}
            color="info"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
