import { FC, useState } from "react";
import { AnimalReading } from "../../hooks/animal/types";
import useDimensions from "react-cool-dimensions";
import {
	Box,
	FormControl,
	FormControlLabel,
	FormLabel,
	Radio,
	RadioGroup,
	Stack,
	Theme,
	Typography,
	useTheme,
	Card,
} from "@mui/material";

import {
	chartValueFormat,
	formatDate,
	monthIndexToString,
	readingUnit,
} from "../Utils";
import {
	Bar,
	BarChart,
	Brush,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
	Cell,
	ScatterChart,
	CartesianGrid,
	Scatter,
} from "recharts";

enum DataRange {
	Raw,
	Day,
	Month,
}

interface GroupedData {
	dateRange: string;
	readings: AnimalReading[];
	hidden: boolean;
}

interface DataRangeGroup {
	dateRange: string;
	placeHolder: boolean;
	min?: number;
	max?: number;
	chartMax?: number;
	chartMin?: number;
}

function getMinMaxFloatValues(readings: number[]): {
	min: number;
	max: number;
} {
	return {
		min: Math.min(...readings),
		max: Math.max(...readings),
	};
}

const getWeekNumber = (date: Date): number => {
	const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
	const pastDaysOfYear = (date.getTime() - firstDayOfYear.getTime()) / 86400000;
	return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
};

function formatDateToHourMinute(date: Date) {
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, "0");
	const day = String(date.getDate()).padStart(2, "0");
	const hours = String(date.getHours()).padStart(2, "0");
	const minutes = String(date.getMinutes()).padStart(2, "0");

	return `${year}-${month}-${day} ${hours}:${minutes}`;
}

function formatDateToDay(date: Date) {
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, "0");
	const day = String(date.getDate()).padStart(2, "0");

	return `${year}-${month}-${day}`;
}

export const HistoricalRangeBarChart: FC<{
	readings: AnimalReading[];
}> = ({ readings }) => {
	const { observe, height } = useDimensions({
		onResize: ({ observe, unobserve, width, height, entry }) => {},
	});

	const theme: Theme = useTheme();
	const [groupBy, setGroupBy] = useState<DataRange>(DataRange.Day);

	const goodReadings = [...readings]
		.filter((reading) => reading.floatValue !== null)
		.reverse();

	const baseLine = Math.min(...readings.map((reading) => reading.floatValue));

	const groupedData: GroupedData[] = [];
	const dataRanges: DataRangeGroup[] = [];

	// Prefill the sections
	const startDate = new Date(
		Math.min(
			...readings.map((reading) =>
				new Date(reading.datetime.toString()).getTime()
			)
		)
	);
	const endDate = new Date(
		Math.max(
			...readings.map((reading) =>
				new Date(reading.datetime.toString()).getTime()
			)
		)
	);

	let currentDate = new Date(startDate);

	/** I really really hate this but it works for now.  */
	if (groupBy === DataRange.Day) {
		// Fill in the empty spaces
		while (currentDate < endDate) {
			const date = currentDate.toISOString().split("T")[0];
			groupedData.push({
				readings: [
					{
						booleanValue: false,
						intValue: 0,
						floatValue: 0,
						stringValue: "",
						type: "placeholder",
						datetime: currentDate,
					},
				],
				hidden: true,
				dateRange: date,
			});

			currentDate.setDate(currentDate.getDate() + 1);
		}

		goodReadings.forEach((reading) => {
			const date = reading.datetime.toString().split("T")[0];
			const group = groupedData.find((obj) => obj.dateRange === date);
			if (group) {
				group.readings.push(reading);
			} else {
				groupedData.push({
					readings: [reading],
					hidden: false,
					dateRange: date,
				});
			}
		});
	} else if (groupBy === DataRange.Month) {
		// Fill in the empty spaces
		while (currentDate < endDate) {
			const year = currentDate.getFullYear();
			const month = currentDate.getMonth() + 1;

			const stamp = `${monthIndexToString(month)} ${year}`;

			groupedData.push({
				readings: [
					{
						booleanValue: false,
						intValue: 0,
						floatValue: 0,
						stringValue: "",
						type: "placeholder",
						datetime: currentDate,
					},
				],
				hidden: true,
				dateRange: stamp,
			});

			currentDate.setMonth(currentDate.getMonth() + 1);

			console.log("Stamp: ", currentDate);
		}
		goodReadings.forEach((reading) => {
			const readingDate = new Date(reading.datetime.toString());

			const year = readingDate.getFullYear();
			const month = readingDate.getMonth() + 1;

			const stamp = `${monthIndexToString(month)} ${year}`;

			const group = groupedData.find((obj) => obj.dateRange === stamp);
			if (group) {
				group.readings.push(reading);
			} else {
				groupedData.push({
					readings: [reading],
					dateRange: stamp,
					hidden: false,
				});
			}
		});
	}

	groupedData.forEach((dataRange) => {
		if (dataRange.readings.length === 1) {
			dataRanges.push({
				dateRange: dataRange.dateRange,
				min: 0,
				max: 0,
				chartMax: 0,
				chartMin: 0,
				placeHolder: true,
			});
		} else {
			const { min, max } = getMinMaxFloatValues(
				dataRange.readings
					.filter((reading) => reading.type !== "placeholder")
					.map((reading) => reading.floatValue)
			);

			var chartMax = max - min;

			dataRanges.push({
				dateRange: dataRange.dateRange,
				min: min,
				max: max,
				chartMax: chartMax,
				chartMin: min,
				placeHolder: false,
			});
		}
	});

	function readingLabelString(val: number) {
		switch (readings[0].type) {
			case "temperature":
				return `${val.toFixed(1)}${readingUnit(goodReadings[0].type)} `;
			default:
				return `${val.toFixed(0)}${readingUnit(goodReadings[0].type)} `;
		}
	}

	function lineStroke(type: string) {
		switch (type) {
			case "temperature":
				return "#ff9800";
			case "heartRateVariance":
				return "#0084FF";
			case "batteryPercent":
				return "#00C507";
			case "heartRate":
				return "#FF0000";
			default:
				return "#FF0000";
		}
	}

	return (
		<Box sx={{ height: "90%" }} ref={observe}>
			<FormControl sx={{ p: 2 }}>
				<FormLabel id="toggle-button-group-label">Data Range</FormLabel>
				<RadioGroup
					row
					aria-labelledby="toggle-button-group-label"
					defaultValue={DataRange.Day}
					name="data-range-button-group"
					value={groupBy}
					onChange={(event) => {
						setGroupBy(parseInt(event.target.value));
					}}
				>
					<FormControlLabel
						control={<Radio />}
						label="No Grouping"
						value={DataRange.Raw}
					/>

					<FormControlLabel
						control={<Radio />}
						label="Daily"
						value={DataRange.Day}
					/>
					<FormControlLabel
						value={DataRange.Month}
						control={<Radio />}
						label="Monthly"
					/>
				</RadioGroup>
			</FormControl>

			{goodReadings.length > 0 ? (
				<ResponsiveContainer width="100%" height="80%">
					{groupBy === DataRange.Raw ? (
						<ScatterChart
							width={500}
							height={200}
							data={goodReadings}
							margin={{
								top: 5,
								right: 30,
								left: 0,
								bottom: 0,
							}}
						>
							<Scatter
								dataKey="floatValue"
								fill={lineStroke(readings[0].type)}
								animationDuration={200}
							/>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis
								dataKey="datetime"
								tick={{
									fill: theme.palette.mode === "light" ? "black" : "white",
								}}
								tickFormatter={(tick) => {
									return formatDate(tick);
								}}
							/>
							<YAxis
								domain={[`auto`, `auto`]}
								tickFormatter={(tick) => {
									return "";
								}}
								tick={{
									fill: theme.palette.mode === "light" ? "black" : "white",
								}}
							/>
							<Tooltip
								content={({ active, payload, label }) => {
									if (active && payload && payload.length) {
										return (
											<Card sx={{ p: 1 }}>
												<div className="custom-tooltip">
													<Typography>
														{formatDate(payload[0].payload["datetime"])}
													</Typography>
													<Typography>
														<b>
															{chartValueFormat(
																payload[0].payload["floatValue"],
																readings[0].type === "temperature" ? 1 : 0,
																readingUnit(goodReadings[0].type)
															)}
														</b>
													</Typography>
												</div>
											</Card>
										);
									}
								}}
								formatter={(value, name, props) => {
									if (name === "chartMax") {
										return [props.payload["max"], name];
									}
									return [value, name];
								}}
							/>
							<Brush
								stroke={theme.palette.mode === "dark" ? "#b5b0b0" : "black"}
								fill={theme.palette.mode === "dark" ? "#5e5e5e" : "#d9d0d0"}
								fillOpacity={theme.palette.mode === "dark" ? 0.1 : 0.1}
								tickFormatter={(tick) => formatDate(tick)}
								dataKey="datetime"
							/>
						</ScatterChart>
					) : (
						<BarChart
							width={500}
							height={180}
							data={dataRanges}
							margin={{
								top: 5,
								right: 30,
								left: 0,
								bottom: 0,
							}}
						>
							<XAxis
								dataKey="dateRange"
								tick={{
									fill: theme.palette.mode === "light" ? "black" : "white",
								}}
								tickFormatter={(tick) => {
									switch (groupBy) {
										case DataRange.Month:
											return tick;
										default:
											return formatDate(tick);
									}
								}}
							/>
							<YAxis
								domain={[`auto`, `auto`]}
								tickFormatter={(tick) => {
									return "";
								}}
								tick={{
									fill: theme.palette.mode === "light" ? "black" : "white",
								}}
							/>

							<Tooltip
								content={({ active, payload, label }) => {
									if (active && payload && payload.length) {
										var realMax = 0;
										var realMin = 0;

										if (
											payload[1].value !== undefined &&
											payload[0].value !== undefined
										) {
											realMax = payload[0].payload["max"];
											realMin = payload[0].payload["min"];
										}

										return payload[0].payload["placeHolder"] === true ? (
											<Card sx={{ p: 1 }}>
												<div className="custom-tooltip">
													<Typography>{label}</Typography>
													<Typography>No data</Typography>
												</div>
											</Card>
										) : (
											<Card sx={{ p: 1 }}>
												<div className="custom-tooltip">
													<Typography>{label}</Typography>
													<Typography>
														<b>{`Max: ${readingLabelString(realMax)}`}</b>
													</Typography>
													<Typography>
														<b>{`Min: ${readingLabelString(realMin)}`}</b>
													</Typography>
												</div>
											</Card>
										);
									}
								}}
								formatter={(value, name, props) => {
									if (name === "chartMax") {
										return [props.payload["max"], name];
									}
									return [value, name];
								}}
							/>

							<Bar dataKey={`chartMin`} stackId="a" fill={"rgba(0, 0, 0, 0)"}>
								{dataRanges.map((entry, index) => (
									<Cell
										key={`cell-${index}`}
										style={{
											display: entry.placeHolder === true ? "none" : "",
										}}
									/>
								))}
							</Bar>

							<Bar
								dataKey={"chartMax"}
								stackId="a"
								fill={lineStroke(goodReadings[0].type)}
								radius={[15, 15, 15, 15]}
								minPointSize={10}
								maxBarSize={20}
							>
								{dataRanges.map((entry, index) => (
									<Cell
										key={`cell-${index}`}
										style={{
											display: entry.placeHolder === true ? "none" : "",
										}}
									/>
								))}
							</Bar>

							<Brush />
						</BarChart>
					)}
				</ResponsiveContainer>
			) : (
				<Stack direction="column" alignItems="center" display="grid">
					<Typography variant="subtitle1" textAlign="center" sx={{ pt: 5 }}>
						No data
					</Typography>
				</Stack>
			)}
		</Box>
	);
};
