import { gql, MutationHookOptions, OperationVariables, useMutation as useApolloMutation } from "@apollo/client";
import { useMemo } from "react";

export const useMutation = (
  query: string,
  variables: Record<string, any>,
  onCompleted: (data: any) => void = () => {},
  onError: (data: any) => void = () => {},
  options?: MutationHookOptions
) => {
  const [runMutation, mutationResult] = useApolloMutation(gql(query), {
    variables,
    onCompleted,
    onError,
    ...options,
  });
  const execute = useMemo(
    () => (variables?: OperationVariables) => {
      runMutation({ variables });
    },
    [runMutation]
  );

  return { execute, ...mutationResult };
};
