import { gql, useMutation } from "@apollo/client";

export interface InviteUserAccountDetails {
  email: string;
  username: string;
  enabled: boolean;
  reinvite: boolean;
}

export const useAdminInviteNewUser = (
  email: string,
  reinvite: boolean,
  isDev?: boolean,
  isResearcher?: boolean,
  isVet?: boolean,
  onCompleted: (data: any) => void = () => {},
  onError: (data: Error) => void = () => {}
) => {
  const [execute, { data, ...mutationResult }] = useMutation(
    gql`
      mutation AdminInviteUser(
        $email: String!
        $reinvite: Boolean
        $isDev: Boolean
        $isResearcher: Boolean
        $isVet: Boolean
      ) {
        adminInviteUser(
          email: $email
          reinvite: $reinvite
          isDev: $isDev
          isResearcher: $isResearcher
          isVet: $isVet
        ) {
          email
          enabled
          username
        }
      }
    `,
    {
      variables: { email, reinvite, isDev, isResearcher, isVet },
      onCompleted,
      onError,
    }
  );
  return { data, ...mutationResult, execute };
};
