import {
  ApartmentOutlined,
  GroupOutlined,
  MemoryOutlined,
  NavigateNext,
  PersonOutline,
  Wysiwyg,
} from "@mui/icons-material";
import { Divider, Link, Paper, Stack, Typography } from "@mui/material";

export default function Home() {
  return (
    <div>
      <Typography variant="h4" color="inherit" justifyContent="center" sx={{ m: 2 }}>
        Home
      </Typography>
      <Divider />
      <Stack spacing={2} sx={{ mt: 2 }} maxWidth="50%">
        <Link underline="none" href="/orgs">
          <Paper sx={{ p: 2 }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Stack direction="row" alignItems="center" justifyContent="left">
                <ApartmentOutlined sx={{ height: "3rem", width: "3rem" }} />
                <Stack direction="column" alignItems="left" justifyContent="left" sx={{ pl: 1 }}>
                  <Typography variant="h5" sx={{ p: 1 }} textAlign="left">
                    Organisations
                  </Typography>
                  <Typography variant="body2" sx={{ p: 1 }} textAlign="left">
                    View organisations
                  </Typography>
                </Stack>
              </Stack>
              <NavigateNext sx={{ height: "3rem", width: "3rem", justifySelf: "end" }} />
            </Stack>
          </Paper>
        </Link>
        <Link underline="none" href="/groups">
          <Paper sx={{ p: 2 }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Stack direction="row" alignItems="center" justifyContent="left">
                <GroupOutlined sx={{ height: "3rem", width: "3rem" }} />
                <Stack direction="column" alignItems="left" justifyContent="left" sx={{ pl: 1 }}>
                  <Typography variant="h5" sx={{ p: 1 }} textAlign="left">
                    Groups
                  </Typography>
                  <Typography variant="body2" sx={{ p: 1 }} textAlign="left">
                    {/* Search for groups, view members and implants */}
                    Previously viewed groups
                  </Typography>
                </Stack>
              </Stack>
              <NavigateNext sx={{ height: "3rem", width: "3rem", justifySelf: "end" }} />
            </Stack>
          </Paper>
        </Link>
        <Link underline="none" href="/users">
          <Paper sx={{ p: 2 }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Stack direction="row" alignItems="center" justifyContent="left">
                <PersonOutline sx={{ height: "3rem", width: "3rem" }} />
                <Stack direction="column" alignItems="left" justifyContent="left" sx={{ pl: 1 }}>
                  <Typography variant="h5" textAlign="left" sx={{ p: 1 }}>
                    Users
                  </Typography>
                  <Typography variant="body2" sx={{ p: 1 }} textAlign="left">
                    Search for users and view details
                  </Typography>
                </Stack>
              </Stack>
              <NavigateNext sx={{ height: "3rem", width: "3rem", justifySelf: "end" }} />
            </Stack>
          </Paper>
        </Link>

        <Link underline="none" href="/implants">
          <Paper sx={{ p: 2 }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Stack direction="row" alignItems="center" justifyContent="left">
                <MemoryOutlined sx={{ height: "3rem", width: "3rem" }} />
                <Stack direction="column" alignItems="left" justifyContent="left" sx={{ pl: 1 }}>
                  <Typography variant="h5" sx={{ p: 1 }} textAlign="left">
                    Implants
                  </Typography>
                  <Typography variant="body2" sx={{ p: 1 }} textAlign="left">
                    {/* Search for implants, view details and readings */}
                    Previously viewed implants
                  </Typography>
                </Stack>
              </Stack>
              <NavigateNext sx={{ height: "3rem", width: "3rem", justifySelf: "end" }} />
            </Stack>
          </Paper>
        </Link>

        <Link underline="none" href="/firmware">
          <Paper sx={{ p: 2 }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Stack direction="row" alignItems="center" justifyContent="left">
                <Wysiwyg sx={{ height: "3rem", width: "3rem" }} />
                <Stack direction="column" alignItems="left" justifyContent="left" sx={{ pl: 1 }}>
                  <Typography variant="h5" sx={{ p: 1 }} textAlign="left">
                    Firmware
                  </Typography>
                  <Typography variant="body2" sx={{ p: 1 }} textAlign="left">
                    View firmware or upload new
                  </Typography>
                </Stack>
              </Stack>
              <NavigateNext sx={{ height: "3rem", width: "3rem", justifySelf: "end" }} />
            </Stack>
          </Paper>
        </Link>
      </Stack>
    </div>
  );
}
