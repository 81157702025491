import { useQuery } from "../../graphql/Query";
import { Gateway } from "../../vetchip/Gateways/GatewayDetails";



export const adminListGatewaysQuery = `
query ListGateways{
  adminGetGateways {
    created
    gatewayId
    hwVersion
    lastOnline
    name
    rebooted
    status
    updated
  }
}
`;

export const useAdminListGateways = () => {
  const {data, ...query} = useQuery(adminListGatewaysQuery, {
  });

  return {
    ...query,
    gateways: (data?.adminGetGateways ?? []) as Gateway[],
  }
}
