import { Button, CircularProgress, Tooltip } from "@mui/material";
import { FC } from "react";
import { useRemoveGroupAnimal } from "../../hooks/group/use-remove-group-animal";
import { LinkOff } from "@mui/icons-material";

export interface RemoveGroupAnimalButtonProps {
  animalID: string;
  groupID: string;
  onDone: (data: any) => void;
}
export const RemoveGroupAnimalButton: FC<RemoveGroupAnimalButtonProps> = ({
  groupID,
  animalID,
  onDone,
}) => {
  const { loading, execute } = useRemoveGroupAnimal(groupID, animalID, onDone);

  return (
    <Tooltip title="Unlink animal from group">
      <Button
        disabled={loading}
        startIcon={
          loading ? <CircularProgress size={15} color="inherit" /> : <LinkOff />
        }
        variant="contained"
        color="error"
        onClick={(e) => {
          e.stopPropagation();
          execute();
        }}
      >
        {loading ? "Unlinking" : "Unlink"}
      </Button>
    </Tooltip>
  );
};
