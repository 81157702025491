import { FC } from "react";

import { LogHistory } from "../Logs/LogHistory";
import { useUserLogs } from "../Logs/useLogs";

export interface UserLogProps {
  userpool: string;
  username: string;
}

export const UserLog: FC<UserLogProps> = ({ userpool, username }) => {
  const { logs, loading } = useUserLogs(`${userpool}:${username}`);
  return <LogHistory user loading={loading} logs={logs} />;
};
