import { gql, useQuery, useSubscription } from "@apollo/client";
import { useMemo } from "react";
import { AnimalReading } from "../animal/types";

export interface VetLink {
  vetId: string;
  orgId: string;
  orgName: string;
  created: string;
}

export interface GroupAnimalWithReadings {
  access: string;
  animalId: string;
  animal: {
    name: string;
    sex: string;
    species: string;
    created: string;
    updated: string;
  };
  readings: AnimalReading[];
  vetLinks: VetLink[];
}

export const useGroupAnimalsWithReadingsQuery = gql`
  query GetGroupAnimalsWithReadings($groupId: ID!) {
    getAnimalByGroup(groupId: $groupId) {
      items {
        access
        animalId
        animal {
          name
          sex
          species
          breed
          created
          updated
          hardwareId
        }
        readings {
          type
          datetime
          floatValue
          stringValue
          booleanValue
          intValue
        }
        vetLinks {
          animalId
          created
          orgId
          orgName
          vetId
        }
      }
    }
  }
`;

export const useGroupAnimalsWithReadings = (groupId?: string) => {
  const { data, loading, refetch, ...query } = useQuery(
    useGroupAnimalsWithReadingsQuery,
    {
      variables: { groupId },
    }
  );

  useSubscription(
    gql`
      subscription GroupAnimalSubscription($groupId: ID!) {
        onAnimalEventByGroup(groupId: $groupId) {
          access
          animalId
          animal {
            name
            sex
            species
            breed
            created
            updated
            hardwareId
          }
          readings {
            type
            datetime
            floatValue
            stringValue
            booleanValue
            intValue
          }
          vetLinks {
            animalId
            created
            orgId
            orgName
            vetId
          }
        }
      }
    `,
    {
      variables: { groupId },
      onData: (_data) => refetch(),
    }
  );

  useSubscription(
    gql`
      subscription RemoveGroupAnimalSubscription($groupId: ID!) {
        onRemoveAnimalEventByGroup(groupId: $groupId) {
          access
          animalId
          created
          groupId
        }
      }
    `,
    { variables: { groupId }, onData: (data) => refetch() }
  );

  const groupAnimals: GroupAnimalWithReadings[] = useMemo(
    () => data?.getAnimalByGroup?.items ?? [],
    [data]
  );
  return { loading, groupAnimals, ...query, refresh: refetch };
};
