import { useQuery } from "../../graphql/Query";

export const useAnimalQuery = (animalId?: string) =>
  useQuery(
    `query AdminGetAnimal($animalId: ID!) {
      adminGetAnimal(animalId: $animalId) {
        animalId
        hardwareId
        breed
        colour
        created
        dob
        implantHwVersion
        implantModel
        implantSwVersion
        microchip
        name
        notes
        photo
        sex
        species
        updated
        weight
      }
    }
    
    `,
    { animalId }
  );
