import { FC, useMemo } from "react";
import { startCase } from "lodash";
import { Typography, Stack, Box, LinearProgress } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridLinkOperator,
  GridRenderCellParams,
} from "@mui/x-data-grid";

import { useMultipleAnimalReadingsTypes } from "../../hooks/animal/use-animal-readings";
import { formatDate } from "../Utils";
import { QuickSearchToolbar } from "../Common/QuickSearchToolbarReadings";

export const readingColumns: GridColDef[] = [
  {
    field: "datetime",
    headerName: "Date",
    type: "dateTime",
    align: "left",
    headerAlign: "left",
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography variant="body2" sx={{ cursor: "pointer" }}>
          {formatDate(params.value)}
        </Typography>
      );
    },
  },
  {
    field: "type",
    headerName: "Question",
    type: "string",
    align: "left",
    headerAlign: "left",
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography variant="body2" sx={{ cursor: "pointer" }}>
          {startCase(params.value)}
        </Typography>
      );
    },
  },
  {
    field: "stringValue",
    headerName: "answer",
    headerAlign: "left",
    type: "number",
    align: "left",
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography
          variant="body2"
          sx={{ cursor: "pointer" }}
          component={"span"}
        >
          {params.value}
        </Typography>
      );
    },
  },
];

export interface ImplantSurveyResponsesProps {
  animalId: string;
}

const surveyTypes = [
  "Initial-OwnerName",
  "Initial-FoodTypes",
  "Initial-FeedingHabits",
  "Initial-FeedingAmount",
  "Initial-FoodBrands",
  "Initial-FeedingFrequency",
  "Initial-Children",
  "Initial-Pets",
  "Initial-HealthIssues",
  "Initial-SleepingPattern",
  "Initial-Activities",
  "Daily-Healthy",
  "Daily-Activities",
  "Daily-FeedingVolume",
  "Daily-FeedingFrequency",
  "Daily-SleepingPattern",
  "Daily-SignificantEvents",
  "Manual-Temperature",
  "Manual-HeartRate",
  "Manual-RespiratoryRate",
  "Survey-Notes",
];

export const ImplantSurveyResponses: FC<ImplantSurveyResponsesProps> = ({
  animalId,
}) => {
  const { readings, loading, reload, progress, progressTotal } =
    useMultipleAnimalReadingsTypes(animalId, surveyTypes);
  const rows = useMemo(
    () =>
      readings?.map((r) => ({
        id: `${r.datetime}-${r.type}`,
        key: `${r.datetime}-${r.type}`,
        ...r,
      })) ?? [],
    [readings]
  );

  if (loading) {
    return (
      <Box
        width="100%"
        color="#aaa"
        fontSize="30px"
        textAlign="center"
        paddingTop="10rem"
      >
        <Box marginX="10rem" marginBottom={4}>
          <LinearProgress
            value={(progress / progressTotal) * 100}
            variant="determinate"
          />
        </Box>
        Loading {progress} of {progressTotal}...
      </Box>
    );
  }

  return (
    <Stack height="100%">
      <DataGrid
        columns={readingColumns}
        rows={rows}
        initialState={{
          filter: {
            filterModel: {
              items: [],
              quickFilterLogicOperator: GridLinkOperator.Or,
            },
          },
        }}
        components={{ Toolbar: QuickSearchToolbar }}
        componentsProps={{
          toolbar: {
            animalId: animalId,
            readingType: "survey",
            onReload: () => reload(),
          },
        }}
      />
    </Stack>
  );
};
