import {
  Add,
  BugReport,
  CheckCircle,
  ErrorOutline,
  Lock,
  MoreHoriz,
  PostAdd,
  RadioButtonUnchecked,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import React, { FC } from "react";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAdminGetImplantFirmware } from "../../hooks/firmware/use-admin-get-implant-firmware";
import { useAdminListFirmware } from "../../hooks/firmware/use-admin-list-firmware";
import { useAdminUpdateFirmwareStatus } from "../../hooks/firmware/use-admin-update-firmware-status";
import { formatDate } from "../Utils";
import { SetFirmwareStatus } from "./SetFirmwareStatus";

const CellMenu: FC<{ version: string; onDone: () => void }> = ({
  version,
  onDone,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { execute } = useAdminUpdateFirmwareStatus(onDone);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpenStatus(false);
  };
  const [openStatus, setOpenStatus] = useState<boolean>(false);
  const handleCloseStatus = () => {
    setOpenStatus(false);
  };

  return (
    <div>
      <IconButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreHoriz />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ width: "320rem" }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            execute({ variables: { version, status: "pending" } });
          }}
        >
          <ListItemIcon>
            <Lock fontSize="small" color="secondary" />
          </ListItemIcon>
          <ListItemText>Set Pending</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            execute({ variables: { version, status: "testing" } });
          }}
        >
          <ListItemIcon>
            <BugReport fontSize="small" color="info" />
          </ListItemIcon>
          <ListItemText>Set Testing</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            execute({ variables: { version, status: "release" } });
          }}
        >
          <ListItemIcon>
            <CheckCircle fontSize="small" color="success" />
          </ListItemIcon>
          <ListItemText>Set Release</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            execute({ variables: { version, status: "deprecated" } });
          }}
        >
          <ListItemIcon>
            <ErrorOutline fontSize="small" color="warning" />
          </ListItemIcon>
          <ListItemText>Set Deprecated</ListItemText>
        </MenuItem>

        {/* <SetCurrentFirmwareButton firmware={version} hardware={"current-implant-v3"} onDone={() => {}} /> */}
      </Menu>

      <Dialog open={openStatus} onClose={handleCloseStatus}>
        {/* <DialogTitle>Set status for {version}</DialogTitle> */}
        <DialogContent>
          <SetFirmwareStatus version={version} onDone={() => {}} />
        </DialogContent>
      </Dialog>
    </div>
  );
};

function NewVersionButton() {
  let navigate = useNavigate();
  return (
    <Button
      variant="contained"
      startIcon={<Add />}
      onClick={() => {
        navigate({ pathname: `/add-firmware` }, { replace: false });
      }}
    >
      Create New
    </Button>
  );
}

function QuickSearchToolbar() {
  return (
    <Stack
      direction="row"
      justifyContent={"space-between"}
      sx={{
        p: 0.5,
        pb: 0,
      }}
    >
      <GridToolbarQuickFilter
        sx={{ maxWidth: "30%" }}
        quickFilterParser={(searchInput: string) =>
          searchInput
            .split(",")
            .map((value) => value.trim())
            .filter((value) => value !== "")
        }
      />
      <GridToolbarContainer>
        <NewVersionButton />
      </GridToolbarContainer>
    </Stack>
  );
}

export default function FirmwareVersions() {
  const { versions, loading, refresh } = useAdminListFirmware();
  const {
    firmware: current,
    loading: currentLoading,
    refresh: refreshCurrent,
  } = useAdminGetImplantFirmware("current-implant-v3");

  const [sortModel, setSortModel] = useState({
    field: "created",
    sort: "desc",
  });

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "version",
        headerName: "Version",
        flex: 1,
        renderCell: (params: GridRenderCellParams) => {
          return <Typography variant="body2">{params.value}</Typography>;
        },
      },
      {
        field: "hci",
        headerName: "HCI",
        flex: 1,
        renderCell: (params: GridRenderCellParams) => {
          return <Typography variant="body2">{params.value}</Typography>;
        },
      },
      {
        field: "lmp",
        headerName: "LMP",
        flex: 1,
        renderCell: (params: GridRenderCellParams) => {
          return <Typography variant="body2">{params.value}</Typography>;
        },
      },
      {
        field: "status",
        headerName: "Status",
        flex: 1,
        renderCell: (params: GridRenderCellParams) => {
          return params.value === "release" ? (
            <Chip
              icon={<CheckCircle />}
              label="Release"
              variant="outlined"
              color="success"
            />
          ) : params.value === "testing" ? (
            <Chip
              icon={<BugReport />}
              label="Testing"
              variant="outlined"
              color="info"
            />
          ) : params.value === "deprecated" ? (
            <Chip
              icon={<ErrorOutline />}
              label="Deprecated"
              variant="outlined"
              color="warning"
            />
          ) : params.value === "pending" ? (
            <Chip
              icon={<Lock />}
              label={params.value}
              variant="outlined"
              color="secondary"
            />
          ) : params.value != null ? (
            <Chip
              icon={<RadioButtonUnchecked />}
              label={params.value}
              variant="outlined"
            />
          ) : (
            <Typography>{params.value}</Typography>
          );
        },
      },
      {
        field: "created",
        headerName: "Created",
        flex: 1.5,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Typography variant="body2">{formatDate(params.value)}</Typography>
          );
        },
      },
      {
        field: "menu",
        headerName: "",
        flex: 0.3,
        renderCell: (params: GridRenderCellParams) => (
          <CellMenu
            version={params.value}
            onDone={async () => {
              await refreshCurrent();
              refresh();
            }}
          />
        ),
      },
    ],
    [refresh, refreshCurrent]
  );

  return (
    <Stack justifyContent="stretch" sx={{ m: 2 }} height="100%">
      <div />
      <Typography variant="h5" color="inherit" textAlign="center" sx={{ m: 2 }}>
        Firmware Versions
      </Typography>

      <div>
        {loading || currentLoading ? (
          <Box width="100%" height="100%">
            <Skeleton variant="rectangular" height={300} />
          </Box>
        ) : (
          <Stack direction="row" alignItems="center" justifyContent="center">
            {
              <Box flex={1}>
                <DataGrid
                  autoHeight
                  columns={columns}
                  components={{ Toolbar: QuickSearchToolbar }}
                  disableSelectionOnClick
                  hideFooterSelectedRowCount
                  rows={versions?.map((version, row) => ({
                    id: version.version,
                    key: version.version,
                    menu: version.version,
                    current:
                      version.version === current?.version
                        ? "current-implant-v3"
                        : null,
                    ...version,
                  }))}
                />
              </Box>
            }
          </Stack>
        )}
      </div>
    </Stack>
  );
}
