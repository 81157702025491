import { FC } from "react";
import { Skeleton, Chip, Stack, Typography } from "@mui/material";
import { useAdminGetAnimalTags } from "../../hooks/animal/use-admin-get-animal-tags";

export const getTagColor = (str: string) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  const hue = hash % 360;
  return `hsl(${hue}, 45%, 35%)`;
};

export const ImplantCustomTags: FC<{
  animalId: string;
}> = ({ animalId }) => {
  const { loading, tagList } = useAdminGetAnimalTags(animalId);

  if (loading) {
    return (
      <Skeleton variant="rectangular" sx={{ width: "100%", height: "100%" }} />
    );
  }

  return (
    <Stack
      direction="row"
      spacing={1}
      flexWrap="wrap"
      sx={{ gap: 1, pb: 2, pt: 2 }}
      width="100%"
    >
      <Typography variant="body2" color="inherit" align="left">
        Custom Tags:{" "}
      </Typography>
      {tagList.map((tag: string) => {
        return (
          <Chip
            key={tag}
            label={tag}
            size="small"
            sx={{
              backgroundColor: getTagColor(tag),
              color: "white",
            }}
          />
        );
      })}
    </Stack>
  );
};
