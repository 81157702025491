import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { CreateAlertSettingButton } from "./CreateAlertSettingButton";
import { useAdminListHealthAlerts } from "../../hooks/health-alerts/use-admin-list-alert-settings";
import { Delete, MoreHoriz } from "@mui/icons-material";
import { FC, useEffect, useState } from "react";
import { EditAlertSettingButton } from "./EditAlertSettingButton";
import { capitalizeFirst, formatReadingType } from "../Utils";
import { DeleteAlertSettingButton } from "./DeleteAlertSettingButton";

const CellMenu: FC<{
  settingName: string;
  setting: HealthAlertSetting;
  onDone: () => void;
}> = ({ settingName, setting, onDone }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [errorMsgEdit, setErrorMsgEdit] = useState("");
  const [openEdit, setOpenEdit] = useState<boolean>(false);

  const [errorMsgDelete, setErrorMsgDelete] = useState("");
  const [openDelete, setOpenDelete] = useState<boolean>(false);

  useEffect(() => {
    setErrorMsgEdit("");
  }, [settingName]);

  return (
    <div>
      <IconButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreHoriz />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ width: "320rem" }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <EditAlertSettingButton setting={setting} onDone={() => null} />

        <DeleteAlertSettingButton settingName={setting.settingName} />
      </Menu>
    </div>
  );
};

export interface HealthAlertSetting {
  settingName: string;
  readingVariable: string;
  readingType: string;
  speciesTag: string;
  highTrigger: number;
  lowTrigger: number;
}

const alertColumns: GridColDef[] = [
  {
    field: "speciesTag",
    headerName: "Species",
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Stack direction="row">
          <Typography
            component={"span"}
            variant="body2"
            sx={{ cursor: "pointer" }}
          >
            {capitalizeFirst(params.value)}
          </Typography>
        </Stack>
      );
    },
  },
  {
    field: "readingType",
    headerName: "Reading Type",
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography
          component={"span"}
          variant="body2"
          sx={{ cursor: "pointer" }}
        >
          {formatReadingType(params.value)}
        </Typography>
      );
    },
  },
  {
    field: "lowTrigger",
    headerName: "Low Trigger",
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography
          component={"span"}
          variant="body2"
          sx={{ cursor: "pointer" }}
        >
          {params.value}
        </Typography>
      );
    },
  },
  {
    field: "highTrigger",
    headerName: "High Trigger",
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography
          component={"span"}
          variant="body2"
          sx={{ cursor: "pointer" }}
        >
          {params.value}
        </Typography>
      );
    },
  },
  {
    field: "settingName",
    headerName: "",
    align: "center",
    flex: 0.25,
    renderCell: (params: GridRenderCellParams) => (
      <CellMenu
        settingName={params.value}
        setting={params.row as HealthAlertSetting}
        onDone={() => null}
      />
    ),
  },
];

export default function AlertSettings() {
  const { alerts, loading, refresh } = useAdminListHealthAlerts();

  function QuickSearchToolbar() {
    return (
      <Stack
        direction="row"
        justifyContent={"space-between"}
        sx={{
          p: 0.5,
          pb: 0,
        }}
      >
        <GridToolbarQuickFilter
          sx={{ maxWidth: "30%" }}
          quickFilterParser={(searchInput: string) =>
            searchInput
              .split(",")
              .map((value) => value.trim())
              .filter((value) => value !== "")
          }
        />
        <GridToolbarContainer>
          <CreateAlertSettingButton
            existingAlerts={alerts?.map((alert) => alert.settingName) ?? []}
            onDone={refresh}
          />
        </GridToolbarContainer>
      </Stack>
    );
  }

  return (
    <Stack justifyContent="stretch" sx={{ m: 2 }} height="100%">
      <Typography
        component={"span"}
        variant="h5"
        color="inherit"
        textAlign="center"
        sx={{ m: 2 }}
      >
        Alert Settings
      </Typography>

      <div>
        {loading ? (
          <Box width="100%" height="100%">
            <Skeleton variant="rectangular" height={300} />
          </Box>
        ) : (
          <Stack direction="row" alignItems="center" justifyContent="center">
            <Box flex={1}>
              <DataGrid
                autoHeight
                columns={alertColumns}
                components={{ Toolbar: QuickSearchToolbar }}
                disableSelectionOnClick
                hideFooterSelectedRowCount
                rows={alerts?.map((alert, row) => ({
                  id: alert.settingName,
                  key: alert.settingName,
                  ...alert,
                }))}
              />
            </Box>
          </Stack>
        )}
      </div>
    </Stack>
  );
}
