import React from "react";
import { Chip, Icon, Link, Stack, Tooltip, Typography } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridLinkOperator,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";

import {
  LocalHospital,
  PersonOutline,
  Security,
  SupervisorAccount,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";

import { UserDetails, UserSetting } from "../../hooks/user/use-user-details";
import { formatDate } from "../Utils";
import { AddGroupUserButton } from "../Widgets/AddGroupUserButton";

function QuickSearchToolbar(props: { email: string }) {
  return (
    <Stack
      sx={{
        p: 0.5,
        pb: 0,
      }}
      direction="row"
      justifyContent="space-between"
    >
      <GridToolbarQuickFilter
        // sx={{ maxWidth: "30%" }}
        quickFilterParser={(searchInput: string) =>
          searchInput
            .split(",")
            .map((value) => value.trim())
            .filter((value) => value !== "")
        }
      />

      <AddGroupUserButton email={props.email} />
    </Stack>
  );
}

export const userGroupColumns: GridColDef[] = [
  {
    field: "name",
    headerName: "Group Name",
    flex: 2,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography variant="body2" sx={{ cursor: "pointer" }}>
          {params.value}
        </Typography>
      );
    },
  },
  {
    field: "org",
    headerName: "Organisation",
    flex: 2,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Link href={`/orgs/${params.value?.orgId}`} underline="none">
          <Typography
            variant="body2"
            sx={{ cursor: "pointer" }}
            component="div"
          >
            {params.value?.name}
            {params.value?.vetIntegration ? (
              <Chip
                sx={{ marginLeft: "1rem" }}
                icon={<LocalHospital />}
                variant="outlined"
                color="info"
                label={params.value.vetIntegration}
              />
            ) : null}
          </Typography>
        </Link>
      );
    },
  },
  {
    field: "created",
    headerName: "Created",
    flex: 1.5,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography variant="body2" sx={{ cursor: "pointer" }}>
          {formatDate(params.value)}
        </Typography>
      );
    },
  },
  {
    field: "animalCount",
    headerName: "Animals",
    flex: 0.5,
    align: "center",
    headerAlign: "center",
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography variant="body2" sx={{ cursor: "pointer" }}>
          {params.value}
        </Typography>
      );
    },
  },
  {
    field: "hidden",
    headerName: "Visibility",
    flex: 1,
    align: "center",
    headerAlign: "center",
    renderCell: (params: GridRenderCellParams) => {
      return params.value === true ? (
        <Tooltip title="User has hidden this group in the app">
          <Stack direction="row" spacing={2}>
            <Icon>
              <VisibilityOff />
            </Icon>
            <Typography>Hidden</Typography>
          </Stack>
        </Tooltip>
      ) : (
        <Tooltip title="This group is not hidden in the app">
          <Stack direction="row" spacing={2}>
            <Icon>
              <Visibility />
            </Icon>
            <Typography>Shown</Typography>
          </Stack>
        </Tooltip>
      );
    },
  },
  {
    field: "access",
    headerName: "Access",
    flex: 1,
    align: "center",
    headerAlign: "center",
    renderCell: (params: GridRenderCellParams) => {
      switch (params.value) {
        case "owner":
          return (
            <Tooltip title="Owners can add and removed users to the group">
              <Stack direction="row" spacing={2}>
                <Icon>
                  <Security />
                </Icon>
                <Typography>Owner</Typography>
              </Stack>
            </Tooltip>
          );

        case "admin":
          return (
            <Tooltip title="Admins can add or remove members">
              <Stack direction="row" spacing={2}>
                <Icon>
                  <SupervisorAccount />
                </Icon>
                <Typography>Admin</Typography>
              </Stack>
            </Tooltip>
          );
        default:
          return (
            <Tooltip title="Members can view group animals">
              <Stack direction="row" spacing={2}>
                <Icon>
                  <PersonOutline />
                </Icon>
                <Typography>Member</Typography>
              </Stack>
            </Tooltip>
          );
      }
    },
  },
];

export const UserGroups: React.FC<{
  user: UserDetails;
}> = ({ user }) => {
  let navigate = useNavigate();

  const userGroups = user.groups ?? [];

  const hiddenGroupSetting: UserSetting[] =
    user.settings?.filter((setting) => setting.setting === "hiddenGroups") ??
    [];
  const hiddenGroups: string[] = hiddenGroupSetting[0]?.value?.split(" ") ?? [];

  return userGroups.length > 0 ? (
    <DataGrid
      columns={userGroupColumns}
      rows={userGroups
        .filter((group) => group.group != null)
        .map((group) => ({
          id: group?.group?.groupId,
          key: group?.group?.groupId,
          access: group.access,
          org: group?.group?.organisation,
          hidden: hiddenGroups.includes(group?.group?.groupId),
          ...group?.group,
        }))}
      initialState={{
        filter: {
          filterModel: {
            items: [],
            quickFilterLogicOperator: GridLinkOperator.Or,
          },
        },
      }}
      components={{ Toolbar: QuickSearchToolbar }}
      componentsProps={{
        toolbar: {
          email: user.email,
        },
      }}
      onRowClick={(params) => {
        navigate({ pathname: `/groups/${params.id}` }, { replace: false });
      }}
    />
  ) : (
    <Typography textAlign="center" variant="body1" sx={{ m: 5 }}>
      This user does not have any groups.
    </Typography>
  );
};
