import { Card, Stack, Theme, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import React, { FC } from "react";
import {
	ScatterChart,
	Scatter,
	CartesianGrid,
	XAxis,
	YAxis,
	Tooltip,
	ResponsiveContainer,
	Brush,
	Dot,
} from "recharts";
import { AnimalReading } from "../../hooks/animal/types";
import { chartValueFormat, formatDate, readingUnit } from "../Utils";
import useDimensions from "react-cool-dimensions";

const CustomizedDot = (props: any) => {
	const { cx, cy, value, chartHeight, colour } = props;

	if (value == null || value === "NaN") {
		return (
			<Dot cx={cx} cy={chartHeight / 2} r={3} stroke={"-"} fill="#FF0000" />
		);
	}

	return <Dot cx={cx} cy={cy} r={3} stroke={"-"} fill={lineStroke(colour)} />;
};

function lineStroke(type: string) {
	switch (type) {
		case "temperature":
			return "#ff9800";
		case "hrv":
			return "#0084FF";
		case "batteryPercent":
			return "#00C507";
		case "heartRate":
			return "#FF0000";
		default:
			return "#FF0000";
	}
}

export const HistoricalReadingChart: FC<{
	readings: AnimalReading[];
	showNan?: boolean;
}> = ({ readings, showNan }) => {
	const { observe, height } = useDimensions({
		onResize: ({ observe, unobserve, width, height, entry }) => {},
	});

	const theme: Theme = useTheme();

	const goodReadings = [...readings]
		.filter((reading) => reading.floatValue !== null)
		.reverse();

	const sortReadings = [...readings].reverse();

	const readingUnit = () => {
		switch (readings[0].type) {
			case "temperature":
				return "° C";
			case "batteryPercent":
				return "%";
			case "heartRate":
				return " bpm";
			default:
				return "";
		}
	};

	return (
		<Box sx={{ height: "90%" }} ref={observe}>
			{(showNan ? sortReadings.length : goodReadings.length) > 0 ? (
				<ResponsiveContainer width="100%" height="80%">
					<ScatterChart
						width={500}
						height={180}
						data={showNan ? sortReadings : goodReadings}
						margin={{
							top: 5,
							right: 30,
							left: 0,
							bottom: 0,
						}}
					>
						<Scatter
							type="monotone"
							dataKey="floatValue"
							fill={lineStroke(readings[0].type)}
							animationDuration={200}
						/>

						<CartesianGrid stroke="#ccc" />
						<XAxis
							dataKey="datetime"
							tick={{
								fill: theme.palette.mode === "light" ? "black" : "white",
							}}
							tickFormatter={(tick) => formatDate(tick)}
						/>

						<YAxis
							domain={[`auto`, `auto`]}
							tick={{
								fill: theme.palette.mode === "light" ? "black" : "white",
							}}
						/>
						<Tooltip
							content={({ active, payload, label }) => {
								if (active && payload && payload.length) {
									return (
										<Card sx={{ p: 1 }}>
											<div className="custom-tooltip">
												<Typography>
													{formatDate(payload[0].payload["datetime"])}
												</Typography>
												<Typography>
													{chartValueFormat(
														payload[0].payload["floatValue"],
														readings[0].type === "temperature" ? 1 : 0,
														readingUnit()
													)}
												</Typography>
											</div>
										</Card>
									);
								}
							}}
						/>
						<Brush
							stroke={theme.palette.mode === "dark" ? "#b5b0b0" : "black"}
							fill={theme.palette.mode === "dark" ? "#5e5e5e" : "#d9d0d0"}
							fillOpacity={theme.palette.mode === "dark" ? 0.1 : 0.1}
							tickFormatter={(tick) => formatDate(tick)}
							dataKey="datetime"
						/>
					</ScatterChart>
				</ResponsiveContainer>
			) : (
				<Stack direction="column" alignItems="center" display="grid">
					<Typography variant="subtitle1" textAlign="center" sx={{ pt: 5 }}>
						No data
					</Typography>
				</Stack>
			)}
		</Box>
	);
};

export const SynchronisedReadingsChart: FC<{
	heartRate: AnimalReading[];
  hrv: AnimalReading[];
	temperature: AnimalReading[];
	batteryPercent: AnimalReading[];
	showBadReadings?: boolean;
}> = ({ heartRate, hrv, temperature, showBadReadings, batteryPercent }) => {
	const { observe } = useDimensions({
		onResize: ({ observe, unobserve, width, height, entry }) => {},
	});

	const theme = useTheme();

	const heartRateSort = [...heartRate].reverse();
	const temperatureSort = [...temperature].reverse();
	const batteryPercentSort = [...batteryPercent].reverse();
  const hrvSort = [...hrv].reverse();

	const heartRateGood = heartRateSort.filter(
		(reading) => reading.floatValue !== null
	);
	const temperatureGood = temperatureSort.filter(
		(reading) => reading.floatValue !== null
	);
	const batteryPercentGood = batteryPercentSort.filter(
		(reading) => reading.floatValue !== null
	);

  const hrvGood = hrvSort.filter(
    (reading) => reading.floatValue !== null
  );

	return (
		<Box style={{ width: "100%" }} ref={observe}>
			{heartRate.length > 0 && (
				<div>
					<Typography variant="h6" sx={{ textAlign: "center", mt: 1 }}>
						Heart Rate
					</Typography>
					<ResponsiveContainer width="100%" height={180}>
						<ScatterChart
							width={500}
							height={200}
							data={showBadReadings ? heartRateSort : heartRateGood}
							margin={{
								top: 5,
								right: 30,
								left: 0,
								bottom: 0,
							}}
						>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis
								dataKey="datetime"
								tick={{
									fill: theme.palette.mode === "light" ? "black" : "white",
								}}
								tickFormatter={(tick) => formatDate(tick)}
							/>
							<YAxis
								domain={[`auto`, `auto`]}
								tick={{
									fill: theme.palette.mode === "light" ? "black" : "white",
								}}
								tickFormatter={(tick) => tick.toFixed(0)}
							/>
							<Tooltip
								content={({ active, payload, label }) => {
									if (active && payload && payload.length) {
										return (
											<Card sx={{ p: 1 }}>
												<div className="custom-tooltip">
													<Typography>
														{formatDate(payload[0].payload["datetime"])}
													</Typography>
													<Typography>
														<b>
															{chartValueFormat(
																payload[0].payload["floatValue"],
																heartRate[0].type === "temperature" ? 1 : 0,
																readingUnit("heartRate")
															)}
														</b>
													</Typography>
												</div>
											</Card>
										);
									}
								}}
							/>
							<Scatter
								type="monotone"
								dataKey="floatValue"
								fill={lineStroke("heartRate")}
								animationDuration={200}
							/>
							<Brush
								stroke={theme.palette.mode === "dark" ? "#b5b0b0" : "black"}
								fill={theme.palette.mode === "dark" ? "#5e5e5e" : "#d9d0d0"}
								fillOpacity={theme.palette.mode === "dark" ? 0.1 : 0.1}
								dataKey="datetime"
								tickFormatter={(tick) => formatDate(tick)}
							/>
						</ScatterChart>
					</ResponsiveContainer>
				</div>
			)}

      	{hrv.length > 0 && (
				<div>
					<Typography variant="h6" sx={{ textAlign: "center", mt: 1 }}>
						Heart Rate Variability
					</Typography>
					<ResponsiveContainer width="100%" height={180}>
						<ScatterChart
							width={500}
							height={200}
							data={showBadReadings ? hrvSort : hrvGood}
							margin={{
								top: 5,
								right: 30,
								left: 0,
								bottom: 0,
							}}
						>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis
								dataKey="datetime"
								tick={{
									fill: theme.palette.mode === "light" ? "black" : "white",
								}}
								tickFormatter={(tick) => formatDate(tick)}
							/>
							<YAxis
								domain={[`auto`, `auto`]}
								tick={{
									fill: theme.palette.mode === "light" ? "black" : "white",
								}}
								tickFormatter={(tick) => tick.toFixed(0)}
							/>
							<Tooltip
								content={({ active, payload, label }) => {
									if (active && payload && payload.length) {
										return (
											<Card sx={{ p: 1 }}>
												<div className="custom-tooltip">
													<Typography>
														{formatDate(payload[0].payload["datetime"])}
													</Typography>
													<Typography>
														<b>
															{chartValueFormat(
																payload[0].payload["floatValue"],
																heartRate[0].type === "temperature" ? 1 : 0,
																readingUnit("hrv")
															)}
														</b>
													</Typography>
												</div>
											</Card>
										);
									}
								}}
							/>
							<Scatter
								type="monotone"
								dataKey="floatValue"
								fill={lineStroke("hrv")}
								animationDuration={200}
							/>
							<Brush
								stroke={theme.palette.mode === "dark" ? "#b5b0b0" : "black"}
								fill={theme.palette.mode === "dark" ? "#5e5e5e" : "#d9d0d0"}
								fillOpacity={theme.palette.mode === "dark" ? 0.1 : 0.1}
								dataKey="datetime"
								tickFormatter={(tick) => formatDate(tick)}
							/>
						</ScatterChart>
					</ResponsiveContainer>
				</div>
			)}


			{temperature.length > 0 && (
				<div>
					<Typography variant="h6" sx={{ textAlign: "center", m: 2 }}>
						Temperature
					</Typography>
					<ResponsiveContainer width="100%" height={200}>
						<ScatterChart
							width={500}
							height={200}
							data={showBadReadings ? temperatureSort : temperatureGood}
							margin={{
								top: 5,
								right: 30,
								left: 0,
								bottom: 0,
							}}
						>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis
								dataKey="datetime"
								tick={{
									fill: theme.palette.mode === "light" ? "black" : "white",
								}}
								tickFormatter={(tick) => formatDate(tick)}
							/>
							<YAxis
								domain={[`dataMin - 5`, `dataMax + 5`]}
								tick={{
									fill: theme.palette.mode === "light" ? "black" : "white",
								}}
								tickFormatter={(tick) => tick.toFixed(2)}
							/>
							<Tooltip
								content={({ active, payload, label }) => {
									if (active && payload && payload.length) {
										return (
											<Card sx={{ p: 1 }}>
												<div className="custom-tooltip">
													<Typography>
														{formatDate(payload[0].payload["datetime"])}
													</Typography>
													<Typography>
														<b>
															{chartValueFormat(
																payload[0].payload["floatValue"],
																temperatureGood[0].type === "temperature"
																	? 1
																	: 0,
																readingUnit("temperature")
															)}
														</b>
													</Typography>
												</div>
											</Card>
										);
									}
								}}
							/>
							<Scatter
								type="monotone"
								dataKey="floatValue"
								fill={lineStroke("temperature")}
								animationDuration={200}
							/>
							<Brush
								stroke={theme.palette.mode === "dark" ? "#b5b0b0" : "black"}
								fill={theme.palette.mode === "dark" ? "#5e5e5e" : "#d9d0d0"}
								fillOpacity={theme.palette.mode === "dark" ? 0.1 : 0.1}
								dataKey="datetime"
								tickFormatter={(tick) => formatDate(tick)}
							/>
						</ScatterChart>
					</ResponsiveContainer>
				</div>
			)}

			
			{batteryPercent.length > 0 && (
				<div>
					<Typography variant="h6" sx={{ textAlign: "center", m: 2 }}>
						<div>Battery Charge</div>
					</Typography>
					<ResponsiveContainer width="100%" height={200}>
						<ScatterChart
							width={500}
							height={200}
							data={showBadReadings ? batteryPercentSort : batteryPercentGood}
							margin={{
								top: 5,
								right: 30,
								left: 0,
								bottom: 0,
							}}
						>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis
								dataKey="datetime"
								tick={{
									fill: theme.palette.mode === "light" ? "black" : "white",
								}}
								tickFormatter={(tick) => formatDate(tick)}
							/>
							<YAxis
								domain={[`auto`, `auto`]}
								tick={{
									fill: theme.palette.mode === "light" ? "black" : "white",
								}}
								tickFormatter={(tick) => tick.toFixed(0)}
							/>
							<Tooltip
								content={({ active, payload, label }) => {
									if (active && payload && payload.length) {
										return (
											<Card sx={{ p: 1 }}>
												<div className="custom-tooltip">
													<Typography>
														{formatDate(payload[0].payload["datetime"])}
													</Typography>
													<Typography>
														<b>
															{chartValueFormat(
																payload[0].payload["floatValue"],
																batteryPercentGood[0].type === "temperature"
																	? 1
																	: 0,
																readingUnit("batteryPercent")
															)}
														</b>
													</Typography>
												</div>
											</Card>
										);
									}
								}}
							/>
							<Scatter
								type="monotone"
								dataKey="floatValue"
								fill={lineStroke("batteryPercent")}
								animationDuration={200}
							/>
							<Brush
								stroke={theme.palette.mode === "dark" ? "#b5b0b0" : "black"}
								fill={theme.palette.mode === "dark" ? "#5e5e5e" : "#d9d0d0"}
								fillOpacity={theme.palette.mode === "dark" ? 0.1 : 0.1}
								dataKey="datetime"
								tickFormatter={(tick) => formatDate(tick)}
							/>
						</ScatterChart>
					</ResponsiveContainer>
				</div>
			)}
		</Box>
	);
};
