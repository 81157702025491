import { useEffect, useMemo, useState } from "react";

const loadDefault = (key: string) => {
  try {
    const rawValue = window.localStorage.getItem(key);
    if (rawValue != null) {
      return JSON.parse(rawValue);
    }
  } catch { }
};

export const usePreviousGatewaysList = <T>(key: string, maxLen: number = 20) => {
  const [prevGateways, setList] = useState<(T & {_id: string})[]>(
    loadDefault(key) ?? []
  );

  const append = useMemo(
    () => (id: string, value?: T) => 
      value != null && 
        setList((previous) => [
          {_id: id, ...value},
          ...previous.filter((v) => v._id !== id).slice(0, maxLen),
        ]),
    [maxLen]
  );

  const clear = useMemo(() => setList(() => []), []);
  const filter = useMemo(
    () => (f: (v: T) => boolean) => setList((previous) => previous.filter(f)),
    []
  );

  useEffect(() => {
    try{
      window.localStorage.setItem(key, JSON.stringify(prevGateways));
    }catch {
      //ignore
    }
  }, [prevGateways, key]);

  return {prevGateways, append, clear, filter};
}
