import { FC, useState } from "react";
import { Box, LinearProgress, Stack, Switch } from "@mui/material";

export const ToggleRow: FC<{
  label: string;
  defaultChecked?: boolean;
  onChange?: (checked: boolean) => Promise<void> | void;
}> = ({ label, defaultChecked = false, onChange }) => {
  const [updating, setUpdating] = useState(false);
  return (
    <Box position="relative">
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        sx={{
          mt: 2,
          backgroundColor: "inherit",
        }}
        alignItems="center"
        flex={1}
      >
        <Box zIndex={1}>{label}</Box>
        <Switch
          sx={{ zIndex: 1 }}
          defaultChecked={defaultChecked}
          onChange={async (e, checked) => {
            if (onChange) {
              setUpdating(true);
              await onChange(checked);
              setUpdating(false);
            }
          }}
        />
        {updating && (
          <LinearProgress
            sx={{
              height: "100%",
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              opacity: 0.3,
              ml: "0 !important",
            }}
            variant="indeterminate"
          />
        )}
      </Stack>
    </Box>
  );
};
