import { FC } from "react";
import {
  DataGrid,
  GridColDef,
  GridToolbarFilterButton,
  GridToolbarContainer,
  GridRenderCellParams,
  GridLinkOperator,
  GridToolbarExport,
  GridToolbarDensitySelector,
  GridCellParams,
} from "@mui/x-data-grid";
import { Chip, Stack, Tooltip, Typography } from "@mui/material";
import { Launch, Notifications } from "@mui/icons-material";

import { useAnimalNotifications } from "../../hooks/animal/use-animal-notifications";
import { formatDate } from "../Utils";

export interface ImplantNotificationsProps {
  implantId: string;
}

export const ImplantNotifications: FC<ImplantNotificationsProps> = ({
  implantId,
}) => {
  const { notifications, loading } = useAnimalNotifications(implantId);

  if (loading) return <p>Loading...</p>;

  return (
    <DataGrid
      getRowHeight={() => "auto"}
      getEstimatedRowHeight={() => 500}
      columns={notificationColumns}
      rows={notifications.map((notification) => ({
        id: notification.timestamp,
        key: notification.timestamp,
        ...notification,
      }))}
      initialState={{
        filter: {
          filterModel: {
            items: [],
            quickFilterLogicOperator: GridLinkOperator.Or,
          },
        },
      }}
      components={{ Toolbar: QuickSearchToolbar }}
      onCellClick={(params: GridCellParams) => {
        if (params.field === "data") {
          const loc = JSON.parse(params.value);
          if (loc.lat !== null && loc.lon !== null) {
            window.open("https://maps.google.com?q=" + loc.lat + "," + loc.lon);
          }
        }
      }}
      sx={{
        "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
          py: "8px",
        },
        "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
          py: "15px",
        },
        "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
          py: "22px",
        },
      }}
    />
  );
};

function QuickSearchToolbar() {
  return (
    <Stack
      spacing={1}
      direction="row"
      justifyContent="start"
      sx={{
        p: 0.5,
        pb: 0,
      }}
    >
      <Stack direction="row">
        <GridToolbarContainer sx={{ flex: 2 }}>
          <GridToolbarDensitySelector />
        </GridToolbarContainer>
        <GridToolbarContainer sx={{ flex: 2 }}>
          <GridToolbarFilterButton />
        </GridToolbarContainer>
        <GridToolbarContainer sx={{ flex: 2 }}>
          <GridToolbarExport />
        </GridToolbarContainer>
      </Stack>
    </Stack>
  );
}

const notificationColumns: GridColDef[] = [
  {
    field: "timestamp",
    headerName: "Time Stamp",
    type: "dateTime",
    align: "left",
    headerAlign: "left",
    flex: 0.5,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography variant="body2" component={"span"}>
          {formatDate(params.value)}
        </Typography>
      );
    },
  },
  {
    field: "title",
    headerName: "Title",
    type: "string",
    align: "left",
    headerAlign: "left",
    flex: 0.5,
    renderCell: (params: GridRenderCellParams) => {
      return <Typography variant="body2">{params.value}</Typography>;
    },
  },
  {
    field: "body",
    headerName: "Body",
    headerAlign: "left",
    type: "string",
    align: "left",
    flex: 1.5,
    renderCell: (params: GridRenderCellParams) => {
      return <Typography variant="body2">{params.value}</Typography>;
    },
  },

  {
    field: "data",
    headerName: "Data",
    type: "string",
    align: "left",
    headerAlign: "left",
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      const data = JSON.parse(params.value);

      //jank but whatever
      let msg = JSON.stringify(data, null, 2);
      msg = msg.split(`{`)[1];
      msg = msg.split(`}`)[0];
      return (
        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography
            variant="body2"
            sx={{ whiteSpace: "pre", fontSize: "70%", fontFamily: "monospace" }}
          >
            <pre>{msg}</pre>
          </Typography>
          {!!data.lat && !!data.lon && (
            <div>
              <Tooltip title="Open in Google maps">
                <Launch sx={{ cursor: "pointer" }} />
              </Tooltip>
            </div>
          )}
        </Stack>
      );
    },
  },

  {
    field: "resolved",
    headerName: "Resolved",
    type: "dateTime",
    align: "center",
    headerAlign: "center",
    flex: 0.5,
    renderCell: (params: GridRenderCellParams) => {
      if (params.value === null) {
        return (
          <Tooltip title="Notification not resolved">
            <Chip
              icon={<Notifications />}
              color="warning"
              label="Not Resolved"
              variant="outlined"
            />
          </Tooltip>
        );
      } else {
        return (
          <Tooltip title="Notification dismissed by user">
            <Chip
              icon={<Notifications />}
              color="success"
              label="Resolved"
              variant="outlined"
            />
          </Tooltip>
        );
      }
    },
  },
];
