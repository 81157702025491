import { useMemo } from "react";
import { useQuery } from "../graphql/Query";

export interface UserSearchDetails {
  email: string;
  enabled: boolean;
  name: string;
  roles: string[];
  username: string;
  status: string;
  created: string;
  updated: string;
}

export const useAdminUserSearch = (email: string, userpool: string) => {
  const { data, loading } = useQuery(
    ` query SearchUsers($email: String!, $userpool: UserPool!) {
      adminSearchUser(userpool: $userpool, email: $email) {
        email
        enabled
        name
        roles
        username
        status
      }
    }
      
      `,
    { email, userpool },
    email !== ""
  );
  const userList: UserSearchDetails[] = useMemo(() => data?.adminSearchUser ?? [], [data]);
  return { loading, userList };
};
