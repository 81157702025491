import { gql, useQuery } from "@apollo/client";
import { useMemo } from "react";

export interface LogMessage {
  id?: string;
  user_id?: string;
  source?: string;
  type?: string;
  timestamp?: string;
  message?: string;
  device?: string;
  platform?: string;
  app_version?: string;
}

const getUserLogsQuery = gql`
  query getUserEditLogs($username: String!) {
    adminUserLogs(username: $username) {
      id
      user_id
      source
      type
      timestamp
      message
      device
      platform
      app_version
    }
  }
`;

export const useUserAnalytics = (userIdString: string) => {
  const { data, loading, refetch } = useQuery(getUserLogsQuery, {
    variables: {
      username: userIdString,
    },
  });
  const logs: LogMessage[] = useMemo(() => {
    return data?.adminUserLogs ?? [];
  }, [data]);
  return {
    logs,
    loading,
    refresh: refetch,
  };
};
