import { FC, useState } from "react";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Add, Edit, NotificationAdd, Save } from "@mui/icons-material";
import { useAdminCreateHealthAlertSetting } from "../../hooks/health-alerts/use-admin-create-health-setting";
import { capitalizeFirst } from "../Utils";
import { HealthAlertSetting } from "./AlertSettings";
import { readingTypes, speciesList } from "./CreateAlertSettingButton";

export const EditAlertSettingButton: FC<{
  setting: HealthAlertSetting;
  onDone: Function;
}> = ({ setting, onDone }) => {
  const [readingType, setReadingType] = useState<string>(setting.readingType);
  const [species, setSpecies] = useState<string>(setting.speciesTag);

  const [readingVariable, setReadingVariable] = useState<string>();
  const [lowTrigger, setLowTrigger] = useState<string | undefined>(
    setting.lowTrigger?.toString() ?? undefined
  );
  const [highTrigger, setHighTrigger] = useState<string>(
    setting.highTrigger?.toString() ?? undefined
  );

  const [triggerType, setTriggerType] = useState<string>("bounds");

  const [errorMsg, setErrorMsg] = useState("");
  const [open, setOpen] = useState<boolean>(false);

  const handleSetSpecies = (event: SelectChangeEvent) => {
    setSpecies(event.target.value as string);
  };

  const handleSetReadingType = (event: SelectChangeEvent) => {
    const type = event.target.value as string;

    setReadingType(type);

    switch (type) {
      case "heartRate":
      case "spO2":
      case "temperature":
      case "batteryPercent":
        setReadingVariable("floatValue");
        break;
      case "location":
      case "softwareVersion":
        setReadingVariable("stringValue");
        break;
    }
  };

  const handleSubmit = () => {
    const highTrig = parseFloat(highTrigger ?? "");
    const lowTrig = parseFloat(lowTrigger ?? "");

    if (!!highTrig && !!lowTrig) {
      if (highTrig < lowTrig || lowTrig > highTrig) {
        setErrorMsg("Error: bad params. Check trigger values");
        return;
      }
    }
    execute();
  };

  const { loading, execute } = useAdminCreateHealthAlertSetting(
    `${species}-${readingType}`,
    readingType,
    species,
    "floatValue",
    parseFloat(lowTrigger ?? ""),
    parseFloat(highTrigger),
    () => {
      setOpen(false);
      onDone();
    },
    (error) => {
      setErrorMsg(`An error occured\n${error}`);
      console.log(error);
    }
  );

  return (
    <div>
      <Tooltip title="Edit alert setting">
        <MenuItem onClick={() => setOpen(!open)}>
          <ListItemIcon>
            <Edit fontSize="small" color="info" />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
      </Tooltip>
      <Dialog open={open} fullWidth={true}>
        <DialogTitle textAlign="center">Edit Health Alert Setting</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>
            Edit health alert setting: {<b>{setting.settingName}</b>}
            <br /> This will send push notifications to users if a reading
            matching the specified triggers is uploaded.
          </DialogContentText>
          <Stack
            justifyContent="stretch"
            sx={{ m: 2 }}
            height="100%"
            textAlign="center"
          >
            <div />
            <Stack direction="column" spacing={0.5} justifyItems="start">
              <Divider />
              <br />
              <InputLabel id="reading-type-label" sx={{ alignSelf: "start" }}>
                Species
              </InputLabel>
              <Select
                labelId="species-label"
                value={species}
                onChange={handleSetSpecies}
              >
                {speciesList.map((species) => (
                  <MenuItem value={species} key={species}>
                    <Typography textAlign="left">
                      {capitalizeFirst(species)}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
              <br />
              <InputLabel id="reading-type-label" sx={{ alignSelf: "start" }}>
                Reading Type
              </InputLabel>
              <Select
                labelId="reading-type-select"
                value={readingType}
                onChange={handleSetReadingType}
              >
                {readingTypes.map((reading) => (
                  <MenuItem value={reading} key={reading}>
                    <Typography textAlign="left">
                      {capitalizeFirst(reading)}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
              <br />
              <InputLabel id="reading-type-label" sx={{ alignSelf: "start" }}>
                Trigger Type
              </InputLabel>
              <Select labelId="trigger-type-label" value={triggerType}>
                <MenuItem value="bounds">
                  <Typography textAlign="left">Bounds</Typography>
                </MenuItem>
              </Select>
              <br />
              {triggerType === "bounds" && (
                <Stack direction="row" sx={{ p: 2 }}>
                  <Box sx={{ width: "50%" }}>
                    <InputLabel
                      id="reading-type-label"
                      sx={{ alignSelf: "start" }}
                    >
                      Low Trigger
                    </InputLabel>
                    <TextField
                      value={lowTrigger === null ? "" : lowTrigger}
                      onChange={(event) => setLowTrigger(event.target.value)}
                    />
                  </Box>
                  <Box sx={{ width: "50%" }}>
                    <InputLabel
                      id="reading-type-label"
                      sx={{ alignSelf: "start" }}
                    >
                      High Trigger
                    </InputLabel>
                    <TextField
                      value={highTrigger === null ? "" : highTrigger}
                      onChange={(event) => setHighTrigger(event.target.value)}
                    />
                  </Box>
                </Stack>
              )}
            </Stack>
          </Stack>
        </DialogContent>
        {errorMsg !== "" && (
          <Alert sx={{ m: 2 }} severity="error" onClose={() => setErrorMsg("")}>
            {errorMsg}
          </Alert>
        )}
        <DialogActions sx={{ justifyContent: "space-between", p: 2 }}>
          <Button
            variant="contained"
            disabled={loading}
            onClick={() => {
              setOpen(false);
            }}
            color="error"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={loading}
            onClick={() => {
              setErrorMsg("");
              handleSubmit();
            }}
            startIcon={
              !loading ? (
                <Save />
              ) : (
                <CircularProgress size={20} color="inherit" />
              )
            }
          >
            {!loading ? "Save" : "Saving"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
