import { gql, useQuery } from "@apollo/client";
import { useMemo } from "react";

export const getRawReadingUrlQuery = gql`
  query GetRawReadingUrl($path: String!) {
    getRawReadingsUrl(path: $path, urlType: "getObject")
  }
`;

export const useGetRawReadingUrl = (file: string) => {
  const { data, loading } = useQuery(getRawReadingUrlQuery, {
    variables: {
      path: file,
      urlType: "getObject",
    },
    skip: !file,
  });
  const rawReadingUrl: string = useMemo(
    () => data?.getRawReadingsUrl ?? null,
    [data]
  );
  return { loading, rawReadingUrl };
};
