import { gql, useMutation } from "@apollo/client";
import { useMemo } from "react";
import { adminGetOrganisationQuery, OrgDetails } from "./use-admin-get-organisation";

export const useAdminSetVetIntegration = (orgId: string, onCompleted: (data: any) => void) => {
  const [execute, { data, ...mutationResult }] = useMutation(
    gql`
      mutation SetVetIntegration($vetIntegration: VetSoftware, $vetIntegrationSettings: String, $orgId: ID!) {
        adminSetVetIntegration(
          organisation: {
            orgId: $orgId
            vetIntegration: $vetIntegration
            vetIntegrationSettings: $vetIntegrationSettings
          }
        ) {
          orgId
          name
          vetIntegration
        }
      }
    `,
    {
      variables: {},
      onCompleted,
      refetchQueries: [{ query: gql(adminGetOrganisationQuery), variables: { orgId } }],
    }
  );
  const orgDetails: OrgDetails = useMemo(() => data ?? null, [data]);
  return { orgDetails, ...mutationResult, execute };
};
