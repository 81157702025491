import { Dispatch, FC, SetStateAction, useMemo, useState } from "react";
import {
  Box,
  BoxProps,
  Button,
  LinearProgress,
  ListItemIcon,
  Menu,
  MenuItem,
} from "@mui/material";

import { LogObjectType, LogRecord } from "./LogRecord";
import { LogUserRow, LogObjectRow } from "./LogRows";
import { ArrowDropDown, Check } from "@mui/icons-material";

interface LogFilters {
  ignoreLinks: boolean;
  ignoreTypes: LogObjectType[];
}

export const LogHistory: FC<{
  user?: boolean;
  loading?: boolean;
  logs: LogRecord[];
}> = ({ user = false, loading, logs }) => {
  const [filter, setFilter] = useState<LogFilters>({
    ignoreLinks: false,
    ignoreTypes: [],
  });

  const filteredLogs = useMemo(() => {
    let ret = logs;
    if (filter.ignoreLinks) {
      ret = ret.filter((l) => !l.action.includes("link"));
    }
    if (filter.ignoreTypes.length > 0) {
      const ignoreSet = new Set(filter.ignoreTypes);
      ret = ret.filter((l) => !ignoreSet.has(l.object));
    }
    return ret;
  }, [logs, filter]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="stretch"
      minWidth="100%"
      height="100%"
      sx={{ overflowX: "auto", overflowY: "hidden" }}
    >
      <LogHeader user={user} filters={filter} setFilters={setFilter} />
      <Box
        flex="1 0"
        width="1600px"
        sx={{ overflowX: "hidden", overflowY: "auto" }}
      >
        {filteredLogs.map((log) =>
          user ? (
            <LogUserRow
              log={log}
              key={`${log.object}-${log.objectId}-${log.action}-${log.timestamp}`}
            />
          ) : (
            <LogObjectRow
              log={log}
              key={`${log.object}-${log.objectId}-${log.action}-${log.timestamp}`}
            />
          )
        )}
        {loading && <LinearProgress />}
      </Box>
    </Box>
  );
};

const LogHeaderCell: FC<{ grow?: boolean; width?: number } & BoxProps> = ({
  grow,
  width,
  children,
  ...props
}) => (
  <Box
    flex={`${grow ? 1 : 0} 0 ${width}rem`}
    overflow="hidden"
    textOverflow="ellipsis"
    paddingY={0}
    paddingX={1}
    fontWeight="500"
    fontSize="12pt"
    color="#999"
    {...props}
  >
    {children}
  </Box>
);

const LogHeader: FC<{
  user?: boolean;
  filters?: LogFilters;
  setFilters?: Dispatch<SetStateAction<LogFilters>>;
}> = ({ user = false, filters, setFilters }) => {
  const [actionFilterAnchorEl, setActionFilterAnchorEl] =
    useState<null | HTMLElement>(null);
  const [targetFilterAnchorEl, setTargetFilterAnchorEl] =
    useState<null | HTMLElement>(null);
  const actionOpen = Boolean(actionFilterAnchorEl);
  const targetOpen = Boolean(targetFilterAnchorEl);
  return (
    <Box
      flex="0"
      display="flex"
      justifyContent="stretch"
      width="1600px"
      alignItems="center"
      borderTop="1px solid rgba(0, 0, 0, 0.12)"
      borderBottom="1px solid rgba(0, 0, 0, 0.12)"
    >
      <LogHeaderCell width={17}>
        {user ? (
          <>
            <Button
              id="target-filter-menu-button"
              aria-controls={targetOpen ? "target-filter-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={targetOpen ? "true" : undefined}
              onClick={(e) => setTargetFilterAnchorEl(e.currentTarget)}
              sx={{ fontSize: "inherit", textTransform: "none" }}
            >
              Target <ArrowDropDown />
            </Button>
            <Menu
              id="target-filter-menu"
              anchorEl={targetFilterAnchorEl}
              open={targetOpen}
              onClose={() => setTargetFilterAnchorEl(null)}
              MenuListProps={{
                "aria-labelledby": "target-filter-menu-button",
              }}
            >
              {(["animal", "group", "org"] as LogObjectType[]).map((item) => (
                <MenuItem
                  key={item}
                  onClick={() => {
                    setFilters &&
                      setFilters(({ ignoreTypes, ...other }) => ({
                        ignoreTypes: ignoreTypes.includes(item)
                          ? ignoreTypes.filter((a) => a !== item)
                          : [...ignoreTypes, item],
                        ...other,
                      }));
                    setTargetFilterAnchorEl(null);
                  }}
                >
                  <ListItemIcon>
                    {filters?.ignoreTypes.includes(item) ? <Check /> : null}
                  </ListItemIcon>
                  Hide {item} entries
                </MenuItem>
              ))}
            </Menu>
          </>
        ) : (
          "User"
        )}
      </LogHeaderCell>
      <LogHeaderCell width={10}>
        <Button
          id="action-filter-menu-button"
          aria-controls={actionOpen ? "action-filter-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={actionOpen ? "true" : undefined}
          onClick={(e) => setActionFilterAnchorEl(e.currentTarget)}
          sx={{ fontSize: "inherit", textTransform: "none" }}
        >
          Action <ArrowDropDown />
        </Button>
        <Menu
          id="action-filter-menu"
          anchorEl={actionFilterAnchorEl}
          open={actionOpen}
          onClose={() => setActionFilterAnchorEl(null)}
          MenuListProps={{
            "aria-labelledby": "action-filter-menu-button",
          }}
        >
          <MenuItem
            onClick={() => {
              setFilters &&
                setFilters(({ ignoreLinks, ...other }) => ({
                  ignoreLinks: !ignoreLinks,
                  ...other,
                }));
              setActionFilterAnchorEl(null);
            }}
          >
            <ListItemIcon>
              {filters?.ignoreLinks ? <Check /> : null}
            </ListItemIcon>
            Hide linking entries
          </MenuItem>
        </Menu>
      </LogHeaderCell>
      <LogHeaderCell grow width={52}>
        Details
      </LogHeaderCell>
      <LogHeaderCell width={15}>Timestamp</LogHeaderCell>
    </Box>
  );
};
