import { useMutation } from "../use-mutation";

export const useRemoveAnimal = (
  animalId: string,
  onDone: (data: any) => void = () => {},
  onError: (data: Error) => void = () => {}
) => {
  const { loading, execute } = useMutation(
    `mutation AdminRemoveAnimal($animalId: ID!) {
      adminRemoveAnimal(animalId: $animalId) {
        animalId
      }
    }
        
      `,
    { animalId },
    onDone,
    onError
  );
  return { loading, execute };
};
