import { ApolloClient, gql, useApolloClient } from "@apollo/client";
import { useMemo, useState } from "react";
import { UserGroupAccess } from "./use-user-details";

export interface GroupAnimal {
  access: string;
  animalId: string;
  animal: {
    name: string;
    sex: string;
    species: string;
    created: string;
    updated: string;
  };
}

export const getGroupAnimals = async (
  client: ApolloClient<any>,
  groupId: any
) => {
  const res = await client.query<any>({
    query: gql`
      query GetGroupAnimals($groupId: ID!) {
        getAnimalByGroup(groupId: $groupId) {
          items {
            access
            animalId
            animal {
              name
              sex
              species
              created
              updated
            }
          }
        }
      }
    `,
    fetchPolicy: "network-only",
    variables: { groupId },
  });

  return res.data?.getAnimalByGroup?.items ?? [];
};

export const useUserImplants = (groups?: UserGroupAccess[]) => {
  const [animals, setAnimals] = useState<GroupAnimal[]>([]);

  const client = useApolloClient();

  const loadAllImplants = useMemo(
    () => async () => {
      setAnimals([]);
      if (groups != null) {
        for (const group of groups) {
          if (group.group?.groupId != null) {
            try {
              const newAnimals = await getGroupAnimals(
                client,
                group.group.groupId
              );
              if (newAnimals != null) {
                setAnimals((current) => [...current, ...newAnimals]);
              }
            } catch (e) {
              console.error("oops", e);
            }
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(groups)]
  );

  useMemo(() => {
    if (groups != null) {
      for (const group of groups) {
        if (group.group?.groupId != null) {
          client
            .subscribe({
              query: gql`
                subscription GroupAnimalSubscription($groupId: ID!) {
                  onAnimalEventByGroup(groupId: $groupId) {
                    access
                    animalId
                    created
                    groupId
                    animal {
                      animalId
                      breed
                      colour
                      created
                      dob
                      implantHwVersion
                      implantModel
                      implantSwVersion
                      microchip
                      name
                      notes
                      photo
                      sex
                      species
                      updated
                      weight
                      implantSwVersion
                    }
                    notifications {
                      animalId
                      body
                      data
                      notificationType
                      resolved
                      timestamp
                      title
                    }
                    readings {
                      animalId
                      booleanValue
                      datetime
                      floatValue
                      intValue
                      stringValue
                      type
                    }
                    vetLinks {
                      animalId
                      created
                      orgId
                      orgName
                      vetId
                    }
                  }
                }
              `,
              variables: { groupId: group.group.groupId },
            })
            .subscribe({
              error: (e) => {
                console.error(e);
              },
              next: (s) => {
                loadAllImplants();
              },
            });

          client
            .subscribe({
              query: gql`
                subscription RemoveGroupAnimalSubscription($groupId: ID!) {
                  onRemoveAnimalEventByGroup(groupId: $groupId) {
                    access
                    animalId
                    created
                    groupId
                  }
                }
              `,
              variables: { groupId: group.group.groupId },
            })
            .subscribe({
              error: (e) => {
                console.log("sub error", e);
              },
              next: (s) => {
                loadAllImplants();
              },
            });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(groups)]);

  useMemo(() => {
    loadAllImplants();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(groups)]);

  return { animals, refresh: loadAllImplants };
};
