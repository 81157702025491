import { gql, useMutation } from "@apollo/client";
import { adminGetAnimalReadings } from "../animal/use-animal-current-readings";
import { adminGetFirmwareQuery } from "./use-admin-get-implant-firmware";

export const useAdminRemoveImplantFirmware = (
  animalId: string,
  onCompleted: (data: any) => void = () => {}
) => {
  const [execute, { data, ...mutationResult }] = useMutation(
    gql`
      mutation RemoveImplantFirmware($animalId: ID!) {
        adminRemoveImplantFirmware(animalId: $animalId) {
          animalId
        }
      }
    `,
    {
      variables: { animalId },
      onCompleted,
      refetchQueries: [
        { query: gql(adminGetFirmwareQuery), variables: { animalId } },
        { query: gql(adminGetAnimalReadings), variables: { animalId } },
      ],
    }
  );
  return { ...mutationResult, execute };
};
