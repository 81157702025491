import { Box, Link, Typography } from "@mui/material";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import { darkTheme, lightTheme } from "../../theme";

export function CopyrightFooter(props: any) {
  const { isLightMode, ...otherProps } = props;

  return (
    <ThemeProvider theme={isLightMode ? darkTheme : lightTheme}>
      <Box sx={{ mt: 1 }}>
        <Typography
          variant="body2"
          color="text.secondary"
          align="center"
          {...otherProps}
        >
          {"Copyright © "}
          <Link
            color="inherit"
            href="https://www.vetchip.com.au/"
            underline="none"
          >
            Apsyrtus Biotechnology
          </Link>{" "}
          {new Date().getFullYear()}
          {"."}
        </Typography>
      </Box>
    </ThemeProvider>
  );
}
