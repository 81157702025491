import {
  DataGrid,
  GridColDef,
  GridLinkOperator,
  GridRenderCellParams,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import {
  CheckCircle,
  LocalHospital,
  PersonOff,
  PersonOutline,
  Security,
  SentimentDissatisfiedOutlined,
  SupervisorAccount,
} from "@mui/icons-material";
import {
  Box,
  Chip,
  Icon,
  Paper,
  Skeleton,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { formatDate } from "../Utils";
import { useAdminGetOrgDetails } from "../../hooks/orgs/use-admin-get-organisation";
import { usePrevOrgs } from "../../hooks/use-prev-orgs";
import React, { useMemo } from "react";
import { OrgGroups } from "./OrgGroups";
import { ObjectLog } from "../Common/ObjectLogs";
import { TabPanel } from "../Common/Tabs";
import { CopyText } from "../Common/CopyText";
import { UpdateVetIntegration } from "./UpdateVetIntergration";
import { AddOrgUserButton } from "../Widgets/AddOrgUserButton";

const userColumns: GridColDef[] = [
  {
    field: "email",
    headerName: "Email",
    flex: 2,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography
          component={"span"}
          variant="body2"
          textAlign="center"
          sx={{ cursor: "pointer" }}
        >
          <CopyText>{params.value}</CopyText>
        </Typography>
      );
    },
  },
  {
    field: "username",
    headerName: "ID",
    flex: 2.5,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography
          component={"span"}
          variant="body2"
          textAlign="center"
          sx={{ cursor: "pointer" }}
        >
          <CopyText>{params.value}</CopyText>
        </Typography>
      );
    },
  },
  {
    field: "created",
    headerName: "Date Added",
    flex: 1.5,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography
          component={"span"}
          variant="body2"
          sx={{ cursor: "pointer" }}
        >
          {formatDate(params.value)}
        </Typography>
      );
    },
  },
  {
    field: "enabled",
    headerName: "Account enabled",
    flex: 1.5,
    renderCell: (params: GridRenderCellParams) => {
      if (params.value) {
        return (
          <Chip
            size="small"
            icon={<CheckCircle />}
            color="success"
            label="Enabled"
            variant="outlined"
            sx={{ ml: 1 }}
          />
        );
      } else {
        return (
          <Chip
            size="small"
            icon={<PersonOff />}
            color="warning"
            label="Disabled"
            variant="outlined"
            sx={{ ml: 1 }}
          />
        );
      }
    },
  },
  {
    field: "access",
    headerName: "Organisation Access",
    flex: 1.5,
    renderCell: (params: GridRenderCellParams) => {
      switch (params.value) {
        case "owner":
          return (
            <Tooltip title="This is owner of the organisation">
              <Stack direction="row" spacing={2}>
                <Icon>
                  <Security />
                </Icon>
                <Typography component={"span"}>Owner</Typography>
              </Stack>
            </Tooltip>
          );

        case "admin":
          return (
            <Tooltip title="This user is an admin">
              <Stack direction="row" spacing={2}>
                <Icon>
                  <SupervisorAccount />
                </Icon>
                <Typography component={"span"}>Admin</Typography>
              </Stack>
            </Tooltip>
          );
        default:
          return (
            <Tooltip title="This user is a member">
              <Stack direction="row" spacing={2}>
                <Icon>
                  <PersonOutline />
                </Icon>
                <Typography component={"span"}>Member</Typography>
              </Stack>
            </Tooltip>
          );
      }
    },
  },
];

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function OrgDetails() {
  const { orgId } = useParams();
  const { append } = usePrevOrgs();

  let navigate = useNavigate();

  const { orgDetails, loading } = useAdminGetOrgDetails(orgId);
  const orgOwner = orgDetails?.users?.find((user) => user.access === "owner");

  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useMemo(() => {
    if (orgId != undefined) {
      append(orgId, orgDetails);
    }
  }, [append, orgDetails]);

  function MemberToolbar() {
    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          p: 0.5,
          pb: 0,
        }}
      >
        <GridToolbarQuickFilter
          quickFilterParser={(searchInput: string) =>
            searchInput
              .split(",")
              .map((value) => value.trim())
              .filter((value) => value !== "")}
        />
        <AddOrgUserButton orgId={orgDetails.orgId} orgName={orgDetails.name} />
      </Stack>
    );
  }

  return (
    <Stack
      spacing={2}
      sx={{ px: 1, py: 2 }}
      height="100%"
      justifyContent="stretch"
    >
      {loading
        ? (
          <Box sx={{ m: 2 }}>
            <Skeleton width="30%" height="2rem" />
            <Skeleton width="15%" height="0.8rem" />
            <Skeleton width="15%" />
            <Skeleton width="15%" />
          </Box>
        )
        : orgDetails
          ? (
            <Box sx={{ m: 2 }}>
              <Typography
                component={"span"}
                variant="h5"
                color="inherit"
                justifyContent="center"
              >
                {`${orgDetails?.name}`}
              </Typography>
              <Typography variant="body2" component="div">
                {orgId ? <CopyText>{orgId}</CopyText> : "< no ID specified >"}
              </Typography>

              <Typography component={"span"} variant="subtitle1">
                {orgDetails?.users?.length ?? ""} member
                {orgDetails?.users?.length === 1 ? "" : "s"}
              </Typography>
              <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
                <div>
                  {orgDetails?.vetIntegration === "EzyVet" && (
                    <Tooltip title="Linked to EzyVet">
                      <Chip
                        icon={<LocalHospital />}
                        variant="outlined"
                        color="info"
                        label="EzyVet"
                      />
                    </Tooltip>
                  )}
                </div>
                <UpdateVetIntegration
                  orgId={orgDetails.orgId}
                  onDone={() => { }}
                />
              </Stack>
            </Box>
          )
          : (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              width="100%"
              marginTop="5rem"
            >
              <SentimentDissatisfiedOutlined
                fontSize="large"
                color="error"
                sx={{ fontSize: "60pt", mr: 1 }}
              />
              <Typography component={"span"} variant="h3" color="error">
                Org Not Found
              </Typography>
            </Stack>
          )}
      {loading
        ? (
          <Box>
            <Skeleton variant="rectangular" height="20rem" />
          </Box>
        )
        : (
          orgDetails && (
            <>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs value={value} onChange={handleChange} aria-label="">
                  <Tab label="Org Members" {...a11yProps(0)} />
                  <Tab label="Groups" {...a11yProps(0)} />
                  <Tab label="Edit Logs" {...a11yProps(0)} />
                </Tabs>
              </Box>
              <TabPanel value={value} index={0} flex="1" m={0} pb={2}>
                <Paper
                  sx={{
                    p: 2,
                    height: "100%",
                  }}
                >
                  <DataGrid
                    columns={userColumns}
                    rows={orgDetails?.users?.map((
                      { user, access, created },
                    ) => ({
                      id: user?.username,
                      key: user?.username,
                      ...user,
                      access,
                      created,
                    })) ?? []}
                    initialState={{
                      filter: {
                        filterModel: {
                          items: [],
                          quickFilterLogicOperator: GridLinkOperator.Or,
                        },
                      },
                    }}
                    components={{
                      Toolbar: MemberToolbar,
                    }}
                    onRowClick={(params) => {
                      navigate(
                        {
                          pathname:
                            `/users/professional/${params.id}/${params.row.email}`,
                        },
                        { replace: false },
                      );
                    }}
                  />
                </Paper>
              </TabPanel>
              <TabPanel value={value} index={1} flex="1" m={0} pb={2}>
                {orgOwner != null
                  ? <OrgGroups owner={orgOwner} org={orgDetails} />
                  : (
                    <Typography component={"span"}>
                      This org has no owner....
                    </Typography>
                  )}
              </TabPanel>
              <TabPanel
                value={value}
                index={2}
                flex="1 1"
                overflow="auto"
                m={0}
                pb={2}
              >
                <Paper sx={{ p: 2, height: "100%" }}>
                  {orgId && <ObjectLog type="org" id={orgId} />}
                </Paper>
              </TabPanel>
            </>
          )
        )}
    </Stack>
  );
}
