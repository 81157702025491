import { gql, useMutation } from "@apollo/client";
import { listAlertSettingsQuery } from "./use-admin-list-alert-settings";
import { useMemo } from "react";

export const useAdminDeleteHealthAlertSetting = (
  settingName: string,
  onCompleted: (data: any) => void = () => {},
  onError: (data: any) => void
) => {
  const [execute, { data, ...mutationResult }] = useMutation(
    gql`
      mutation DeleteHealthAlertSetting($settingName: String!) {
        adminRemoveHealthAlert(settingName: $settingName)
      }
    `,
    {
      variables: {
        settingName,
      },
      onCompleted,
      onError,
      refetchQueries: [{ query: gql(listAlertSettingsQuery), variables: {} }],
    }
  );
  const alertSetting: string = useMemo(() => data ?? null, [data]);
  return { alertSetting, ...mutationResult, execute };
};
