import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Icon,
  Stack,
  Tooltip,
  Typography,
  Skeleton,
  Paper,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridLinkOperator,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import {
  Security,
  SupervisorAccount,
  PersonOutline,
} from "@mui/icons-material";

import { useUserDetails } from "../../hooks/user/use-user-details";
import {
  OrgDetails,
  OrgUserAccess,
} from "../../hooks/orgs/use-admin-get-organisation";
import { formatDate } from "../Utils";

export const orgGroupColumns: GridColDef[] = [
  {
    field: "name",
    headerName: "Group Name",
    flex: 2,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography variant="body2" sx={{ cursor: "pointer" }}>
          {params.value}
        </Typography>
      );
    },
  },
  {
    field: "created",
    headerName: "Created",
    flex: 1.5,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography variant="body2" sx={{ cursor: "pointer" }}>
          {formatDate(params.value)}
        </Typography>
      );
    },
  },
  {
    field: "animalCount",
    headerName: "Animals",
    flex: 0.5,
    align: "center",
    headerAlign: "center",
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography variant="body2" sx={{ cursor: "pointer" }}>
          {params.value}
        </Typography>
      );
    },
  },
  {
    field: "access",
    headerName: "Access",
    flex: 1,
    align: "center",
    headerAlign: "center",
    renderCell: (params: GridRenderCellParams) => {
      switch (params.value) {
        case "owner":
          return (
            <Tooltip title="Owners can add and removed users to the group">
              <Stack direction="row" spacing={2}>
                <Icon>
                  <Security />
                </Icon>
                <Typography>Owner</Typography>
              </Stack>
            </Tooltip>
          );

        case "admin":
          return (
            <Tooltip title="Admins can add or remove members">
              <Stack direction="row" spacing={2}>
                <Icon>
                  <SupervisorAccount />
                </Icon>
                <Typography>Admin</Typography>
              </Stack>
            </Tooltip>
          );
        default:
          return (
            <Tooltip title="Members can view group animals">
              <Stack direction="row" spacing={2}>
                <Icon>
                  <PersonOutline />
                </Icon>
                <Typography>Member</Typography>
              </Stack>
            </Tooltip>
          );
      }
    },
  },
];

export const OrgGroups: React.FC<{ owner: OrgUserAccess; org: OrgDetails }> = ({
  owner,
  org,
}) => {
  let navigate = useNavigate();

  const { loading, userDetails } = useUserDetails(
    owner.user.username,
    "professional",
    owner.user.email
  );
  const orgGroups =
    userDetails?.groups?.filter(
      (a) => a.group.organisation?.orgId === org.orgId
    ) ?? [];

  return (
    <Paper sx={{ p: 2, height: "100%" }}>
      {loading ? (
        <Skeleton
          variant="rectangular"
          sx={{ width: "100%", height: "100%" }}
        />
      ) : orgGroups.length > 0 ? (
        <DataGrid
          columns={orgGroupColumns}
          rows={orgGroups.map((group) => ({
            id: group?.group?.groupId,
            key: group?.group?.groupId,
            access: group.access,
            org: group?.group?.organisation,
            ...group?.group,
          }))}
          initialState={{
            filter: {
              filterModel: {
                items: [],
                quickFilterLogicOperator: GridLinkOperator.Or,
              },
            },
          }}
          // components={{ Toolbar: QuickSearchToolbar }}
          onRowClick={(params) => {
            navigate({ pathname: `/groups/${params.id}` }, { replace: false });
          }}
        />
      ) : (
        <Typography textAlign="center">No linked groups</Typography>
      )}
    </Paper>
  );
};
