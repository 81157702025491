import { FC } from "react";
import { Box, BoxProps } from "@mui/material";

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export const TabPanel: FC<TabPanelProps & BoxProps> = ({
  children,
  value,
  index,
  ...other
}) => {
  if (value !== index) return null;
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`tab-panel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {children}
    </Box>
  );
};

export function a11yProps(index: number, prefix = "simple") {
  return {
    id: `${prefix}-tab-${index}`,
    "aria-controls": `${prefix}-tabpanel-${index}`,
  };
}
