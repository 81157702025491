import { FC, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Box,
  Button,
  Dialog,
  IconButton,
  Paper,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridToolbarQuickFilter,
  GridLinkOperator,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridSelectionModel,
} from "@mui/x-data-grid";
import {
  CheckBoxOutlineBlank,
  CheckBoxOutlined,
  CloseSharp,
  TimelineOutlined,
} from "@mui/icons-material";
import { uniqBy } from "lodash";

import { CopyText } from "../Common/CopyText";
import { UserGroupAccess } from "../../hooks/user/use-user-details";
import { useUserImplants } from "../../hooks/user/use_user-implants";
import { MultiAnimalReadingChart } from "../Widgets/MultiAnimalReadingChart";
import { formatDate } from "../Utils";

export const implantColumns: GridColDef[] = [
  {
    field: "name",
    headerName: "Name",
    description: "The name assigned by the user.",
    flex: 1.5,

    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography variant="body2" sx={{ cursor: "pointer" }}>
          {params.value}
        </Typography>
      );
    },
  },
  {
    field: "animalId",
    headerName: "Implant ID",
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography variant="body2" sx={{ cursor: "pointer" }} component="div">
          <CopyText>{params.value}</CopyText>
        </Typography>
      );
    },
  },

  {
    field: "created",
    headerName: "Date Created",
    flex: 1.5,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography variant="body2" sx={{ cursor: "pointer" }}>
          {formatDate(params.value)}
        </Typography>
      );
    },
  },
  {
    field: "updated",
    headerName: "Date Updated",
    description: "When data from the implant was last collected.",
    flex: 1.5,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography variant="body2" sx={{ cursor: "pointer" }}>
          {formatDate(params.value)}
        </Typography>
      );
    },
  },
  {
    field: "species",
    headerName: "Species",
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography variant="body2" sx={{ cursor: "pointer" }}>
          {params.value}
        </Typography>
      );
    },
  },
];

export const UserImplants: FC<{ userGroups: UserGroupAccess[] }> = ({
  userGroups,
}) => {
  let navigate = useNavigate();

  const { animals } = useUserImplants(userGroups);
  const [selectMode, setSelectMode] = useState(false);
  const [showGraph, setShowGraph] = useState(false);
  const uniqueAnimals = useMemo(
    () => uniqBy(animals, (a) => a.animalId),
    [animals]
  );
  const [selectedAnimals, setSelectedAnimals] = useState<string[]>([]);

  function QuickSearchToolbar() {
    return (
      <Stack
        spacing={1}
        direction="row"
        justifyContent="start"
        sx={{
          p: 0.5,
          pb: 0,
        }}
      >
        <Stack direction="row" justifyContent="space-around">
          <GridToolbarContainer sx={{ flex: 2 }}>
            <GridToolbarQuickFilter />
          </GridToolbarContainer>
          <Stack direction="row" justifyItems="end">
            <GridToolbarContainer sx={{ flex: 1 }}>
              <GridToolbarDensitySelector />
            </GridToolbarContainer>
            <GridToolbarContainer sx={{ flex: 2 }}>
              <GridToolbarFilterButton />
            </GridToolbarContainer>
            <GridToolbarContainer sx={{ flex: 1 }}>
              <GridToolbarExport />
            </GridToolbarContainer>
            <GridToolbarContainer sx={{ flex: 4 }}>
              <Button
                sx={{ justifySelf: "start" }}
                onClick={() => setSelectMode(!selectMode)}
                startIcon={
                  !selectMode ? <CheckBoxOutlined /> : <CheckBoxOutlineBlank />
                }
              >
                <Typography fontSize="small">Toggle Select</Typography>
              </Button>
            </GridToolbarContainer>
            {selectedAnimals?.length > 0 ? (
              <GridToolbarContainer sx={{ flex: 2 }}>
                <Button
                  startIcon={<TimelineOutlined />}
                  onClick={() => {
                    setShowGraph(true);
                  }}
                >
                  <Typography fontSize="small"> Graph</Typography>
                </Button>
              </GridToolbarContainer>
            ) : (
              <Box />
            )}
          </Stack>
        </Stack>
        <Dialog open={showGraph} fullScreen>
          {" "}
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <AppBar sx={{ position: "relative" }}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => {
                    setShowGraph(false);
                  }}
                  aria-label="close"
                >
                  <CloseSharp />
                </IconButton>

                <Typography
                  sx={{ ml: 2, flex: 1 }}
                  variant="h6"
                  component="div"
                  textAlign="center"
                >
                  Historical Readings
                </Typography>
              </Toolbar>
            </AppBar>
            <Stack direction={"row"}></Stack>
            <Box height="100%">
              <MultiAnimalReadingChart
                animalList={selectedAnimals}
                readingType={"heartRate"}
              />
            </Box>
          </Paper>
        </Dialog>
      </Stack>
    );
  }

  return uniqueAnimals?.length > 0 ? (
    <DataGrid
      columns={implantColumns}
      rows={uniqueAnimals.map((implant) => ({
        id: implant?.animalId,
        key: implant?.animalId,
        animalId: implant?.animalId,
        ...implant?.animal,
      }))}
      initialState={{
        filter: {
          filterModel: {
            items: [],
            quickFilterLogicOperator: GridLinkOperator.Or,
          },
        },
      }}
      components={{ Toolbar: QuickSearchToolbar }}
      onRowClick={(params) => {
        navigate({ pathname: `/implant/${params.id}` }, { replace: false });
      }}
      checkboxSelection={selectMode}
      onSelectionModelChange={(newSelectionModel: GridSelectionModel) => {
        setSelectedAnimals(newSelectionModel.map((id) => `${id}`));
      }}
    />
  ) : (
    <Typography textAlign="center" variant="body1" sx={{ m: 5 }}>
      This user does not have any animals.
    </Typography>
  );
};
