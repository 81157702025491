import { Avatar, Box, Divider, IconButton, Stack, Tab, Tabs, Typography } from "@mui/material";
import React from "react";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`tab-panel-${index}`} aria-labelledby={`tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export function UserSettings() {
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <div>
      <Stack>
        <Typography variant="h4" color="inherit" justifyContent="flex-start" sx={{ m: 2 }}>
          Settings
        </Typography>
      </Stack>
      <Divider />
      <Stack direction="row" spacing={2}>
        <Box sx={{ width: "100%", height: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={value} onChange={handleChange} aria-label="" orientation="horizontal">
              <Tab label="Profile" {...a11yProps(0)} />
              <Tab label="Email" {...a11yProps(0)} />
              <Tab label="Security" {...a11yProps(0)} />
              <Tab label="Preferences" {...a11yProps(0)} />
            </Tabs>
          </Box>

          <TabPanel value={value} index={0}>
            <Stack direction="column" justifyContent="start">
              <Typography variant="h6" color="inherit" component="div">
                Profile Settings
              </Typography>
              <Stack direction="row" spacing={2} justifyContent="start" sx={{ m: 2 }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "left",
                    flexWrap: "wrap",
                  }}
                >
                  <IconButton>
                    <Avatar />
                  </IconButton>
                </div>
                <Typography variant="body1" color="inherit" component="div">
                  username
                </Typography>
              </Stack>
            </Stack>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Box sx={{ width: "100%", height: "100%" }}>
              <Typography variant="h6" color="inherit" component="div" align="left">
                Email Settings
              </Typography>
            </Box>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Typography variant="h6" color="inherit" component="div" align="left">
              Security Settings
            </Typography>
          </TabPanel>
          <TabPanel value={value} index={3}>
            <Typography variant="h6" color="inherit" component="div" align="left">
              Preferences
            </Typography>
          </TabPanel>
        </Box>
      </Stack>
    </div>
  );
}
