import { useMemo } from "react";
import { useQuery } from "../../graphql/Query";
import { UserSearchDetails } from "../use-admin-user-search";

export interface OrgDetails {
  name: string;
  orgId: string;
  created: string;
  updated: string;
  users: OrgUserAccess[];
  vetIntegration: string;
  vetIntegrationSettings: string;
}

export interface OrgUserAccess {
  access: string;
  user: UserSearchDetails;
  created: string;
  statues: string;
  enabled: string;
}

export const adminGetOrganisationQuery = `query GetOrg($orgId: ID!) {
  adminGetOrganisation(orgId: $orgId) {
    created
    name
    orgId
    updated
    vetIntegration
    users {
      access
      created
      user {
        email
        username
        status
        enabled
      }
    }
  }
}   
    `;

export const useAdminGetOrgDetails = (orgId?: string) => {
  const { data, loading } = useQuery(adminGetOrganisationQuery, { orgId });
  const orgDetails: OrgDetails = useMemo(() => data?.adminGetOrganisation, [data]);
  return { loading, orgDetails };
};
