import { FC, useMemo, useState } from "react";
import { startCase } from "lodash";
import { CloseSharp } from "@mui/icons-material";
import {
  Alert,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  LinearProgress,
  Stack,
  IconButton,
  Paper,
  AppBar,
  Toolbar,
  Button,
  Skeleton,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridLinkOperator,
  GridRenderCellParams,
  GridToolbarContainer,
} from "@mui/x-data-grid";

import { useAnimalReadingsType } from "../../hooks/animal/use-animal-readings";
import { formatDate } from "../Utils";
import { QuickSearchToolbar } from "../Common/QuickSearchToolbarReadings";
import { useDeleteAnimalReadings } from "../../hooks/animal/use-admin-delete-animal-reading";
import { differenceInDays } from "date-fns";

export const readingColumns: GridColDef[] = [
  {
    field: "type",
    headerName: "Type",
    type: "string",
    align: "left",
    headerAlign: "left",
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography variant="body2" sx={{ cursor: "pointer" }}>
          {startCase(params.value)}
        </Typography>
      );
    },
  },
  {
    field: "floatValue",
    headerName: "Value",
    headerAlign: "left",
    type: "number",
    align: "left",
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography
          variant="body2"
          sx={{ cursor: "pointer" }}
          component={"span"}
        >
          {params.value == null
            ? "NaN"
            : params.row.type === "heartRate" ||
              params.row.type === "batteryPercent"
            ? params.value?.toFixed(0)
            : params.value?.toFixed(2)}
        </Typography>
      );
    },
  },
  {
    field: "datetime",
    headerName: "Date",
    type: "dateTime",
    align: "left",
    headerAlign: "left",
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography variant="body2" sx={{ cursor: "pointer" }}>
          {formatDate(params.value)}
        </Typography>
      );
    },
  },
];

export interface ImplantReadingHistoryProps {
  animalId: string;
  type: string;
}
export const ImplantReadingHistory: FC<ImplantReadingHistoryProps> = ({
  animalId,
  type,
}) => {
  const { readings, loading, reload } = useAnimalReadingsType(animalId, type);
  const [selectedRows, setSelectedRows] = useState<Date[]>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [cancelling, setCancelling] = useState(false);
  const [error, setError] = useState<string>("");

  const { execute, progress } = useDeleteAnimalReadings(animalId, type);

  const handleConfirmDelete = async () => {
    setDeleting(true);
    setCancelling(false);
    setError("");

    await execute(
      selectedRows,
      () => {
        if (!cancelling && error !== "") {
          setError("");
          setDeleting(false);
        } else {
          reload();
          handleCloseDialog();
        }
        setDeleting(false);
        setSelectedRows([]);
      },

      (error) => {
        setError(error);
      }
    );
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleDeleteDialogOpen = () => {
    setCancelling(false);
    setOpenDialog(true);
  };

  const handleCancelDelete = () => {
    setCancelling(true);
    setDeleting(false);
    setError("");
    handleCloseDialog();
  };

  const rows = useMemo(
    () =>
      readings?.map((r) => ({
        id: r.datetime,
        key: r.datetime,
        ...r,
      })) ?? [],
    [readings]
  );

  const daysWithReadings = useMemo(() => {
    const ret: Record<string, number> = {};
    for (const r of readings) {
      const key = r.datetime.toString().substring(0, 10);
      ret[key] = (ret[key] ?? 0) + 1;
    }
    console.debug("Days With Readings", ret);
    return ret;
  }, [readings]);

  const daysTotal = useMemo(() => {
    const oldest = readings[readings.length - 1];
    if (oldest) {
      return differenceInDays(new Date(), new Date(oldest.datetime));
    }
    return 0;
  }, [readings]);

  if (loading) {
    return <Skeleton variant="rectangular" height="100%" sx={{ m: 1 }} />;
  }

  return (
    <Stack height="100%">
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        sx={{ width: "100%" }}
      >
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: "120%",
          }}
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleCloseDialog}
                disabled={deleting}
                aria-label="close"
              >
                <CloseSharp />
              </IconButton>

              <DialogTitle> Confirm Delete Readings </DialogTitle>
            </Toolbar>
          </AppBar>

          {!deleting && (
            <div>
              <DialogContent>
                <Typography color="error" variant="h5">
                  Warning!
                </Typography>
                <br />
                This will permanently delete the selected reading
                {selectedRows.length > 1 ? "s" : ""} forever.{" "}
                {<b>There is no way to recover deleted animal readings.</b>}
              </DialogContent>
              <DialogContent>
                Are you sure you want to delete {selectedRows.length} selected
                reading{selectedRows.length > 1 ? "s" : ""}?
              </DialogContent>
            </div>
          )}
          {deleting && (
            <div>
              <DialogContent sx={{ p: 2 }}>
                <Typography color="error" variant="h5">
                  Deleting readings
                </Typography>
                <br />
                <LinearProgress
                  variant="determinate"
                  value={progress}
                  color="error"
                />
              </DialogContent>
            </div>
          )}

          {error !== "" && (
            <Alert
              severity="error"
              action={
                <Button
                  color="inherit"
                  size="small"
                  onClick={() => {
                    handleCancelDelete();
                  }}
                >
                  Ok
                </Button>
              }
            >
              {`An error occurred: ${error}`}
            </Alert>
          )}

          {error === "" && (
            <DialogActions style={{ justifyContent: "space-between" }}>
              <Button
                variant="outlined"
                color="info"
                onClick={() => handleCancelDelete()}
                disabled={cancelling}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="error"
                disabled={deleting}
                onClick={() => handleConfirmDelete()}
              >
                Delete {selectedRows.length} Reading
                {selectedRows.length > 1 ? "s" : ""}
              </Button>
            </DialogActions>
          )}
        </Paper>
      </Dialog>
      <DataGrid
        columns={readingColumns}
        rows={rows}
        initialState={{
          filter: {
            filterModel: {
              items: [],
              quickFilterLogicOperator: GridLinkOperator.Or,
            },
          },
        }}
        onSelectionModelChange={(selection) => {
          const selectedRows = selection.map((row) =>
            rows.find((r) => r.id.toString() === row.toString())
          );
          let selectedDates = selectedRows.map((row) => row!.datetime);
          console.log(selectedDates);
          setSelectedRows(selectedDates);
        }}
        components={{ Toolbar: QuickSearchToolbar }}
        checkboxSelection
        componentsProps={{
          toolbar: {
            animalId: animalId,
            readingType: type,
            numRowsSelected: selectedRows.length,
            onDelete: () => handleDeleteDialogOpen(),
            buttons: [
              <GridToolbarContainer sx={{ flex: 2 }}>
                <Typography whiteSpace="nowrap" color="grey">
                  Readings for {Object.keys(daysWithReadings).length} of{" "}
                  {daysTotal} days...
                </Typography>
              </GridToolbarContainer>,
            ],
          },
        }}
      />
    </Stack>
  );
};
