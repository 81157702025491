import { gql, QueryHookOptions, useQuery as useApolloQuery } from "@apollo/client";

export const useQuery = (
  query: string,
  variables: Record<string, any>,
  runQuery: boolean = true,
  options?: QueryHookOptions
) => {
  const {
    loading,
    error,
    data,
    refetch: refresh,
    fetchMore: loadMore,
    ...queryResult
  } = useApolloQuery(gql(query), { variables, skip: !runQuery, ...options });

  return { loading, data, refresh, loadMore, ...queryResult };
};
