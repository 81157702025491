import { FC, useMemo, useState } from "react";
import Uppy from "@uppy/core";
import { ProgressBar, DragDrop } from "@uppy/react";
import AwsS3 from "@uppy/aws-s3";
import { CheckCircle, DeveloperBoard, Notes } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Divider,
  FormControl,
  InputAdornment,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useMutation } from "../../hooks/use-mutation";
import { useNavigate } from "react-router-dom";
import { useAdminListFirmware } from "../../hooks/firmware/use-admin-list-firmware";
import { McuImage } from "./McuImageUtils";

const Upload: FC<{
  url: string;
  label: string;
  imgType: string;
  versionString: string;
  onDone: () => void;
}> = ({ url, label, imgType, versionString, onDone }) => {
  const [result, setResult] = useState<string | undefined>();
  const [image, setImage] = useState<McuImage>();

  const uppy = useMemo(() => {
    const ret = new Uppy({
      restrictions: { maxNumberOfFiles: 1 },
      autoProceed: true,
      onBeforeUpload: (files) => {
        if (imgType !== "appcore") {
          return files;
        }

        for (const fileID of Object.keys(files)) {
          const file = files[fileID];
          console.log("File " + fileID, file);
          if (file.extension !== "bin") {
            console.log("Wrong file extension:", file.extension);
            return false;
          }

          file.data.arrayBuffer().then((b) => {
            const array = new Uint8Array(b);
            const image = McuImage.decode(array);
            setImage(image);
          });
        }
        return files;
      },
    });

    ret.use(AwsS3, {
      getUploadParameters(file) {
        return {
          method: "PUT",
          url,
          headers: {
            "Content-Type": "application/octet-stream",
          },
        };
      },
    });

    ret.on("progress", (result) => {
      setResult("progress");
    });

    ret.on("error", (result) => {
      setResult("error");
      return (
        <Alert severity="error">An error occured during file upload</Alert>
      );
    });

    ret.on("complete", (result) => {
      // const url = result.successful[0].uploadURL;
      // store.dispatch({
      //   type: 'SET_USER_AVATAR_URL',
      //   payload: { url },
      // })
      setResult(result.successful ? "success" : "error");
      onDone();
    });

    return ret;
  }, [onDone, url, imgType]);
  if (result === "success") {
    return (
      <Box>
        <Box>
          <CheckCircle fontSize="small" color="success" sx={{ mr: 1 }} />
          Done
        </Box>
        <Button variant="contained" onClick={() => setResult(undefined)}>
          Retry
        </Button>
      </Box>
    );
  }
  if (result === "progress") {
    return <ProgressBar uppy={uppy} fixed hideAfterFinish />;
  }
  if (result === "error") {
    return (
      <Box>
        <Alert
          severity="error"
          action={
            <Button
              variant="outlined"
              onClick={() => setResult(undefined)}
              color="error"
            >
              Retry
            </Button>
          }
        >
          An error occured!
        </Alert>
      </Box>
    );
  }

  if (!!result) {
    return (
      <Stack direction="column">
        <Alert
          severity="success"
          action={
            <Button
              color="success"
              variant="contained"
              onClick={() => setResult(undefined)}
            >
              Retry
            </Button>
          }
        >
          <Box>Done uploading {imgType}.</Box>
        </Alert>
        {imgType === "appcore" ? (
          <div>
            {versionString !== image?.header.version.toString() && (
              <Typography color="error">
                Warning! Version string does not match firmware version.{" "}
                {versionString} != {" " + image?.header.version.toString()}
              </Typography>
            )}
            <Box>
              <Typography>{"Version: " + image?.header.version}</Typography>
              <Typography>Hash:</Typography>
              <Typography overflow="auto">{image?.getHash()}</Typography>
            </Box>
          </div>
        ) : (
          <Typography></Typography>
        )}
      </Stack>
    );
  }
  return (
    <Box>
      <DragDrop uppy={uppy} note={label} locale={{ strings: {} }} />
    </Box>
  );
};

export default function AddFirmware() {
  let navigate = useNavigate();
  const { data, execute, loading } = useMutation(
    `mutation CreateVersion($hci: String!, $lmp: String!, $status: String, $version: String!) {
    adminCreateFirmware(hci: $hci, lmp: $lmp, version: $version, status: $status) {
      appcore
      created
      hci
      lmp
      netcore
      status
      version
    }
  }`,
    {}
  );
  const { versions, loading: versionsLoading } = useAdminListFirmware();
  const [version, setVersion] = useState("");
  const [hci, setHci] = useState("");
  const [lmp, setLmp] = useState("");
  const [appcoreDone, setAppcoreDone] = useState(false);
  const [netcoreDone, setNetcoreDone] = useState(false);
  const [error, setError] = useState(false);

  const checkVersion = (newVersion: string) => {
    const match = versions.filter((version) => {
      return version.version === newVersion;
    });
    setError(match.length > 0);
  };

  return versionsLoading ? (
    <Stack
      spacing={2}
      sx={{ m: 2, textAlign: "center" }}
      height="100%"
      justifyContent={"center"}
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Typography variant="h5">Add New Firmware Version</Typography>
      <Divider />
      <Box width="50%" height="100%">
        <Skeleton variant="rectangular" height={50} sx={{ m: 1 }} />
        <Skeleton variant="rectangular" height={50} sx={{ m: 1 }} />
      </Box>
    </Stack>
  ) : (
    <div>
      <Stack
        spacing={2}
        sx={{ m: 2, textAlign: "center" }}
        height="100%"
        justifyItems={"center"}
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography variant="h5">Add New Firmware Version</Typography>
        <Divider />
        {!appcoreDone || !netcoreDone ? (
          <FormControl fullWidth={false}>
            <Stack
              spacing={2}
              sx={{ m: 2, textAlign: "center" }}
              justifyItems={"center"}
              maxWidth={"100%"}
            >
              <TextField
                id="version-string"
                label="Version String"
                disabled={loading || data != null}
                onChange={(event) => {
                  checkVersion(event.target.value);
                  setVersion(event.target.value);
                }}
                helperText={
                  error ? "Firmware version with this name already exists." : ""
                }
                error={error}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Notes />
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                sx={{ minWidth: "100%", textAlign: "center", mr: 2 }}
              />{" "}
              <Typography variant="subtitle2">
                Warning: version string must match the firmware version. i.e.
                "v2.5.99-6-g525276c"
                <br />
                If these do not match, implants may get stuck in upgrade loop.
              </Typography>
              <Stack direction="row" spacing={2} justifyContent="space-between">
                <TextField
                  id="hci"
                  label="HCI"
                  disabled={loading || data}
                  onChange={(event) => {
                    setHci(event.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DeveloperBoard />
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  sx={{ minWidth: "45%", textAlign: "center", mr: 2 }}
                />
                <TextField
                  id="lmp"
                  label="LMP"
                  disabled={loading || data}
                  onChange={(event) => {
                    setLmp(event.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DeveloperBoard />
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  sx={{ minWidth: "45%", textAlign: "center", mr: 2 }}
                />
              </Stack>
              <Typography variant="subtitle2">
                HCI and LMP are used to determine netcore upgrade status. <br />{" "}
                If these are identical to the current values on the implant,
                netcore upgrade will be skipped.
              </Typography>
              {!data && (
                <Box>
                  <Button
                    disabled={
                      !version.trim() || !hci.trim() || !lmp.trim() || error
                    }
                    onClick={() =>
                      execute({
                        version,
                        status: "pending",
                        lmp,
                        hci,
                      })
                    }
                  >
                    Next
                  </Button>
                </Box>
              )}
              {!!data?.adminCreateFirmware.netcore && (
                <Box>
                  <Upload
                    url={data.adminCreateFirmware.netcore}
                    label="Upload NetCore Binary"
                    imgType="netcore"
                    versionString={version}
                    onDone={() => {
                      setNetcoreDone(true);
                    }}
                  />
                </Box>
              )}
              {!!data?.adminCreateFirmware.appcore && (
                <Box>
                  <Upload
                    url={data.adminCreateFirmware.appcore}
                    label="Upload appcore Binary"
                    imgType="appcore"
                    versionString={version}
                    onDone={() => {
                      setAppcoreDone(true);
                    }}
                  />
                </Box>
              )}
            </Stack>
          </FormControl>
        ) : (
          <Stack spacing={2}>
            <Typography variant="h6">
              New firmware created: {version}
            </Typography>
            <Button onClick={() => navigate("/firmware")}>Back</Button>
          </Stack>
        )}
      </Stack>
    </div>
  );
}
