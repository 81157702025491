import { gql, useMutation } from "@apollo/client";
import { adminGetAnimalGroupsQuery } from "../animal/use-animal-groups";
import { useGroupAnimalsQuery } from "./use-group-animals";

export const useLinkAnimalToGroup = (
  groupId: string,
  animalId: string,
  onCompleted: (data: any) => void,
  onError: (data: any) => void
) => {
  const [execute, { data, error, loading, ...mutationResult }] = useMutation(
    gql`
      mutation LinkGroupAnimal($groupId: ID!, $animalId: ID!) {
        linkAnimalToGroup(groupId: $groupId, animalId: $animalId) {
          access
          groupId
          created
          animalId
          animal {
            animalId
            breed
            colour
            created
            dob
            microchip
            name
            sex
            species
            updated
            weight
          }
          readings {
            animalId
            floatValue
            stringValue
            booleanValue
            intValue
            datetime
            type
          }
          notifications {
            body
            data
            notificationType
            resolved
            timestamp
            title
            animalId
          }
          vetLinks {
            animalId
            created
            orgId
            orgName
            vetId
          }
        }
      }
    `,
    {
      variables: { groupId, animalId },
      onCompleted,
      onError,
      refetchQueries: [
        { query: gql(useGroupAnimalsQuery), variables: { groupId } },
        { query: gql(adminGetAnimalGroupsQuery), variables: { animalId } },
      ],
    }
  );
  return { data, error, loading, ...mutationResult, execute };
};
