import { FC, useMemo } from "react";
import { startCase } from "lodash";
import { Stack, Tooltip, Typography } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridLinkOperator,
  GridRenderCellParams,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";

import { useAnimalReadingsType } from "../../hooks/animal/use-animal-readings";
import { formatDate } from "../Utils";
import { Launch } from "@mui/icons-material";
import Geohash from "latlon-geohash";

const locationColumns: GridColDef[] = [
  {
    field: "type",
    headerName: "Type",
    type: "string",
    align: "left",
    headerAlign: "left",
    flex: 0.5,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography variant="body2" sx={{ cursor: "pointer" }}>
          {startCase(params.value)}
        </Typography>
      );
    },
  },
  {
    field: "datetime",
    headerName: "Time Stamp",
    type: "dateTime",
    align: "left",
    headerAlign: "left",
    flex: 0.5,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography variant="body2">{formatDate(params.value)}</Typography>
      );
    },
  },

  {
    field: "stringValue",
    headerName: "GeoHash Record",
    type: "string",
    align: "left",
    headerAlign: "left",
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      try {
        const data = Geohash.decode(params.value);
        if (data.lat != null && data.lon != null) {
          return (
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography
                variant="body2"
                sx={{
                  whiteSpace: "pre",
                  fontSize: "70%",
                  fontFamily: "monospace",
                }}
              >
                <pre>{params.value}</pre>
              </Typography>
            </Stack>
          );
        } else {
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography
              variant="body2"
              color="red"
              sx={{
                whiteSpace: "pre",
                fontSize: "70%",
                fontFamily: "monospace",
              }}
            >
              <pre>Error: Invalid GeoHash</pre>
            </Typography>
          </Stack>;
        }
      } catch {
        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography
            variant="body2"
            color="red"
            sx={{ whiteSpace: "pre", fontSize: "70%", fontFamily: "monospace" }}
          >
            <pre>Error: Invalid GeoHash</pre>
          </Typography>
        </Stack>;
      }
    },
  },
  {
    field: "location",
    headerName: "Location",
    type: "string",
    align: "left",
    headerAlign: "left",
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      if (params.value != null) {
        try {
          const data = Geohash.decode(params.value);
          //jank but whatever
          let msg = JSON.stringify(data, null, 2);
          msg = msg.split(`{`)[1];
          msg = msg.split(`}`)[0];
          return (
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography
                variant="body2"
                sx={{
                  whiteSpace: "pre",
                  fontSize: "70%",
                  fontFamily: "monospace",
                }}
              >
                <pre>{msg}</pre>
              </Typography>
              {!!data.lat && !!data.lon && (
                <div>
                  <Tooltip
                    title="Open in Google maps"
                    onClick={() => {
                      if (data.lat !== null && data.lon !== null) {
                        window.open(
                          "https://maps.google.com?q=" +
                            data.lat +
                            "," +
                            data.lon
                        );
                      }
                    }}
                  >
                    <Launch sx={{ cursor: "pointer" }} />
                  </Tooltip>
                </div>
              )}
            </Stack>
          );
        } catch (e) {
          return (
            <Typography
              variant="body2"
              color="red"
              sx={{
                whiteSpace: "pre",
                fontSize: "70%",
                fontFamily: "monospace",
              }}
            >
              <pre>Error: could not decode geohash</pre>
            </Typography>
          );
        }
      }
      return (
        <Typography
          variant="body2"
          sx={{ whiteSpace: "pre", fontSize: "70%", fontFamily: "monospace" }}
        >
          <pre> - </pre>
        </Typography>
      );
    },
  },
];

function QuickSearchToolbar() {
  return (
    <Stack
      spacing={1}
      direction="row"
      justifyContent="start"
      sx={{
        p: 0.5,
        pb: 0,
      }}
    >
      <Stack direction="row">
        <GridToolbarContainer sx={{ flex: 2 }}>
          <GridToolbarDensitySelector />
        </GridToolbarContainer>
        <GridToolbarContainer sx={{ flex: 2 }}>
          <GridToolbarFilterButton />
        </GridToolbarContainer>
        <GridToolbarContainer sx={{ flex: 2 }}>
          <GridToolbarExport />
        </GridToolbarContainer>
      </Stack>
    </Stack>
  );
}

export interface ImplantLocationHistoryProps {
  animalId: string;
}
export const ImplantLocationHistory: FC<ImplantLocationHistoryProps> = ({
  animalId,
}) => {
  const { readings, loading } = useAnimalReadingsType(animalId, "location");

  const rows = useMemo(
    () =>
      readings?.map((location) => ({
        id: location.datetime,
        key: location.datetime,
        location: location.stringValue,
        ...location,
      })) ?? [],
    [readings]
  );

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <DataGrid
      getRowHeight={() => "auto"}
      getEstimatedRowHeight={() => 500}
      columns={locationColumns}
      rows={rows}
      initialState={{
        filter: {
          filterModel: {
            items: [],
            quickFilterLogicOperator: GridLinkOperator.Or,
          },
        },
      }}
      components={{ Toolbar: QuickSearchToolbar }}
      sx={{
        "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
          py: "8px",
        },
        "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
          py: "15px",
        },
        "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
          py: "22px",
        },
      }}
    />
  );
};
