import { Icon } from "@mui/material";
import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./Home";
import Implants from "../Implants/Implants";
import { ProfileInfo } from "./ProfileInfo";
import { UserDetails } from "../Users/UserDetails";
import Users from "../Users/Users";

import SentimentDissatisfiedOutlinedIcon from "@mui/icons-material/SentimentDissatisfiedOutlined";
import Groups from "../Groups/Groups";
import GroupDetails from "../Groups/GroupDetails";
import { Implant } from "../Implants/Implant";
import { AddUser } from "../Users/AddUser";
import { UserSettings } from "./UserSettings";
import Orgs from "../Organisations/Orgs";
import OrgDetails from "../Organisations/OrgDetails";
import FirmwareVersions from "../Firmware/FirmwareVersions";
import AddFirmware from "../Firmware/AddFirmware";
import RawReadingsSearch from "../RawReadings/RawReadingsSearch";
import AlertSettings from "../Alerts/AlertSettings";
import Gateways from "../Gateways/Gateways";
import { Gateway } from "../Gateways/Gateway";

export const AppBody: FC = () => (
  <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/settings" element={<UserSettings />} />
    <Route path="/users" element={<Users />} />
    <Route path="/users/:pool/:userid/:email" element={<UserDetails />} />
    <Route path="/add-user" element={<AddUser />} />
    <Route path="/profile" element={<ProfileInfo />} />
    <Route path="/groups" element={<Groups />} />
    <Route path="/groups/:groupid" element={<GroupDetails />} />
    <Route path="/orgs" element={<Orgs />} />
    <Route path="/orgs/:orgId" element={<OrgDetails />} />
    <Route path="/implants" element={<Implants />} />
    <Route path="/implant/:implantid" element={<Implant />} />
    <Route path="/gateways" element={<Gateways />} />
    <Route path="/gateway/:gatewayId" element={<Gateway />} />
    <Route path="/firmware/" element={<FirmwareVersions />} />
    <Route path="/add-firmware/" element={<AddFirmware />} />
    <Route path="/raw-readings-search/" element={<RawReadingsSearch />} />

    <Route path="/health-alert-settings" element={<AlertSettings />} />

    <Route
      path="*"
      element={
        <main style={{ padding: "1rem" }}>
          <Icon>
            <SentimentDissatisfiedOutlinedIcon fontSize="medium" />
          </Icon>
          <p>Sorry, looks like that page doesn't exist.</p>
        </main>
      }
    />
  </Routes>
);
