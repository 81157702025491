import { API } from "@aws-amplify/api";
import { useMemo, useState } from "react";
import { AnimalChartData, AnimalReading } from "./types";

export const getAnimalReadings = async (animalId: any, type: string) => {
  const res = await API.graphql<any>({
    query: ` query Query($animalId: String!, $type: String!) {
      getAnimalReadingHistory(animalId: $animalId, type: $type) {
        items {
          type
          booleanValue
          datetime
          floatValue
          intValue
          stringValue
        }
      }
    }`,
    variables: { animalId, type },
    authMode: "AMAZON_COGNITO_USER_POOLS",
  });

  return (res.data?.getAnimalReadingHistory?.items ?? []) as AnimalReading[];
};

export const useMultiAnimalReadings = (type: string, animals?: string[]) => {
  const [readings, setReadings] = useState<AnimalChartData[]>([]);

  console.log(animals);
  useMemo(async () => {
    if (animals != null) {
      for (const animal of animals) {
        try {
          console.log("reading", animal);
          const newReadings = await getAnimalReadings(animal, type);
          let data: AnimalChartData = {
            animalId: animal,
            readings: newReadings,
          };

          if (data != null) {
            setReadings((current) => [...current, data]);
            console.log("new data", data);
          }
        } catch (e) {
          console.error("oops", e);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, JSON.stringify(animals)]);

  return { readings };
};
