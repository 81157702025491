import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputAdornment,
  Link,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { usePreviousUsers } from "../../hooks/use-prev-users";
import {
  CheckCircle,
  ClearAll,
  ExpandMore,
  PersonAdd,
  PersonOff,
  Search,
} from "@mui/icons-material";
import { useAdminUserSearch } from "../../hooks/use-admin-user-search";
import React, { useMemo, useState } from "react";
import { UserRoleIcons } from "../Common/UserRoleIcons";

export default function Users() {
  const [email, setEmail] = useState("");
  const [search, setSearch] = useState("");
  const [userpool, setUserpool] = useState("professional");
  const [expandPrev, setExpandPrev] = useState(true);

  const { prevVals, clear } = usePreviousUsers();
  const { userList, loading } = useAdminUserSearch(
    search ?? "",
    userpool ?? "professional"
  );
  let navigate = useNavigate();

  const handleSearchSubmit = (search: string) => {
    if (search === null || search === "") {
      return;
    }
    setExpandPrev(false);
    setSearch(search);
  };

  const handleUserpoolChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserpool((event.target as HTMLInputElement).value);
  };

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "email",
        headerName: "Email",
        flex: 1,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Stack direction="row">
              <Typography
                component={"span"}
                variant="body2"
                sx={{ cursor: "pointer" }}
              >
                {params.value}
              </Typography>
            </Stack>
          );
        },
      },
      {
        field: "username",
        headerName: "User ID",
        flex: 1,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Typography
              component={"span"}
              variant="body2"
              sx={{ cursor: "pointer" }}
            >
              {params.value}
            </Typography>
          );
        },
      },
      {
        field: "roles",
        headerName: "Roles",
        flex: 1,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Typography
              component={"span"}
              variant="body2"
              sx={{ cursor: "pointer" }}
            >
              <UserRoleIcons pool={userpool} roles={params.value} />
            </Typography>
          );
        },
      },
      {
        field: "status",
        headerName: "Account Status",
        type: "boolean",
        flex: 1,
        renderCell: (params: GridRenderCellParams) => {
          if (params.value === "CONFIRMED") {
            return (
              <Chip
                icon={<CheckCircle />}
                color="success"
                label={params.value}
                variant="outlined"
              />
            );
          } else {
            return (
              <Chip
                icon={<PersonOff />}
                color="warning"
                label={params.value}
                variant="outlined"
              />
            );
          }
        },
      },
    ],
    [userpool]
  );

  return (
    <Stack justifyContent="stretch" sx={{ m: 2 }} height="100%">
      <Typography
        component={"span"}
        variant="h5"
        color="inherit"
        textAlign="center"
      >
        Search for Users
      </Typography>
      <Stack direction="row" spacing="1rem">
        {prevVals.length > 0 && (
          <Stack justifyContent="top" sx={{ py: 2 }}>
            <Accordion
              expanded={expandPrev || (userList?.length ?? 0) < 1}
              onChange={() => {
                setExpandPrev(!expandPrev);
              }}
              onClick={() => {
                setExpandPrev(!expandPrev);
              }}
            >
              <AccordionSummary
                expandIcon={
                  (userList?.length ?? 0) > 1 ? <ExpandMore /> : <div />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography component={"span"} variant="h6" fontSize="medium">
                  Previously Viewed
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ alignItems: "center" }}>
                <ul>
                  {prevVals.map((p) => (
                    <Link
                      key={`${p.pool}-${p.username}`}
                      underline="none"
                      href={`/users/${p.pool}/${p.username}/${p.email}`}
                      textAlign="left"
                    >
                      <Stack direction="row" alignItems="left">
                        <Typography component={"span"} sx={{ pr: 1 }}>
                          {"\u2022  "}
                        </Typography>
                        <Typography component={"span"} textAlign={"left"}>
                          {p.email}
                        </Typography>
                      </Stack>
                    </Link>
                  ))}
                </ul>
                <Divider sx={{ p: "2" }} />
                <br />
                <Button
                  variant="contained"
                  color="error"
                  startIcon={<ClearAll />}
                  onClick={() => {
                    clear();
                    setExpandPrev(false);
                  }}
                >
                  Clear History
                </Button>
              </AccordionDetails>
            </Accordion>
          </Stack>
        )}
        <Stack direction="column" justifyContent="center" alignItems="left">
          <Stack direction="row" justifyContent="center">
            <Box textAlign="center" sx={{ m: 2, width: "30rem" }}>
              <TextField
                fullWidth
                label="Search"
                id="searchbox"
                value={email}
                onKeyPress={(ev) => {
                  handleSearchSubmit(email);
                }}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
                onSubmit={() => {
                  handleSearchSubmit(email);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {loading ? (
                        <CircularProgress style={{ color: "GrayText" }} />
                      ) : (
                        <IconButton
                          aria-label="Search"
                          edge="end"
                          onClick={() => {
                            handleSearchSubmit(email);
                          }}
                        >
                          <Search />
                        </IconButton>
                      )}
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
              />
            </Box>
            <Button
              variant="contained"
              size="large"
              startIcon={<PersonAdd />}
              sx={{ m: 2, justifyItems: "center", maxHeight: "3.5rem" }}
              onClick={() => {
                navigate({ pathname: `/add-user` }, { replace: false });
              }}
            >
              Invite New
            </Button>
          </Stack>
          <FormControl sx={{ m: 2 }}>
            <FormLabel id="user-pool-radio-label">User Pool</FormLabel>
            <RadioGroup
              row
              aria-labelledby="radio-group"
              value={userpool}
              onChange={handleUserpoolChange}
              sx={{ m: 2 }}
            >
              <FormControlLabel
                value="professional"
                control={<Radio />}
                label="Professional"
              />
              <FormControlLabel
                value="consumer"
                disabled
                control={<Radio />}
                label="Consumer"
              />
            </RadioGroup>
          </FormControl>
        </Stack>
      </Stack>
      {!!userList?.length && (
        <Box flex={1}>
          <DataGrid
            columns={columns}
            rows={userList?.map((user, row) => ({
              id: user.username,
              key: user.username,
              avatar: user.email,
              ...user,
            }))}
            onRowClick={(params) => {
              navigate(
                {
                  pathname: `/users/professional/${params.row.username}/${params.row.email}`,
                },
                { replace: false }
              );
            }}
          />
        </Box>
      )}
    </Stack>
  );
}
