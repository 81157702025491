import { useMemo } from "react";
import { useQuery } from "../../graphql/Query";

interface GroupAnimal {
  customTags: string[];
}

export const useAdminGetAnimalTags = (animalId: string) => {
  const { data, loading } = useQuery(
    `query GetAnimalCustomTags($animalId: ID!) {
  adminGetAnimalGroups(animalId: $animalId) {
    customTags
  }
}
`,
    { animalId },
    animalId !== ""
  );

  const groupList: GroupAnimal[] = useMemo(
    () => data?.adminGetAnimalGroups ?? [],
    [data]
  );

  const tagList = groupList
    .map((groupAnimal: GroupAnimal) => groupAnimal.customTags ?? [])
    .flatMap((tags) => [...tags]);

  console.log(tagList);

  return { tagList, loading };
};
