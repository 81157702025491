import { Refresh } from "@mui/icons-material";
import { Button, CircularProgress, Tooltip } from "@mui/material";
import { FC } from "react";
import { useAdminAddImplantFirmware } from "../../hooks/firmware/use-admin-add-implant-firmware";

export const SetImplantFirmwareButton: FC<{
  animalId: string;
  version: string;
  disabled: boolean;
  onDone: (data: any) => void;
}> = ({ animalId, version, disabled, onDone }) => {
  const { loading, execute } = useAdminAddImplantFirmware(
    animalId,
    version,
    onDone
  );

  return (
    <Tooltip title="Set the implant's target firmware version">
      <Button
        disabled={loading || disabled}
        startIcon={
          loading ? <CircularProgress size={20} color="inherit" /> : <Refresh />
        }
        variant="contained"
        color="success"
        onClick={(e) => {
          e.stopPropagation();
          execute();
        }}
      >
        {loading ? "Upating" : "Set Target"}
      </Button>
    </Tooltip>
  );
};
