import { useMemo } from "react";
import { useQuery } from "../../graphql/Query";

export interface FirmwareVersion {
  appcore: string;
  netcore: string;
  hci: string;
  lmp: string;
  version: string;
  created: string;
  status: string;
}

export const adminListFirmwareQuery = `query GetFirmware {
  adminListFirmware {
    nextToken
    items {
      appcore
      hci
      lmp
      netcore
      version
      created
      status
    }
  }
}`;

export const useAdminListFirmware = () => {
  const { data, ...query } = useQuery(adminListFirmwareQuery, {});
  const versions: FirmwareVersion[] = useMemo(() => data?.adminListFirmware?.items ?? [], [data]);
  return { ...query, versions };
};
