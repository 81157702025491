import { Download } from "@mui/icons-material";
import { IconButton, CircularProgress, Box, Button } from "@mui/material";
import { FC, useState, useEffect } from "react";
import {
  useGetRawReadingUrl,
  getRawReadingUrlQuery,
} from "../../hooks/raw-readings/use-raw-reading-url";
import { API } from "aws-amplify";
import { RawReadingsItem } from "../../hooks/raw-readings/use-list-raw-readigns";

// this is horrible and i hate it
export const DownloadAllRawReadingsButton: FC<{ urls: RawReadingsItem[] }> = ({
  urls,
}) => {
  const getDownloadLinks = async () => {
    for (const url of urls) {
      const file = await API.graphql<any>({
        query: `query GetRawReadingUrl($path: String!) {
          getRawReadingsUrl(path: $path, urlType: "getObject")
        }`,
        variables: { path: url.Key },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      window.location.assign(file.data.getRawReadingsUrl);
    }
  };

  return (
    <Button
      variant="contained"
      startIcon={<Download />}
      onClick={async () => {
        await getDownloadLinks();
      }}
    >
      Download All
    </Button>
  );
};

export const DownloadRawReadingButton: FC<{
  file: string;
  onDone: (data: any) => void;
}> = ({ file, onDone }) => {
  const [path, setPath] = useState<string>("");
  const { rawReadingUrl, loading } = useGetRawReadingUrl(path);

  const handleDownloadRequest = () => {
    setPath(file);
  };

  const downloadFile = () => {
    if (rawReadingUrl) {
      window.location.assign(rawReadingUrl);
      return true;
    }
    return false;
  };

  useEffect(() => {
    downloadFile();
  }, [rawReadingUrl]);

  return (
    <Box>
      {loading ? (
        <CircularProgress size={30} />
      ) : (
        <IconButton onClick={() => downloadFile() || handleDownloadRequest()}>
          <Download />
        </IconButton>
      )}
    </Box>
  );
};
