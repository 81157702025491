import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Avatar,
  Menu,
  MenuItem,
  Divider,
  IconButton,
  Tooltip,
  ListItemIcon,
  Theme,
  useTheme,
  FormControlLabel,
  FormGroup,
  Switch,
} from "@mui/material";
import { DarkMode, LightMode, Logout } from "@mui/icons-material";

import { Auth } from "aws-amplify";
import { UserAvatar } from "../Users/UserAvatar";
import { AdminUserAttrs } from "./ProfileInfo";

export interface AccountMenuProps {
  setIsLightMode: React.Dispatch<React.SetStateAction<boolean>>;
}

export function AccountMenu({ setIsLightMode }: AccountMenuProps) {
  let navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const theme: Theme = useTheme();
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const [attributes, setAttributes] = useState<AdminUserAttrs>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    // load auth attributes...
    (async () => {
      try {
        setLoading(true);
        const user = await Auth.currentAuthenticatedUser();
        // console.log("user = ", user);
        setAttributes(() => user.attributes as AdminUserAttrs);
      } catch (e) {
        setError(true);
        console.log(e);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            {loading ? (
              <Avatar />
            ) : (
              <UserAvatar email={attributes?.email ?? ""} />
            )}
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            navigate("profile");
          }}
        >
          {loading ? (
            <Avatar />
          ) : (
            <UserAvatar email={attributes?.email ?? ""} />
          )}
          Profile
        </MenuItem>
        <Divider />

        <MenuItem>
          {theme.palette.mode !== "light" ? (
            <DarkMode color="inherit" />
          ) : (
            <LightMode color="action" />
          )}{" "}
          <Box sx={{ width: 15 }} />
          {
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    color="default"
                    checked={theme.palette.mode !== "light"}
                    onChange={(_) => setIsLightMode((t) => !t)}
                  />
                }
                label=""
              />
            </FormGroup>
          }
        </MenuItem>
        <MenuItem
          onClick={async () => {
            handleClose();
            await Auth.signOut();
            window.location.reload();
          }}
        >
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
