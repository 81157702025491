import { Box, Paper, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import { usePreviousGateways } from "../../hooks/use-prev-gateways";
import { CopyText } from "../Common/CopyText";
import { formatDate } from "../Utils";
import { GatewayVims } from "./GatewayVims";

export interface Gateway {
  gatewayId: string;
  hwVersion: string;
  name?: string;
  rebooted?: Date;
  lastOnline?: Date;
  status?: string;
  updated?: string;
}

export const GatewayDetails: FC<{ gateway: Gateway }> = ({ gateway }) => {
  const { gatewayId } = useParams();

  const { append } = usePreviousGateways();

  useEffect(() => {
    if (gatewayId) {
      append(gatewayId);
    }
  }, [append, gatewayId, gateway]);


  return (
    <Stack
      spacing={2}
      sx={{ px: 1, py: 2 }}
      height="100%"
      justifyContent="stretch"
    >
      <Stack height="50%" width="50%" direction="row" spacing={4}>
        <Paper
          sx={{
            flexBasis: "100%",
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Typography variant="h6" color="inherit" textAlign="left">
            Gateway Details
          </Typography>
          <Stack alignItems="left" sx={{ p: 2 }} spacing={1}>
            <Box width="100%">
              <Typography variant="body2" color="inherit" align="left">
                Gateway ID:{"  "}
                <CopyText>
                  <strong>{gatewayId}</strong>
                </CopyText>
              </Typography>
            </Box>
            <Box width="100%">
              <Typography variant="body2" color="inherit" align="left">
                Name :{"  "}{gateway.name ?? ""}
              </Typography>
            </Box>
            <Box width="100%">
              <Typography variant="body2" color="inherit" align="left">
                Last online:{"  "}{formatDate(gateway?.lastOnline)}
              </Typography>
            </Box>

            <Box width="100%">
              <Typography variant="body2" color="inherit" align="left">
                Last rebooted:{"  "}{formatDate(gateway?.rebooted)}
              </Typography>
            </Box>
          </Stack>
        </Paper>
      </Stack>
      <Stack height="60%" direction="row" spacing={4}>
        <Paper
          sx={{
            flexBasis: "100%",
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Typography
            variant="h6"
            color="inherit"
            textAlign="left"
            sx={{ pb: 2 }}
          >
            Connected Implants
          </Typography>
          <GatewayVims gatewayId={gatewayId ?? ""} />
        </Paper>
      </Stack>
    </Stack>
  );
};
