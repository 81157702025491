import { gql, useMutation } from "@apollo/client";
import { FC, useState } from "react";
import { Box, Button, LinearProgress, TextareaAutosize } from "@mui/material";
import { loadReadingHistoryQuery } from "../../hooks/animal/use-animal-readings";

export const GroupSurveyComment: FC<{
  animalId: string;
  onAdd?: () => Promise<void>;
}> = ({ animalId, onAdd }) => {
  const [note, setNote] = useState("");
  const { addReading, loading } = useAddStringReading(
    animalId,
    "Survey-Notes",
    async () => {
      await onAdd?.();
      setNote("");
    }
  );

  return (
    <>
      <Box>
        <TextareaAutosize
          minRows={4}
          maxRows={20}
          onChange={(e) => setNote(e.target.value)}
          style={{ width: "100%" }}
        />
      </Box>
      {loading && <LinearProgress />}
      <Button
        disabled={note.trim() === "" || loading}
        onClick={() => {
          if (note.trim() !== "") {
            addReading(note);
          }
        }}
      >
        Add Note
      </Button>
    </>
  );
};

const addReadingMutation = gql`
  mutation MyMutation($readings: [AnimalReadingInput]!) {
    addReadings(readings: $readings) {
      successful {
        animalId
        hardwareId
        datetime
        type
        floatValue
        intValue
        stringValue
        booleanValue
      }
    }
  }
`;

const useAddStringReading = (
  animalId: string,
  type: string,
  onDone?: () => void
) => {
  const [rawAddReading, props] = useMutation(addReadingMutation);

  const addReading = (stringValue: string) => {
    rawAddReading({
      variables: {
        readings: [
          {
            animalId,
            type,
            datetime: new Date().toISOString(),
            stringValue,
          },
        ],
      },
      onCompleted: (data) => {
        if (data.addReadings?.successful?.length) {
          onDone?.();
        }
      },
      refetchQueries: [
        {
          query: loadReadingHistoryQuery,
          variables: {
            animalId,
            type: "Survey-Notes",
          },
        },
      ],
    });
  };

  return { addReading, ...props };
};
