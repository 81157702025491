import { gql, useMutation } from "@apollo/client";
import { HealthAlertSetting } from "../../vetchip/Alerts/AlertSettings";
import { useMemo } from "react";
import { listAlertSettingsQuery } from "./use-admin-list-alert-settings";

export const useAdminCreateHealthAlertSetting = (
  settingName: string,
  readingType: string,
  speciesTag: string,
  readingVariable: string,
  lowTrigger: number,
  highTrigger: number,
  onCompleted: (data: any) => void = () => {},
  onError: (data: any) => void
) => {
  const [execute, { data, ...mutationResult }] = useMutation(
    gql`
      mutation CreateSetting(
        $speciesTag: String!
        $settingName: String!
        $readingVariable: String!
        $readingType: String!
        $lowTrigger: Float
        $highTrigger: Float
      ) {
        adminSetHealthAlert(
          setting: {
            highTrigger: $highTrigger
            lowTrigger: $lowTrigger
            readingType: $readingType
            readingVariable: $readingVariable
            settingName: $settingName
            speciesTag: $speciesTag
          }
        ) {
          highTrigger
          lowTrigger
          readingType
          readingVariable
          settingName
          speciesTag
        }
      }
    `,
    {
      variables: {
        settingName,
        readingType,
        readingVariable,
        highTrigger,
        lowTrigger,
        speciesTag,
      },
      onCompleted,
      refetchQueries: [{ query: gql(listAlertSettingsQuery), variables: {} }],
    }
  );
  const alertSetting: HealthAlertSetting = useMemo(() => data ?? null, [data]);
  return { alertSetting, ...mutationResult, execute };
};
