import { useQuery } from "../../graphql/Query";
import { AnimalReading } from "./types";

export const adminGetAnimalReadings = `query GetCurrentReadings($animalId: ID!) {
  adminGetAnimalReadings(animalId: $animalId) {
    type
    stringValue
    intValue
    floatValue
    datetime
    booleanValue
    animalId
  }
}
`;

export const useAnimalCurrentReadings = (animalId: string) => {
  const { data, loading } = useQuery(adminGetAnimalReadings, { animalId });
  return {
    loading,
    readings: (data?.adminGetAnimalReadings ?? []) as AnimalReading[],
  };
};
