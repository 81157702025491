import {
  Paper,
  LinearProgress,
  Typography,
  Button,
  Stack,
} from "@mui/material";
import {
  DataGrid,
  GridLinkOperator,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import { FC, useMemo, useState } from "react";
import { useGroupAnimalsWithReadings } from "../../hooks/group/use-group-animals-with-readings";
import { GroupAccessDetails } from "../../hooks/group/use-group-details";
import { formatRelativeDate } from "../Utils";
import { AnimalReading } from "../../hooks/animal/types";
import { GroupSurveyHealthStatus } from "./GroupSurveyHealthStatus";
import { GroupSurveyNotes } from "./GroupSurveyNotes";
import { Refresh } from "@mui/icons-material";
import { GroupSurveyAnimalDetails } from "./GroupSurveyAnimalDetails";
import { GroupSurveyManualReadingDates } from "./GroupManualReadingDates";

const getSurveyDateString = (readings: AnimalReading[], type: string) => {
  return readings.filter((r) => r.type === type).pop()?.datetime;
};

const surveyColumns = [
  {
    field: "name",
    headerName: "Name",
    flex: 1.5,
    renderCell: (params: GridRenderCellParams) => {
      return <GroupSurveyAnimalDetails row={params.row} />;
    },
  },
  {
    field: "ownerName",
    headerName: "Owner Name",
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      return <Typography variant="body2">{params.value ?? "-"}</Typography>;
    },
  },
  {
    field: "surveyStartedDate",
    headerName: "Survey Started",
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography variant="body2">
          {params.value ? new Date(params.value).toLocaleDateString() : "-"}
        </Typography>
      );
    },
  },
  {
    field: "lastDailyDate",
    headerName: "Last Daily Survey",
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      const daysAgo =
        (Date.now() - new Date(params.value).getTime()) / (24 * 60 * 60 * 1000);
      return (
        <Typography
          variant="body2"
          sx={
            daysAgo > 4
              ? { backgroundColor: "red", color: "white", padding: "0.2rem" }
              : daysAgo > 2
              ? { backgroundColor: "amber", color: "white", padding: "0.2rem" }
              : undefined
          }
        >
          {formatRelativeDate(
            /* bump by a day to get the end of the day */
            params.value &&
              new Date(new Date(params.value).getTime() + 24 * 3600 * 1000)
          )}
        </Typography>
      );
    },
  },
  {
    field: "lastHealth",
    headerName: "Last Health Alert",
    flex: 2,
    renderCell: (params: GridRenderCellParams) => {
      return <GroupSurveyHealthStatus animalId={params.row.id} />;
    },
  },
  {
    field: "lastManual",
    headerName: "Last Manual Reading",
    flex: 2,
    renderCell: (params: GridRenderCellParams) => {
      return <GroupSurveyManualReadingDates animalId={params.row.id} />;
    },
  },
  {
    field: "notes",
    headerName: "Last Health Note",
    flex: 2,
    renderCell: (params: GridRenderCellParams) => {
      return <GroupSurveyNotes animalId={params.row.id} />;
    },
  },
];

export const GroupSurveyDetails: FC<{ groupDetails: GroupAccessDetails }> = ({
  groupDetails,
}) => {
  const { groupAnimals, loading, refresh } = useGroupAnimalsWithReadings(
    groupDetails.groupId
  );

  const [reloadCount, setReloadCount] = useState(0);

  const [hideCurrent, setHideCurrent] = useState(false);
  const [hideNotStarted, setHideNotStarted] = useState(false);

  const animalDetails = useMemo(
    () =>
      groupAnimals.map((implant) => ({
        id: implant.animalId,
        key: implant.animalId,
        groupId: groupDetails.groupId,
        vetLinks: implant.vetLinks,
        readings: implant.readings,
        ...implant.animal,
        surveyStartedDate: getSurveyDateString(
          implant.readings,
          "InitialSurvey"
        ),
        lastDailyDate: getSurveyDateString(implant.readings, "DailySurvey"),
        ownerName: implant.readings
          ?.filter((r: AnimalReading) => r.type === "Initial-OwnerName")
          .pop()?.stringValue,
      })),
    [groupAnimals, groupDetails.groupId]
  );

  const gridDetails = useMemo(() => {
    var ret = animalDetails;
    if (hideNotStarted) {
      ret = ret.filter((r) => !!r.surveyStartedDate);
    }
    if (hideCurrent) {
      ret = ret.filter((r) => {
        if (!r.surveyStartedDate) return true;
        const daysSinceDaily =
          Date.now() -
          new Date(r.lastDailyDate ?? r.surveyStartedDate).getTime() /
            (24 * 60 * 60 * 1000);
        return daysSinceDaily > 1;
      });
    }
    return ret;
  }, [hideNotStarted, hideCurrent, animalDetails]);

  return (
    <Paper sx={{ p: 2, height: "100%" }}>
      {loading ? (
        <LinearProgress />
      ) : (
        <>
          <Stack direction="row" spacing="1rem" mb="1rem">
            <Button
              onClick={async () => {
                await refresh();
                setReloadCount((p) => p + 1);
              }}
            >
              <Refresh /> Reload
            </Button>
            <Button onClick={() => setHideNotStarted((p) => !p)}>
              {hideNotStarted ? "Show not started" : "Hide not started"}
            </Button>
            <Button onClick={() => setHideCurrent((p) => !p)}>
              {hideCurrent ? "Show up to date" : "Hide up to date"}
            </Button>
          </Stack>
          <DataGrid
            key={`reload-${reloadCount}`}
            columns={surveyColumns}
            rows={gridDetails}
            initialState={{
              filter: {
                filterModel: {
                  items: [],
                  quickFilterLogicOperator: GridLinkOperator.Or,
                },
              },
            }}
          />
        </>
      )}
    </Paper>
  );
};
