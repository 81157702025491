import { gql, ApolloClient, useApolloClient } from "@apollo/client";
import { useMemo, useState } from "react";

const deleteReadingMutation = gql`
  mutation AdminDeleteReading(
    $animalId: ID!
    $datetime: AWSDateTime!
    $readingType: String!
  ) {
    adminRemoveAnimalReading(
      animalId: $animalId
      datetime: $datetime
      readingType: $readingType
    ) {
      animalId
      datetime
      type
    }
  }
`;

const deleteRecord = async (
  apollo: ApolloClient<any>,
  animalId: string,
  readingType: string,
  date: Date
) => {
  const datetime = date.toString();
  const result = await apollo.mutate({
    mutation: deleteReadingMutation,
    variables: {
      animalId,
      readingType,
      datetime,
    },
  });

  const ret = result.data?.adminDeleteAnimalRecord;
  return ret;
};

export const useDeleteAnimalReadings = (animalId: string, type: string) => {
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0.0);
  const [cancel, setCancel] = useState(false);
  const apollo = useApolloClient();

  const execute = useMemo(
    () =>
      async (
        datetimes: Date[],
        onDone: () => void,
        onError: (error: string) => void
      ) => {
        setLoading(true);
        setCancel(false);
        setProgress(0.0);

        try {
          for (let c = 0, len = datetimes.length; c < len; c++) {
            if (cancel) {
              break;
            }

            const d = datetimes[c];
            await deleteRecord(apollo, animalId, type, d);
            setProgress((c / datetimes.length) * 100);
          }
          setLoading(false);
          setProgress(100);
          onDone();
        } catch (e) {
          onError("Failed to delete reading");
        }
      },
    [animalId, type, cancel, apollo]
  );

  return { loading, execute, progress, setCancel };
};
