// import { Badge, IconButton } from "@mui/material";
// import NotificationsIcon from "@mui/icons-material/Notifications";

export function NotificationIcon() {
  return (
    <div>
      {/* <IconButton style={{ backgroundColor: "transparent" }}>
        <Badge badgeContent={4} color="warning">
          <NotificationsIcon sx={{ color: "white" }} />
        </Badge>
      </IconButton> */}
    </div>
  );
}
