import { gql, useMutation } from "@apollo/client";
import { useMemo } from "react";
import { adminListFirmwareQuery, FirmwareVersion } from "./use-admin-list-firmware";

export const useAdminUpdateFirmwareStatus = (onCompleted: (data: any) => void) => {
  const [execute, { data, ...mutationResult }] = useMutation(
    gql`
      mutation UpdateFirmwareStatus($status: String!, $version: String!) {
        adminUpdateFirmwareStatus(version: $version, status: $status) {
          version
          status
        }
      }
    `,
    {
      variables: {},
      onCompleted,
      refetchQueries: [{ query: gql(adminListFirmwareQuery), variables: {} }],
    }
  );
  const firmware: FirmwareVersion = useMemo(() => data ?? null, [data]);
  return { firmware, ...mutationResult, execute };
};
