import { useMemo } from "react";
import { useQuery } from "../../graphql/Query";

export interface RawReadingsItem {
  Key: string;
  LastModified: string;
  Size: number;
}

export const listRawReadingsQuery = `query ListRawReadings($maxKeys: Int = 10, $prefix: String ) {
    listRawReadings(maxKeys: $maxKeys, prefix: $prefix) {
      Key
      LastModified
      Size
    }
  }
  `;

export const useListRawReadings = (prefix: string, maxItems: number) => {
  const { data, loading } = useQuery(
    listRawReadingsQuery,
    {
      prefix,
      maxItems,
    },
    prefix !== ""
  );
  const rawReadingsList: RawReadingsItem[] = useMemo(
    () => data?.listRawReadings ?? [],
    [data]
  );

  return { loading, rawReadingsList };
};
