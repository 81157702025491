import { FC, useState } from "react";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Add, NotificationAdd } from "@mui/icons-material";
import { useAdminCreateHealthAlertSetting } from "../../hooks/health-alerts/use-admin-create-health-setting";
import { capitalizeFirst } from "../Utils";

export const speciesList: string[] = [
  "canine",
  "feline",
  "bovine",
  "equine",
  "porcine",
  "ovine",
  "all",
];

export const readingTypes: string[] = ["temperature", "heartRate", "spO2"];

export const CreateAlertSettingButton: FC<{
  existingAlerts: string[];
  onDone: Function;
}> = ({ existingAlerts, onDone }) => {
  const [readingType, setReadingType] = useState<string>("temperature");
  const [species, setSpecies] = useState<string>("canine");

  const [readingVariable, setReadingVariable] = useState<string>();
  const [lowTrigger, setLowTrigger] = useState<string>("");
  const [highTrigger, setHighTrigger] = useState<string>("");

  const [triggerType, setTriggerType] = useState<string>("bounds");

  const [errorMsg, setErrorMsg] = useState("");
  const [open, setOpen] = useState<boolean>(false);

  const handleSetSpecies = (event: SelectChangeEvent) => {
    setSpecies(event.target.value as string);
  };

  const handleSetReadingType = (event: SelectChangeEvent) => {
    const type = event.target.value as string;

    setReadingType(type);

    switch (type) {
      case "heartRate":
      case "spO2":
      case "temperature":
      case "batteryPercent":
        setReadingVariable("floatValue");
        break;
      case "location":
      case "softwareVersion":
        setReadingVariable("stringValue");
        break;
    }
  };

  const handleSubmit = () => {
    const highTrig = parseFloat(highTrigger ?? "");
    const lowTrig = parseFloat(lowTrigger ?? "");

    if (!!highTrig && !!lowTrig) {
      if (highTrig < lowTrig || lowTrig > highTrig) {
        setErrorMsg("Error: bad params. Check trigger values");
        return;
      }
    }
    if (existingAlerts.includes(`${species}-${readingType}`)) {
      setErrorMsg(
        `Alert: ${readingType} for` +
          ` species: ${species}` +
          " already exists.\nEdit the existing alert or delete it before creating a new one."
      );
    } else {
      console.log(
        "Reading params: " + `${species}-${readingType}`,
        readingType,
        species,
        readingVariable,
        parseFloat(lowTrigger),
        parseFloat(highTrigger)
      );
      execute();
    }
  };

  const { loading, execute } = useAdminCreateHealthAlertSetting(
    `${species}-${readingType}`,
    readingType,
    species,
    "floatValue",
    parseFloat(lowTrigger),
    parseFloat(highTrigger),
    () => {
      setOpen(false);
      onDone();
    },
    (error) => {
      setErrorMsg(`An error occured\n${error}`);
      console.log(error);
    }
  );

  return (
    <div>
      <Tooltip title="Create new alert setting">
        <Button
          variant="contained"
          onClick={() => {
            setOpen(!open);
          }}
          startIcon={<Add />}
        >
          Create Alert
        </Button>
      </Tooltip>
      <Dialog open={open} fullWidth={true}>
        <DialogTitle textAlign="center">
          Create Health Alert Setting
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>
            Create a new health alert setting. This will send push notifications
            to users if a reading matching the specified triggers is uploaded.
          </DialogContentText>
          <Stack
            justifyContent="stretch"
            sx={{ m: 2 }}
            height="100%"
            textAlign="center"
          >
            <div />
            <Stack direction="column" spacing={0.5} justifyItems="start">
              <Divider />
              <br />
              <InputLabel id="reading-type-label" sx={{ alignSelf: "start" }}>
                Species
              </InputLabel>
              <Select
                labelId="species-label"
                value={species}
                onChange={handleSetSpecies}
              >
                {speciesList.map((species) => (
                  <MenuItem value={species} key={species}>
                    <Typography textAlign="left">
                      {capitalizeFirst(species)}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
              <br />
              <InputLabel id="reading-type-label" sx={{ alignSelf: "start" }}>
                Reading Type
              </InputLabel>
              <Select
                labelId="reading-type-select"
                value={readingType}
                onChange={handleSetReadingType}
              >
                {readingTypes.map((reading) => (
                  <MenuItem value={reading} key={reading}>
                    <Typography textAlign="left">
                      {capitalizeFirst(reading)}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
              <br />
              <InputLabel id="reading-type-label" sx={{ alignSelf: "start" }}>
                Trigger Type
              </InputLabel>
              <Select labelId="trigger-type-label" value={triggerType}>
                <MenuItem value="bounds">
                  <Typography textAlign="left">Bounds</Typography>
                </MenuItem>
              </Select>
              <br />
              {triggerType === "bounds" && (
                <Stack direction="row" sx={{ p: 2 }}>
                  <Box sx={{ width: "50%" }}>
                    <InputLabel
                      id="reading-type-label"
                      sx={{ alignSelf: "start" }}
                    >
                      Low Trigger
                    </InputLabel>
                    <TextField
                      value={lowTrigger === null ? "" : lowTrigger}
                      onChange={(event) => setLowTrigger(event.target.value)}
                    />
                  </Box>
                  <Box sx={{ width: "50%" }}>
                    <InputLabel
                      id="reading-type-label"
                      sx={{ alignSelf: "start" }}
                    >
                      High Trigger
                    </InputLabel>
                    <TextField
                      value={highTrigger === null ? "" : highTrigger}
                      onChange={(event) => setHighTrigger(event.target.value)}
                    />
                  </Box>
                </Stack>
              )}
            </Stack>
          </Stack>
        </DialogContent>
        {errorMsg !== "" && (
          <Alert sx={{ m: 2 }} severity="error" onClose={() => setErrorMsg("")}>
            {errorMsg}
          </Alert>
        )}
        <DialogActions sx={{ justifyContent: "space-between", p: 2 }}>
          <Button
            variant="contained"
            disabled={loading}
            onClick={() => {
              setOpen(false);
            }}
            color="error"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={loading}
            onClick={() => {
              setErrorMsg("");
              handleSubmit();
            }}
            startIcon={
              !loading ? (
                <NotificationAdd />
              ) : (
                <CircularProgress size={20} color="inherit" />
              )
            }
          >
            {!loading ? "Create" : "Creating"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
