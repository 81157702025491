import React, { FC, useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowId,
} from "@mui/x-data-grid";
import {
  Alert,
  AppBar,
  Box,
  Button,
  Chip,
  Collapse,
  Dialog,
  IconButton,
  Paper,
  Skeleton,
  Stack,
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from "@mui/material";

import {
  BugReport,
  CheckCircle,
  Close,
  CloseSharp,
  DeveloperBoard,
  ErrorOutline,
  Lock,
  RadioButtonUnchecked,
  StackedBarChart,
} from "@mui/icons-material";

import { usePrevImplants } from "../../hooks/use-prev-implants";
import { formatDate, formatSexString } from "../Utils";
import { ImplantGroups } from "../Widgets/ImplantGroups";
import { RemoveAnimalButton } from "../Widgets/RemoveAnimalButton";
import { CopyText } from "../Common/CopyText";
import { ObjectLog } from "../Common/ObjectLogs";
import { useAdminListFirmware } from "../../hooks/firmware/use-admin-list-firmware";
import { useAdminGetImplantFirmware } from "../../hooks/firmware/use-admin-get-implant-firmware";
import { SetImplantFirmwareButton } from "../Firmware/SetImplantFirmwareButton";
import { RemoveTargetFirmwareButton } from "../Firmware/RemoveTargetFirmwareButton";
import { ImplantReadingHistory } from "./ImplantReadingHistory";
import { ImplantNotifications } from "./ImplantNotifications";
import { ImplantReadingGraphs } from "./ImplantReadingGraphs";
import { ImplantLocationHistory } from "./ImplantLocationHistory";
import { ImplantHwVersionIcon } from "./ImplantHwVersionIcon";
import { ImplantLogHistory } from "./ImplantLogHistory";
import { ImplantStatus } from "./ImplantStatus";
import { ImplantCustomTags } from "./ImplantCustomTags";
import { ImplantDiagnostics } from "./ImplantDiagnostics";
import { ImplantSurveyResponses } from "./ImplantSurveyResponses";

enum AnimalReadingType {
  heartRate,
  heartRateVariability,
  temperature,
  battery,
  respRate,
  notifications,
  linkedGroups,
  editLog,
  location,
  logMsg,
  diagnostics,
  survey,
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const firmwareColumns: GridColDef[] = [
  {
    field: "version",
    headerName: "Version",
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography variant="body2" sx={{ cursor: "pointer" }}>
          {params.value}
        </Typography>
      );
    },
  },

  {
    field: "hci",
    headerName: "HCI",
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography variant="body2" sx={{ cursor: "pointer" }}>
          {params.value}
        </Typography>
      );
    },
  },
  {
    field: "lmp",
    headerName: "LMP",
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography variant="body2" sx={{ cursor: "pointer" }}>
          {params.value}
        </Typography>
      );
    },
  },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      return params.value === "release" ? (
        <Chip
          icon={<CheckCircle />}
          label="Release"
          variant="outlined"
          color="success"
          sx={{ cursor: "pointer" }}
        />
      ) : params.value === "testing" ? (
        <Chip
          icon={<BugReport />}
          label="Testing"
          variant="outlined"
          color="info"
          sx={{ cursor: "pointer" }}
        />
      ) : params.value === "pending" ? (
        <Chip
          icon={<Lock />}
          label="Pending"
          variant="outlined"
          color="info"
          sx={{ cursor: "pointer" }}
        />
      ) : params.value === "deprecated" ? (
        <Chip
          icon={<ErrorOutline />}
          label="Deprecated"
          variant="outlined"
          color="warning"
          sx={{ cursor: "pointer" }}
        />
      ) : params.value != null ? (
        <Chip
          icon={<RadioButtonUnchecked />}
          label={params.value}
          variant="outlined"
          sx={{ cursor: "pointer" }}
        />
      ) : (
        <Typography></Typography>
      );
    },
  },
  {
    field: "created",
    headerName: "Created",
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography variant="body2">{formatDate(params.value)}</Typography>
      );
    },
  },
];

export interface Animal {
  animalId: string;
  hardwareId?: string;
  name?: string;
  sex?: string;
  species?: string;
  breed?: string;
  dob?: string;
  updated?: string;
  microchip?: string;
  implantHwVersion?: string;
  colour?: string;
}

export const ImplantDetails: FC<{ animal: Animal }> = ({ animal }) => {
  const { implantid } = useParams();
  const [value, setValue] = useState(0);
  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const { append } = usePrevImplants();
  const [graphOpen, setGraphOpen] = useState(false);

  const [showFirmwareSelection, setShowFirmwareSelection] = useState(false);
  const { versions, loading } = useAdminListFirmware();
  const { firmware } = useAdminGetImplantFirmware(implantid ?? "");
  const [newFirmwareVersion, setNewFirmwareVersion] = useState("");
  const [selectionModel, setSelectionModel] = useState<GridRowId[]>([]);
  const { firmware: current } =
    useAdminGetImplantFirmware("current-implant-v3");

  const handleFirmwareOpen = () => {
    setSelectionModel([firmware?.version]);
    setShowFirmwareSelection(true);
  };

  const handleFirmwareClose = () => {
    setShowFirmwareSelection(false);
  };

  const handleGraphOpen = () => {
    setGraphOpen(true);
  };

  const handleGraphClose = () => {
    setGraphOpen(false);
  };

  let navigate = useNavigate();

  useEffect(() => {
    if (implantid) {
      append(implantid, {
        animalId: implantid,
        name: animal.name,
        species: animal.species,
        updated: animal.updated,
        dob: animal.dob,
      });
    }
  }, [append, implantid, animal]);

  const handleTypeChange = useCallback((event: any, val: any) => {
    setValue(val);
  }, []);

  return (
    <Stack
      spacing={2}
      sx={{ px: 1, py: 2 }}
      height="100%"
      justifyContent="stretch"
    >
      <Stack direction="row" spacing={4}>
        <Paper
          sx={{
            flexBasis: "50%",
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Typography variant="h6" color="inherit" textAlign="left">
            Animal Details
          </Typography>
          <Stack alignItems="left" sx={{ p: 2 }} spacing={1}>
            <Box width="100%">
              <ImplantHwVersionIcon
                hwVersion={animal.implantHwVersion ?? "unknown"}
              />
            </Box>
            <Typography variant="body2" color="inherit" align="left">
              Name: {animal.name ? <strong>{animal.name}</strong> : <em>-</em>}
            </Typography>
            <Typography
              variant="body2"
              color="inherit"
              align="left"
              component="div"
            >
              Implant ID:{" "}
              <strong>
                <CopyText>{implantid}</CopyText>
              </strong>
            </Typography>
            <Typography
              variant="body2"
              color="inherit"
              align="left"
              component="div"
            >
              Hardware ID: <strong>{animal.hardwareId ?? ""}</strong>
            </Typography>
            <Stack direction="row" spacing={1}>
              <Typography variant="body2" color="inherit" align="left">
                Species:{" "}
                {animal.species ? (
                  <strong>{animal.species ?? "-"}</strong>
                ) : (
                  <em>-</em>
                )}
              </Typography>
              <Typography variant="body2" color="inherit" align="left">
                Breed:{" "}
                {animal.breed ? (
                  <strong>{animal.breed ?? "-"}</strong>
                ) : (
                  <em>-</em>
                )}
              </Typography>
              <Typography variant="body2" color="inherit" align="left">
                Sex:{" "}
                {animal.sex ? (
                  <strong>{`${formatSexString(animal.sex ?? "-")}`}</strong>
                ) : (
                  <em>-</em>
                )}
              </Typography>
            </Stack>
            <ImplantCustomTags animalId={animal.animalId} />
            <Stack
              direction="column"
              spacing={2}
              flexGrow={1}
              justifyContent="flex-end"
            >
              <Box marginLeft="auto" textAlign="right" sx={{ width: "300" }}>
                <RemoveAnimalButton
                  animalID={implantid!}
                  onError={(e) => {
                    setErrorMsg(e.message);
                    console.log(e);
                    setShowError(true);
                  }}
                  onDone={() => {
                    navigate("/implants/");
                  }}
                />
              </Box>
            </Stack>
            <Collapse in={showError}>
              <Alert
                color="error"
                icon={<ErrorOutline />}
                action={
                  <IconButton
                    aria-label="close"
                    color="error"
                    size="small"
                    onClick={() => {
                      setShowError(false);
                    }}
                  >
                    <Close fontSize="inherit" />
                  </IconButton>
                }
                sx={{ mb: 2 }}
              >
                {errorMsg ?? "Error! Could not delete animal."}
              </Alert>
            </Collapse>
          </Stack>
        </Paper>
        <Paper
          sx={{
            flexBasis: "50%",
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <ImplantStatus implantId={implantid!} />

          <Stack direction="row" spacing={2} justifyContent={"space-around"}>
            <Button
              variant="contained"
              color="success"
              fullWidth={false}
              startIcon={<DeveloperBoard />}
              onClick={() => {
                handleFirmwareOpen();
              }}
            >
              Select Firmware
            </Button>
            <Button
              variant="contained"
              color="info"
              fullWidth={false}
              startIcon={<StackedBarChart />}
              onClick={() => {
                handleGraphOpen();
              }}
            >
              Historical Graph
            </Button>
          </Stack>
        </Paper>
      </Stack>

      <Box sx={{ borderBottom: 1, borderColor: "divider" }} flex="0">
        <Tabs value={value} onChange={handleTypeChange} aria-label="">
          <Tab label="Heart Rate" {...a11yProps(0)} />
          <Tab label="HRV" {...a11yProps(0)} />
          <Tab label="Temperature" {...a11yProps(0)} />
          <Tab label="Battery %" {...a11yProps(0)} />
          <Tab label="Resp. Rate" {...a11yProps(0)} />
          <Tab label="Notifications" {...a11yProps(0)} />
          <Tab label="Linked Groups" {...a11yProps(0)} />
          <Tab label="Edit Log" {...a11yProps(0)} />
          <Tab label="Location" {...a11yProps(0)} />
          <Tab label="Log Messages" {...a11yProps(0)} />
          <Tab label="Diagnostics" {...a11yProps(0)} />
          <Tab label="Survey Data" {...a11yProps(0)} />
        </Tabs>
      </Box>

      {value === AnimalReadingType.editLog && !!implantid && (
        <Box flex={1} overflow="auto">
          <ObjectLog type="animal" id={implantid} />
        </Box>
      )}

      {implantid && value === AnimalReadingType.notifications && (
        <Box flex={1}>
          <ImplantNotifications implantId={implantid} />
        </Box>
      )}
      {value === AnimalReadingType.linkedGroups && (
        <Box flex={1}>
          <ImplantGroups animalId={animal.animalId} animalName={animal.name} />
        </Box>
      )}
      {value === AnimalReadingType.location && (
        <Box flex={1}>
          <ImplantLocationHistory animalId={animal.animalId} />
        </Box>
      )}
      {value === AnimalReadingType.logMsg && (
        <Box flex={1}>
          <ImplantLogHistory animalId={animal.animalId} />
        </Box>
      )}
      {value === AnimalReadingType.diagnostics && (
        <Box flex={1}>
          <ImplantDiagnostics animalId={animal.animalId} />
        </Box>
      )}
      {value === AnimalReadingType.survey && (
        <Box flex={1}>
          <ImplantSurveyResponses animalId={animal.animalId} />
        </Box>
      )}
      {implantid &&
        (value === AnimalReadingType.heartRate ||
          value === AnimalReadingType.temperature ||
          value === AnimalReadingType.battery ||
          value === AnimalReadingType.respRate ||
          value === AnimalReadingType.heartRateVariability) && (
          <Box flex={1}>
            <ImplantReadingHistory
              animalId={implantid}
              type={
                value === AnimalReadingType.heartRate
                  ? "heartRate"
                  : value === AnimalReadingType.temperature
                  ? "temperature"
                  : value === AnimalReadingType.battery
                  ? "batteryPercent"
                  : value === AnimalReadingType.respRate
                  ? "respiratoryRate"
                  : value === AnimalReadingType.heartRateVariability
                  ? "heartRateVariance"
                  : ""
              }
            />
          </Box>
        )}
      <Dialog
        open={showFirmwareSelection}
        onClose={handleFirmwareClose}
        fullWidth
        maxWidth={"xl"}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <Typography
              sx={{ ml: 2, flex: 1 }}
              variant="h6"
              component="div"
              textAlign="center"
            >
              Select target firmware version for {animal.name}
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => {
                handleFirmwareClose();
              }}
              aria-label="close"
            >
              <CloseSharp />
            </IconButton>
          </Toolbar>
        </AppBar>

        {loading ? (
          <Box width="100%" height="100%">
            <Skeleton variant="rectangular" height={300} />
          </Box>
        ) : (
          <Stack direction="row" alignItems="center" justifyContent="center">
            {!!versions?.length && (
              <Box flex={1}>
                <Box flex={1} height={500}>
                  <DataGrid
                    columns={firmwareColumns}
                    selectionModel={selectionModel}
                    hideFooterSelectedRowCount
                    // checkboxSelection
                    onRowClick={(params, event) => {
                      const selectedIDs = new Set([params.id]);
                      const selectedRowData = versions.filter((version) =>
                        selectedIDs.has(version.version)
                      );

                      console.log(selectedIDs);

                      if (selectedRowData.length > 0) {
                        setNewFirmwareVersion(selectedRowData[0].version);
                      } else {
                        setNewFirmwareVersion("");
                      }
                    }}
                    onSelectionModelChange={(selection) => {
                      if (selection.length > 1) {
                        const selectionSet = new Set(selectionModel);
                        const result = selection.filter(
                          (s) => !selectionSet.has(s)
                        );

                        setSelectionModel(result);
                      } else {
                        setSelectionModel(selection);
                      }
                    }}
                    rows={versions?.map((version, row) => ({
                      id: version.version,
                      key: version.version,
                      menu: version.version,
                      current:
                        version.version === current?.version
                          ? "current-implant-v3"
                          : null,
                      ...version,
                    }))}
                  />
                </Box>
                {newFirmwareVersion !== null && (
                  <div>
                    <br />
                    <Stack direction="row" justifyContent="center" spacing="25">
                      <Typography>Target version:</Typography>
                      <Typography fontWeight="bold" sx={{ pl: 2 }}>
                        {newFirmwareVersion}
                      </Typography>
                    </Stack>
                  </div>
                )}
                <Stack direction="row" spacing={2} justifyContent={"center"}>
                  <Box
                    maxWidth={"100%"}
                    sx={{ p: 2 }}
                    justifySelf={"center"}
                    textAlign={"center"}
                  >
                    <RemoveTargetFirmwareButton
                      animalId={animal.animalId}
                      onDone={() => {
                        setShowFirmwareSelection(false);
                      }}
                    />
                  </Box>

                  <Box
                    maxWidth={"100%"}
                    sx={{ p: 2 }}
                    justifySelf={"center"}
                    textAlign={"center"}
                  >
                    <SetImplantFirmwareButton
                      animalId={animal.animalId}
                      version={newFirmwareVersion}
                      disabled={newFirmwareVersion === ""}
                      onDone={() => {
                        setShowFirmwareSelection(false);
                      }}
                    />
                  </Box>
                </Stack>
              </Box>
            )}
            {versions?.length < 1 && (
              <Box height={200}>
                <Typography alignSelf={"center"} variant="h6">
                  No firmware versions available
                </Typography>
              </Box>
            )}
          </Stack>
        )}
      </Dialog>

      <Dialog open={graphOpen} fullScreen>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: "120%",
          }}
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleGraphClose}
                aria-label="close"
              >
                <CloseSharp />
              </IconButton>

              <Typography
                sx={{ ml: 2, flex: 1 }}
                variant="h6"
                component="div"
                textAlign="center"
              >
                Historical Readings for {animal.name}
              </Typography>
            </Toolbar>
          </AppBar>
          <ImplantReadingGraphs animal={animal} />
        </Paper>
      </Dialog>
    </Stack>
  );
};
