import {
  BrokenImage,
  CheckCircle,
  Pets,
  Science,
  Security,
  Settings,
} from "@mui/icons-material";
import { Chip } from "@mui/material";
import { FC } from "react";

export const UserRoleIcons: FC<{ pool?: string; roles?: string[] }> = ({
  pool,
  roles,
}) => (
  <>
    {roles?.includes("groupadmin") && (
      <Chip
        size="small"
        icon={<Security />}
        color="success"
        label="Owner"
        variant="outlined"
        sx={{ mr: 2 }}
      />
    )}
    {roles?.includes("vet") && (
      <Chip
        size="small"
        icon={<Pets />}
        color="primary"
        label="Vet"
        variant="outlined"
        sx={{ mr: 2 }}
      />
    )}
    {roles?.includes("researcher") && (
      <Chip
        size="small"
        icon={<Science />}
        color="warning"
        label="Researcher"
        variant="outlined"
        sx={{ mr: 2 }}
      />
    )}
    {roles?.includes("dev") && (
      <Chip
        size="small"
        icon={<Settings />}
        color="secondary"
        label="Dev"
        variant="outlined"
        sx={{ mr: 2 }}
      />
    )}
    {roles?.includes("surveyadmin") && (
      <Chip
        size="small"
        icon={<CheckCircle />}
        color="secondary"
        label="SurveyAdmin"
        variant="outlined"
        sx={{ mr: 2 }}
      />
    )}
    {pool === "professional" && roles?.includes("groupuser") === false && (
      <Chip
        size="small"
        icon={<BrokenImage />}
        color="warning"
        label="Broken"
        variant="outlined"
        sx={{ mr: 2 }}
      />
    )}
  </>
);
