import { useNavigate } from "react-router-dom";
import { Box, Button } from "@mui/material";

import VcLogo from "../../Icons/logo_wide_light_white.png";

export function VetChipLogo() {
  let navigate = useNavigate();

  return (
    <Button>
      <Box
        component="img"
        src={VcLogo}
        sx={{ height: 28 }}
        onClick={() => {
          navigate("/", { replace: false });
        }}
      />
    </Button>
  );
}
