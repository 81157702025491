import { Close, Settings, SettingsApplications } from "@mui/icons-material";
import {
  Alert,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogContentText,
  DialogTitle,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { Box } from "@mui/system";
import { FC, useState } from "react";
import { useAdminSetVetIntegration } from "../../hooks/orgs/use-admin-set-vet-integration";

export const UpdateVetIntegration: FC<{
  orgId: string;
  onDone: (data: any) => void;
}> = ({ orgId, onDone }) => {
  const [showField, setShowField] = useState(false);
  const [vetIntegrationSettings, setVetIntegrationSettings] = useState("");
  const [validSettings, setValidSettings] = useState(false);
  const [vetIntegration, setVetIntegration] =
    useState<string | undefined>("EzyVet");
  const { loading, execute } = useAdminSetVetIntegration(orgId, () => {
    setShowField(false);
  });

  const handleVetIntegration = (vetSoftware: string) => {
    if (vetSoftware === "none") {
      setVetIntegrationSettings("");
      setVetIntegration(undefined);
    } else {
      setVetIntegration(vetSoftware);
    }
  };

  return (
    <div>
      <Chip
        disabled={loading}
        color="info"
        icon={
          loading ? (
            <CircularProgress size={15} color="inherit" />
          ) : (
            <Settings color="inherit" />
          )
        }
        onClick={() => {
          setShowField(true);
        }}
        label={loading ? "Updating" : "Settings"}
      ></Chip>
      <Dialog
        onClose={() => {
          setShowField(false);
        }}
        open={showField}
      >
        <DialogTitle>
          <Stack direction="row" justifyContent={"space-around"}>
            <Box />
            Update Vet Integration Settings
            <IconButton
              onClick={() => {
                setShowField(false);
              }}
            >
              <Close sx={{ textAlign: "flex-end" }} />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContentText textAlign={"center"}>{orgId}</DialogContentText>
        <Stack sx={{ m: 2 }} spacing={2}>
          <InputLabel id="vet-software-select">Vet Software</InputLabel>
          <Select
            defaultValue={vetIntegration}
            labelId="vet-software-select"
            onChange={(event) => {
              handleVetIntegration(event.target.value);
            }}
          >
            <MenuItem value={"none"}>No Integration</MenuItem>
            <MenuItem value={"EzyVet"}>EzyVet</MenuItem>
          </Select>
          <Typography variant="body1">
            Paste vet practice software integration settings JSON below.
          </Typography>
          {vetIntegration !== undefined && (
            <TextField
              multiline
              minRows={5}
              onChange={(event) => {
                try {
                  JSON.parse(event.target.value);
                } catch (e) {
                  setValidSettings(false);
                  return;
                }
                setValidSettings(true);
                setVetIntegrationSettings(event.target.value);
              }}
              id="custom-status-input"
              label="Vet Integration Settings"
              type="text"
            />
          )}
          {vetIntegration !== undefined && validSettings !== true && (
            <Alert severity="warning">Settings must be valid JSON</Alert>
          )}
          <Stack direction="row" justifyContent={"space-around"}>
            <Button
              variant="contained"
              disabled={vetIntegration === undefined ? false : !validSettings}
              startIcon={
                loading ? (
                  <CircularProgress size={20} style={{ color: "white" }} />
                ) : (
                  <SettingsApplications />
                )
              }
              onClick={() => {
                execute({
                  variables: { vetIntegration, vetIntegrationSettings, orgId },
                });
              }}
            >
              {loading ? "Uploading" : "Save"}
            </Button>
          </Stack>
        </Stack>
      </Dialog>
    </div>
  );
};
