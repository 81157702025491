import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Chip,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { ClearAll, NavigateNext } from "@mui/icons-material";
import { usePreviousGroups } from "../../hooks/use-prev-groups";
import {
  DataGrid,
  GridColDef,
  GridLinkOperator,
  GridRenderCellParams,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { formatDate } from "../Utils";
import { useState } from "react";

const columns: GridColDef[] = [
  {
    field: "name",
    headerName: "Group Name",
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography variant="body2" sx={{ cursor: "pointer" }}>
          {params.value}
        </Typography>
      );
    },
  },
  {
    field: "org",
    headerName: "Organisation",
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Link href={`/orgs/${params.value?.orgId}`} underline="none">
          <Typography variant="body2" sx={{ cursor: "pointer" }}>
            {params.value?.name}
          </Typography>
        </Link>
      );
    },
  },
  {
    field: "animalCount",
    headerName: "Animals",
    flex: 0.5,
    align: "center",
    headerAlign: "center",
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography variant="body2" sx={{ cursor: "pointer" }}>
          {params.value}
        </Typography>
      );
    },
  },
  {
    field: "vetIntegration",
    headerName: "Vet Linked",
    flex: 0.5,
    align: "center",
    headerAlign: "center",
    renderCell: (params: GridRenderCellParams) => {
      if (!!params.value) {
        return (
          <Chip
            color="info"
            label="EzyVet"
            variant="filled"
            sx={{ cursor: "pointer" }}
          />
        );
      }
      return null;
    },
  },
  {
    field: "created",
    headerName: "Created",
    flex: 0.8,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography variant="body2" sx={{ cursor: "pointer" }}>
          {formatDate(params.value)}
        </Typography>
      );
    },
  },
];

export default function Groups() {
  const [searchId, setSearchId] = useState("");
  let navigate = useNavigate();

  const { prevGroups, clear } = usePreviousGroups();

  const handleSearchSubmit = async (search: string) => {
    if (search === null || search === "") {
      return;
    }
    navigate({ pathname: `/groups/${search}` }, { replace: false });
  };

  function QuickSearchToolbar() {
    return (
      <Stack
        direction="row"
        spacing={3}
        justifyContent="space-between"
        sx={{
          p: 0.5,
          pb: 0,
        }}
      >
        <GridToolbarQuickFilter
          quickFilterParser={(searchInput: string) =>
            searchInput
              .split(",")
              .map((value) => value.trim())
              .filter((value) => value !== "")
          }
        />

        <Button
          variant="contained"
          color="error"
          startIcon={<ClearAll />}
          onClick={() => {
            clear();
          }}
          sx={{ fontSize: "0.8rem" }}
        >
          Clear List
        </Button>
      </Stack>
    );
  }

  return (
    <Stack
      direction="column"
      justifyItems="stretch"
      alignItems="center"
      height="100%"
    >
      <Typography
        variant="body1"
        color="inherit"
        textAlign="center"
        sx={{ mt: 3 }}
      >
        Enter a group's ID to navigate the its details page.
      </Typography>
      <Box alignContent="center" sx={{ m: 2, width: "30rem" }}>
        <TextField
          fullWidth
          label="Group ID"
          id="searchbox"
          value={searchId}
          onKeyPress={(ev) => {
            if (ev.key === "Enter" && searchId) {
              handleSearchSubmit(searchId);
            }
          }}
          onChange={(event) => {
            setSearchId(event.target.value);
          }}
          onSubmit={() => {
            handleSearchSubmit(searchId);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="Group ID"
                  edge="end"
                  onClick={() => {
                    handleSearchSubmit(searchId);
                  }}
                >
                  <NavigateNext />
                </IconButton>
              </InputAdornment>
            ),
          }}
          variant="outlined"
        />
      </Box>
      <Typography variant="h5" color="inherit" textAlign="center">
        Prevously Viewed Groups
      </Typography>
      <Box flex={1} width="100%" mb={2}>
        <DataGrid
          columns={columns}
          rows={prevGroups.map((group, index) => ({
            id: `${group?.groupId}|${index}`,
            key: index,
            org: group?.organisation,
            vetIntegration: group?.organisation.vetIntegration,
            ...group,
          }))}
          initialState={{
            filter: {
              filterModel: {
                items: [],
                quickFilterLogicOperator: GridLinkOperator.Or,
              },
            },
          }}
          components={{ Toolbar: QuickSearchToolbar }}
          onRowClick={(params) => {
            navigate(
              { pathname: `/groups/${params.id.toString().split("|")[0]}` },
              { replace: false }
            );
          }}
        />
      </Box>
    </Stack>
  );
}
