import { Remove } from "@mui/icons-material";
import { Button, CircularProgress } from "@mui/material";
import { FC } from "react";
import { useAdminRemoveImplantFirmware } from "../../hooks/firmware/use-admin-remove-implant-firmware";

export const RemoveTargetFirmwareButton: FC<{
  animalId: string;
  onDone: (data: any) => void;
}> = ({ animalId, onDone }) => {
  const { loading, execute } = useAdminRemoveImplantFirmware(animalId, onDone);

  return (
    <Button
      disabled={loading}
      startIcon={
        loading ? <CircularProgress size={20} color="inherit" /> : <Remove />
      }
      variant="contained"
      color="warning"
      onClick={(e) => {
        e.stopPropagation();
        execute();
      }}
    >
      {loading ? "Upating" : "Remove Target"}
    </Button>
  );
};
