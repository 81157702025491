import { Search } from "@mui/icons-material";
import {
  Box,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridToolbar,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import React, { useState } from "react";
import { useListRawReadings } from "../../hooks/raw-readings/use-list-raw-readigns";
import { formatDate } from "../Utils";
import {
  DownloadAllRawReadingsButton,
  DownloadRawReadingButton,
} from "./RawReadingsDownloadButton";

const rawReadingColumns: GridColDef[] = [
  {
    field: "Key",
    headerName: "File",
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography variant="body2">{params.value.split("/")[1]}</Typography>
      );
    },
  },
  {
    field: "Size",
    headerName: "Size",
    flex: 0.3,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography variant="body2">{`${(params.value / 1024).toFixed(
          2
        )} KB`}</Typography>
      );
    },
  },
  {
    field: "LastModified",
    headerName: "Uploaded",
    flex: 0.5,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography variant="body2">{formatDate(params.value)}</Typography>
      );
    },
  },
  {
    field: "Download",
    headerName: "",
    flex: 0.2,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <DownloadRawReadingButton file={params.row.id} onDone={() => {}} />
      );
    },
  },
];

export default function RawReadingsSearch() {
  const [maxItems, setMaxItems] = useState<number>(30);
  const [prefix, setPrefix] = useState<string>("");
  const [search, setSearch] = useState<{ prefix: string; maxItems: number }>();
  const [showInfoPanel, setShowInfoPanel] = useState<boolean>(false);

  const { rawReadingsList, loading } = useListRawReadings(
    search?.prefix ?? "",
    search?.maxItems ?? 10
  );

  const handleSearchSubmit = () => {
    setSearch({ prefix, maxItems });
  };

  function RawReadingsToolbar() {
    return (
      <Stack
        direction="row"
        justifyContent={"space-between"}
        sx={{
          p: 0.5,
          pb: 0,
        }}
      >
        <GridToolbar />
        <GridToolbarContainer>
          <DownloadAllRawReadingsButton urls={rawReadingsList} />
        </GridToolbarContainer>
      </Stack>
    );
  }

  return (
    <Stack
      justifyContent="stretch"
      sx={{ m: 2 }}
      height="100%"
      textAlign="center"
    >
      <div />
      <Typography variant="h5" color="inherit" textAlign="center" sx={{ m: 2 }}>
        Search for Raw Readings Files
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        {`Search by readings based on date or date + animalId. e.g. "2023-06-16", "2023-06", or "2023-06-15-e43e-9a47-31ba-3bd0"`}
      </Typography>
      <Box alignContent="center">
        <TextField
          label="Date"
          id="searchbox"
          fullWidth
          onChange={(event) => {
            setPrefix((event.target as HTMLInputElement).value);
          }}
          onKeyDown={(event) => {
            if (event.code === "Enter") {
              handleSearchSubmit();
            }
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {loading ? (
                  <CircularProgress size={30} />
                ) : (
                  <IconButton
                    aria-label="Implant ID"
                    edge="end"
                    onClick={() => {
                      handleSearchSubmit();
                    }}
                  >
                    <Search />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
          sx={{ width: "40%" }}
          variant="outlined"
        />
      </Box>
      {rawReadingsList.length > 0 && (
        <DataGrid
          disableSelectionOnClick
          columns={rawReadingColumns}
          rows={rawReadingsList.map((reading) => ({
            id: reading.Key,
            ...reading,
          }))}
          components={{ Toolbar: RawReadingsToolbar }}
          sx={{ mt: 2 }}
        />
      )}
    </Stack>
  );
}
