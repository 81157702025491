import { Typography } from "@mui/material";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridLinkOperator,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import { FC, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useAdminListGatewayVims } from "../../hooks/gateway/use-admin-list-gateway-vims-query";
import { CopyText } from "../Common/CopyText";
import { QuickSearchToolbar } from "../Common/QuickSearchToolbarReadings";
import { formatDate } from "../Utils";

export const gatewayVimColumns: GridColDef[] = [
  {
    field: "animalName",
    headerName: "Name",
    type: "string",
    align: "left",
    headerAlign: "left",
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography variant="body2" sx={{ cursor: "pointer" }}>
          {params.value}
        </Typography>
      );
    },
  },
  {
    field: "animalId",
    headerName: "Animal ID",
    align: "left",
    headerAlign: "left",
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography
          variant="body2"
          sx={{ cursor: "pointer" }}
          component={"span"}
        >
          <CopyText>
            {params.value}
          </CopyText>
        </Typography>
      );
    },
  },
  {
    field: "updated",
    headerName: "Updated",
    type: "dateTime",
    align: "left",
    headerAlign: "left",
    flex: 0.6,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography variant="body2" sx={{ cursor: "pointer" }}>
          {formatDate(params.value)}
        </Typography>
      );
    },
  },
];

export interface GatewayVimsProps {
  gatewayId: string;
}

export const GatewayVims: FC<GatewayVimsProps> = ({
  gatewayId,
}) => {
  let navigate = useNavigate();
  const { vims, loading } = useAdminListGatewayVims(gatewayId);

  const rows = useMemo(
    () =>
      vims?.map((vim) => ({
        id: vim.updatedImplantId,
        key: vim.updatedImplantId,
        ...vim,
      })) ?? [],
    [vims],
  );

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <DataGrid
      columns={gatewayVimColumns}
      rows={rows}
      initialState={{
        filter: {
          filterModel: {
            items: [],
            quickFilterLogicOperator: GridLinkOperator.Or,
          },
        },
      }}
      onCellClick={(params: GridCellParams) => {
        navigate(
          { pathname: `/implant/${params.id.toString().split("Z-")[1]}` },
          { replace: false },
        );
      }}
      components={{ Toolbar: QuickSearchToolbar }}
    />
  );
};
