import { FC } from "react";
import { useParams } from "react-router-dom";
import { Grid, Skeleton, Stack, Typography } from "@mui/material";
import { SentimentDissatisfiedOutlined } from "@mui/icons-material";
import { ImplantDetails } from "./ImplantDetails";
import { useAnimalQuery } from "../../hooks/animal/use-animal-query";

export const Implant: FC = () => {
  const { implantid } = useParams();

  const { loading: loadingAnimal, data: animal } = useAnimalQuery(implantid);

  if (loadingAnimal) {
    return (
      <Grid container spacing={3} sx={{ p: 2 }}>
        <Grid item xs={12} md={6} lg={4}>
          <Skeleton
            variant="rectangular"
            sx={{ width: "100%", height: "100%" }}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Skeleton
            variant="rectangular"
            sx={{ width: "100%", height: "100%" }}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Skeleton
            variant="rectangular"
            sx={{ width: "100%", height: "100%" }}
          />
        </Grid>
      </Grid>
    );
  }
  if (!animal?.adminGetAnimal) {
    return (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        width="100%"
        marginTop="5rem"
      >
        <SentimentDissatisfiedOutlined
          color="error"
          sx={{ fontSize: "60pt", mr: 1 }}
        />
        <Typography variant="h3" color="error">
          Implant Not Found
        </Typography>
      </Stack>
    );
  }

  return <ImplantDetails animal={animal.adminGetAnimal} />;
};
