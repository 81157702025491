import { gql, useMutation } from "@apollo/client";
import { useMemo } from "react";
import { adminGetUserDetailsQuery } from "../user/use-user-details";
import {
  adminGetOrganisationQuery,
  OrgDetails,
} from "./use-admin-get-organisation";

export const useAdminAddOrgUser = (
  orgId: string,
  email: string,
  reinvite: boolean,
  onCompleted: (data: any) => void,
  onError: (data: any) => void,
  userId?: string,
  pool?: string
) => {
  const [execute, { data, ...mutationResult }] = useMutation(
    gql`
      mutation AddOrgUser($email: String!, $orgId: ID!) {
        addOrganisationUser(email: $email, orgId: $orgId, reinvite: false) {
          organisation {
            users {
              user {
                email
              }
            }
          }
        }
      }
    `,
    {
      variables: { orgId, email, reinvite },
      onCompleted,
      onError,
      refetchQueries: [
        { query: gql(adminGetOrganisationQuery), variables: { orgId } },
        {
          query: gql(adminGetUserDetailsQuery),
          variables: { username: userId, pool },
        },
      ],
    }
  );
  const orgDetails: OrgDetails = useMemo(() => data ?? null, [data]);
  return { orgDetails, ...mutationResult, execute };
};
