import { FC } from "react";

import { LogHistory } from "../Logs/LogHistory";
import { LogObjectType } from "../Logs/LogRecord";
import { useObjectLogs } from "../Logs/useLogs";

export interface ObjectLogProps {
  type: LogObjectType;
  id: string;
}

export const ObjectLog: FC<ObjectLogProps> = ({ type, id }) => {
  const { logs, loading } = useObjectLogs(`${type}-${id}`);
  return <LogHistory loading={loading} logs={logs} />;
};
