import { useMemo, useState } from "react";
import { useQuery } from "../../graphql/Query";
import { inflate } from "pako";

export interface AnimalDiagnosticData {
  implantId: string;
  deviceId: string;
  startTime: string;
  endTime: string;
  metrics: Record<string, any>;
  logs: string[];
  blob: string;
}

const unpackDiagnostic = async (
  data: string
): Promise<AnimalDiagnosticData | undefined> => {
  const compressed = Buffer.from(data, "base64");
  const raw = inflate(new Uint8Array(compressed), { to: "string" });
  const ret = JSON.parse(raw);
  return ret;
};

export const useAnimalDiagnostics = (animalId?: string) => {
  const [diagnostics, setDiagnostics] = useState<AnimalDiagnosticData[]>();
  const [parsing, setParsing] = useState(true);
  const [nextToken, setNextToken] = useState<any>();
  const { loading, data, loadMore, ...props } = useQuery(
    `query GetAnimalDiagnostics($animalId: String!, $nextToken: String) {
        adminGetAnimalDiagnostics(animalId: $animalId, nextToken: $nextToken) {
          nextToken
          items {
            animalId
            data
            startTime
            endTime
          }
        }
      }
      
    `,
    { animalId }
  );

  const more = useMemo(
    () => async () => {
      if (nextToken) {
        const response = await loadMore({
          variables: {
            nextToken: nextToken,
          },
        });
        const ret: AnimalDiagnosticData[] = [];
        for (const item of response.data?.adminGetAnimalDiagnostics?.items ??
          []) {
          const data = await unpackDiagnostic(item.data);
          if (data) {
            ret.push(data);
          } else {
            console.warn("Bad Diagnostic", item);
          }
        }
        setNextToken(response.data?.adminGetAnimalDiagnostics?.nextToken);
        setDiagnostics((old) => [...(old ?? []), ...ret]);
      } else {
        console.warn("No nextToken");
      }
    },
    [loadMore, nextToken]
  );

  useMemo(async () => {
    setParsing(true);
    const ret: AnimalDiagnosticData[] = [];
    for (const item of data?.adminGetAnimalDiagnostics?.items ?? []) {
      const data = await unpackDiagnostic(item.data);
      if (data) {
        ret.push(data);
      } else {
        console.warn("Bad Diagnostic", item);
      }
    }
    setNextToken(data?.adminGetAnimalDiagnostics?.nextToken);
    setDiagnostics(ret);
    setParsing(false);
  }, [data]);

  return { ...props, more, loading: loading || parsing, diagnostics };
};
