import { useEffect, useMemo, useState } from "react";

const loadDefault = (key: string) => {
  try {
    const rawValue = window.localStorage.getItem(key);
    if (rawValue != null) {
      return JSON.parse(rawValue);
    }
  } catch {
    //ignore
  }
};

export const usePreviousList = <T>(key: string, maxLen: number = 20) => {
  const [prevVals, setList] = useState<T[]>(loadDefault(key) ?? []);
  const append = useMemo(
    () => (value?: T) =>
      value != null &&
      setList((previous) => [
        value,
        ...previous.filter((v) => value !== v && JSON.stringify(value) !== JSON.stringify(v)).slice(0, maxLen),
      ]),
    [maxLen]
  );
  const clear = () => setList(() => []);
  const filter = (f: (v: T) => boolean) => setList((previous) => previous.filter(f));

  useEffect(() => {
    try {
      // console.log(prevVals);
      window.localStorage.setItem(key, JSON.stringify(prevVals));
    } catch {
      //ignore
    }
  }, [prevVals, key]);

  return { prevVals, append, clear, filter };
};
