import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { CopyText } from "../Common/CopyText";

import { FC, Fragment, useState } from "react";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Info } from "@mui/icons-material";

export const GroupSurveyAnimalDetails: FC<{ row: any }> = ({ row }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Stack direction="row" alignItems="center" spacing="0.5rem">
        <Info />
        <Typography
          variant="body2"
          width="100%"
          sx={{ cursor: "pointer", verticalAlign: "middle" }}
          onClick={() => setOpen(true)}
        >
          {row.name}
        </Typography>
      </Stack>

      <Dialog open={open} fullWidth={true}>
        <DialogTitle textAlign="center">
          {row.name} - Animal Details
        </DialogTitle>
        <DialogContent>
          <Grid2 container>
            <Grid2 xs={4}>ID</Grid2>
            <Grid2 xs={8}>
              <CopyText>{row.id ?? "-"}</CopyText>
            </Grid2>
            <Grid2 xs={4}>Species</Grid2>
            <Grid2 xs={8}>{row.species ?? "-"}</Grid2>
            <Grid2 xs={4}>Sex</Grid2>
            <Grid2 xs={8}>{row.sex ?? "-"}</Grid2>
            <Grid2 xs={4}>ImplantID</Grid2>
            <Grid2 xs={8}>
              <CopyText>{row.hardwareId ?? row.id ?? "-"}</CopyText>
            </Grid2>
            <Grid2 xs={4}>Owner Name</Grid2>
            <Grid2 xs={8}>{row.ownerName ?? "-"}</Grid2>
            {!!row.vetLinks?.length && (
              <>
                <Grid2 xs={12} justifyContent="center">
                  <Typography variant="h6" p="0.5rem" textAlign="center">
                    Vet Links
                  </Typography>
                </Grid2>
                {(row.vetLinks ?? []).map((v: any, idx: number) => (
                  <Fragment key={`vetlink-${idx}`}>
                    <Grid2 xs={4}>{v.orgName}</Grid2>
                    <Grid2 xs={8}>
                      <CopyText>{v.vetId}</CopyText>
                    </Grid2>
                  </Fragment>
                ))}
              </>
            )}
          </Grid2>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={(e) => {
              setOpen(false);
              e.stopPropagation();
            }}
            color="info"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
