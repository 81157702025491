import { Autocomplete, TextField } from "@mui/material";
import { uniqBy } from "lodash";
import { FC, useEffect, useState } from "react";
import { usePreviousGroups } from "../../hooks/use-prev-groups";
import { usePrevImplants } from "../../hooks/use-prev-implants";
import { usePrevOrgs } from "../../hooks/use-prev-orgs";
import { usePreviousUsers } from "../../hooks/use-prev-users";

export enum HistoryType {
  implant,
  user,
  group,
  organisation,
  tag,
}

interface HistoryOption {
  label: string;
  id: string;
}

export const TextFieldHistory: FC<{
  type: HistoryType;
  onSelect: (data: any) => void;
}> = ({ type, onSelect }) => {
  const [label, setLabel] = useState<string>();
  const [optionsList, setOptionsList] = useState<HistoryOption[]>();
  const [selectedId, setSelectedId] = useState<string>("");

  // const { prevTags } = usePreviousTags();
  const { prevImplants } = usePrevImplants();
  const { prevGroups } = usePreviousGroups();
  const { prevOrgs } = usePrevOrgs();
  const { prevVals } = usePreviousUsers();

  useEffect(() => {
    var list: HistoryOption[] = [];

    switch (type) {
      // case HistoryType.tag:
      //   list = prevTags.map((tag) => {
      //     return { id: tag.tagId, label: tag.tagId };
      //   });

      //   setLabel("Tag ID");
      //   break;
      case HistoryType.implant:
        list = prevImplants.map((implant) => {
          return {
            id: implant.animalId,
            label: implant.name ?? implant.animalId,
          };
        });
        setLabel("Implant");
        break;
      case HistoryType.group:
        list = prevGroups.map((group) => {
          return { id: group.groupId, label: group.name };
        });
        setLabel("Group");
        break;
      case HistoryType.organisation:
        list = prevOrgs.map((org) => {
          return { id: org.orgId, label: org.name };
        });
        setLabel("Organisation");
        break;
      case HistoryType.user:
        list = prevVals.map((user) => {
          return { id: user.email, label: user.email };
        });
        setLabel("Email");
        break;
    }

    setOptionsList(uniqBy(list, (option) => option.id));
  }, []);

  useEffect(() => {
    onSelect(selectedId);
  }, [selectedId]);

  return (
    <Autocomplete
      id="tag-auto-complete"
      freeSolo
      options={optionsList ?? []}
      onChange={(event, value) => {
        if (value === null) {
          return;
        }
        var val = value as HistoryOption;
        setSelectedId(val.id ?? "");
      }}
      renderInput={(params) => (
        <div>
          <TextField
            {...params}
            label={label}
            onChange={(event) => {
              setSelectedId(event.target.value ?? "");
            }}
          />
        </div>
      )}
    />
  );
};
