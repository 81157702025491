import { gql, useMutation } from "@apollo/client";
import { useMemo } from "react";
import { adminGetAnimalReadings } from "../animal/use-animal-current-readings";
import { adminGetFirmwareQuery } from "./use-admin-get-implant-firmware";
import { FirmwareVersion } from "./use-admin-list-firmware";

export const useAdminAddImplantFirmware = (
  animalId: string,
  version: string,
  onCompleted: (data: any) => void = () => {}
) => {
  const [execute, { data, ...mutationResult }] = useMutation(
    gql`
      mutation SetAnimalID($animalId: ID!, $version: String!) {
        adminAddImplantFirmware(animalId: $animalId, version: $version) {
          firmware {
            appcore
            created
            netcore
            lmp
            hci
            status
            version
          }
        }
      }
    `,
    {
      variables: { animalId, version },
      onCompleted,
      refetchQueries: [
        { query: gql(adminGetFirmwareQuery), variables: { animalId } },
        { query: gql(adminGetAnimalReadings), variables: { animalId } },
      ],
    }
  );
  const firmware: FirmwareVersion = useMemo(() => data ?? null, [data]);
  return { firmware, ...mutationResult, execute };
};
