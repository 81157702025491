import { DataGrid, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import React from "react";
import { UserDetails, useUserDetails } from "../../hooks/user/use-user-details";
import { orgColumns } from "../Organisations/Orgs";
import { AddOrgUserButton } from "../Widgets/AddOrgUserButton";

export const UserOrgs: React.FC<{
  user: UserDetails;
  userid: string;
  pool: string;
}> = ({ user, userid, pool }) => {
  let navigate = useNavigate();

  const { loading, userDetails, refresh } = useUserDetails(
    userid,
    pool,
    user.email
  );
  const userOrgs = userDetails.organisations.filter(
    (val) => val.organisation != null
  );

  function MemberToolbar() {
    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          p: 0.5,
          pb: 0,
        }}
      >
        <GridToolbarQuickFilter
          quickFilterParser={(searchInput: string) =>
            searchInput
              .split(",")
              .map((value) => value.trim())
              .filter((value) => value !== "")
          }
        />
        <AddOrgUserButton email={user.email} userId={userid} pool={pool} />
      </Stack>
    );
  }

  return userOrgs?.length > 0 ? (
    <DataGrid
      columns={orgColumns}
      rows={userOrgs?.map((org, row) => ({
        id: org.orgId,
        key: org.orgId,
        ...org,
        ...org.organisation,
      }))}
      onRowClick={(params) => {
        navigate({ pathname: `/orgs/${params.row.orgId}` }, { replace: false });
      }}
      components={{
        Toolbar: MemberToolbar,
      }}
    />
  ) : (
    <Typography textAlign="center" variant="body1" sx={{ m: 5 }}>
      This user is not part of any organisations.
    </Typography>
  );
};
