import { Delete, GroupAdd, PersonAdd } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { FC, useState } from "react";
import { useAdminDeleteHealthAlertSetting } from "../../hooks/health-alerts/use-admin-delete-health-alert";

export const DeleteAlertSettingButton: FC<{ settingName: string }> = ({
  settingName,
}) => {
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);

  const handleSubmit = () => {
    execute();
  };

  const { loading, execute } = useAdminDeleteHealthAlertSetting(
    settingName,
    () => {
      setOpen(false);
    },
    (error) => {
      setErrorMsg(`An error occured\n${error}`);
      console.log(error);
    }
  );

  return (
    <div>
      <Tooltip title="Delete alert setting">
        <MenuItem onClick={() => setOpen(!open)}>
          <ListItemIcon>
            <Delete fontSize="small" color="error" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Tooltip>
      <Dialog open={open} fullWidth={true}>
        <DialogTitle textAlign="center">Confirm Delete Alert</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>
            Are you sure you want to delete alert setting:{" "}
            {<b>{settingName}</b>}?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "space-between", p: 2 }}>
          <Button
            variant="outlined"
            disabled={loading}
            onClick={() => {
              setOpen(false);
            }}
            color="info"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            startIcon={
              !loading ? (
                <Delete />
              ) : (
                <CircularProgress size={20} color="inherit" />
              )
            }
            disabled={loading}
            onClick={() => handleSubmit()}
            color="error"
          >
            {!loading ? "Delete" : "Deleting"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
