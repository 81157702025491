import {
  Close,
  Email,
  ErrorOutline,
  Send,
  SentimentSatisfied,
} from "@mui/icons-material";
import {
  Alert,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useAdminInviteNewUser } from "../../hooks/use-admin-invite-new-user";

export const AddUser = () => {
  const [email, setEmail] = useState("");
  const [isVet, setIsVet] = useState(true);
  const [isResearcher, setIsResearcher] = useState(false);
  const [isDev, setIsDev] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    switch ((event.target as HTMLInputElement).value) {
      case "vet":
        setIsVet(true);
        setIsResearcher(false);
        break;
      case "researcher":
        setIsResearcher(true);
        setIsVet(false);
        break;
    }
  };

  const onCompleted = ({ data }: any) => {
    setSuccess(true);
  };

  const onError = (e: Error) => {
    setErrorMsg(e.message);
    console.log(e);
    setError(true);
  };
  const { loading, execute } = useAdminInviteNewUser(
    email,
    false,
    isVet,
    isDev,
    isResearcher,
    onCompleted,
    onError
  );

  const handleSetDev = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsDev(event.target.checked);
    // console.log("isDev:", !isDev);
  };

  return (
    <Stack spacing={2} sx={{ m: 2, textAlign: "center" }} height="100%">
      <Typography variant="h5" textAlign="center">
        Invite New User
      </Typography>
      <Divider />
      <Typography variant="body1" textAlign="center" sx={{ pr: 5 }}>
        Enter new user's email address:
      </Typography>
      <FormControl>
        <Stack direction="row" width="100%" justifyContent="center" spacing="2">
          <TextField
            id="email"
            label="Email"
            autoComplete="email"
            onChange={(event) => {
              setEmail(event.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Email />
                </InputAdornment>
              ),
            }}
            variant="outlined"
            sx={{ minWidth: "40%", textAlign: "center", mr: 2 }}
          />
          <Button
            disabled={
              loading ||
              email == null ||
              /^[\w-.+']+@([\w-]+\.)+[\w-]{2,4}$/.exec(email) == null
            }
            variant="contained"
            startIcon={
              loading ? (
                <CircularProgress size={30} sx={{ color: "white" }} />
              ) : (
                <Send />
              )
            }
            onClick={() => {
              setError(false);
              execute();
            }}
          >
            Invite
          </Button>
        </Stack>
        <br />
        {/* <FormLabel>Groups</FormLabel> */}
        <RadioGroup
          row
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="vet"
          name="radio-buttons-group"
          sx={{ alignSelf: "center" }}
          onChange={handleChange}
        >
          <FormControlLabel value="vet" control={<Radio />} label="Vet" />
          <FormControlLabel
            value="researcher"
            control={<Radio />}
            label="Researcher"
          />
          <FormGroup>
            <FormControlLabel
              checked={isDev}
              control={<Checkbox defaultChecked onChange={handleSetDev} />}
              label="Developer"
            />
          </FormGroup>
        </RadioGroup>
      </FormControl>
      <Collapse in={error} sx={{ alignSelf: "center" }}>
        <Alert
          color="error"
          icon={<ErrorOutline />}
          action={
            <IconButton
              aria-label="close"
              color="error"
              size="small"
              onClick={() => {
                setError(false);
              }}
            >
              <Close fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {errorMsg ?? "Error! Could not invite user."}
        </Alert>
      </Collapse>
      <Collapse in={success} sx={{ alignSelf: "center" }}>
        <Alert
          color="success"
          icon={<SentimentSatisfied />}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setSuccess(false);
              }}
            >
              <Close fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {`User ${email} succesfully invited`}
        </Alert>
      </Collapse>
    </Stack>
  );
};
