import {
  Box,
  BoxProps,
  Chip,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { FC } from "react";
import { useUserAnalytics } from "./useUserAnalytics";

export interface UserAnalyticsProps {
  username: string;
}

const LogCell: FC<{ grow?: boolean; width?: number } & BoxProps> = ({
  grow = false,
  width = 12,
  children,
  ...props
}) => (
  <Box
    flex={`${grow ? 1 : 0} 0 ${width}rem`}
    borderTop="1px solid rgba(0, 0, 0, 0.12)"
    overflow={grow ? "auto" : "hidden"}
    textOverflow="ellipsis"
    paddingY={0.5}
    paddingX={1}
    {...props}
  >
    {children}
  </Box>
);

const crashlyticsStyle = {
  backgroundColor: "rgba(255,80,5,0.2)",
};

export const UserAnalytics: FC<UserAnalyticsProps> = ({ username }) => {
  const { logs, loading } = useUserAnalytics(username);

  // console.debug("LogItems", logs?.length);

  if (loading) {
    return (
      <Stack direction="column" spacing={0.5} justifyContent="start">
        {[1, 2, 3, 4, 5].map((r) => (
          <Stack direction="row" spacing={2} justifyContent="stretch">
            <Skeleton
              variant="text"
              sx={{ flex: "0 0 12rem", fontSize: "1.5rem" }}
            />
            <Skeleton variant="text" sx={{ flex: 1, fontSize: "1.5rem" }} />
          </Stack>
        ))}
      </Stack>
    );
  }

  if (!logs?.length) {
    return (
      <Typography textAlign="center" variant="body1" sx={{ m: 5 }}>
        No Logs found.
      </Typography>
    );
  }
  return (
    <Box
      flex="1 0"
      width="1600px"
      sx={{ overflowX: "hidden", overflowY: "auto" }}
    >
      {logs.map((l) => (
        <Box
          display="flex"
          justifyContent="stretch"
          sx={l.source === "CRASHLYTICS" ? crashlyticsStyle : {}}
        >
          <LogCell width={12}>
            <Typography fontSize={14}>
              {l.timestamp ? (
                new Date(l.timestamp).toLocaleString()
              ) : (
                <em>Unknown Dat</em>
              )}
            </Typography>
            <Typography fontSize={10}>{l.timestamp}</Typography>
          </LogCell>
          <LogCell grow>
            {l.type === "app_exception" ? (
              <Typography fontSize={16} fontStyle="italic">
                {l.message}
              </Typography>
            ) : (
              <Typography fontSize={16}>{l.message}</Typography>
            )}
            <Stack
              direction="row"
              spacing={2}
              justifyContent="start"
              sx={{ mt: 1 }}
            >
              <Chip
                color={l.platform === "ANDROID" ? "info" : "success"}
                label={l.platform}
                size="small"
              />
              <Chip color="default" label={l.device} size="small" />
              <Chip color="default" label={l.app_version} size="small" />
              <Chip color="default" label={l.type} size="small" />
              <Chip color="default" label={l.source} size="small" />
            </Stack>
          </LogCell>
        </Box>
      ))}
    </Box>
  );
};
