import { FC, PropsWithChildren, useState } from "react";
import {
  BrowserRouter,
  useNavigate,
  useLocation,
  Routes,
  Route,
} from "react-router-dom";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  HttpLink,
  split,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { getMainDefinition } from "@apollo/client/utilities";
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";
import { Auth } from "aws-amplify";
import { Authenticator, useTheme, View } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import {
  Drawer,
  Toolbar,
  AppBar,
  ListItem,
  ListItemText,
  IconButton,
  Box,
  CssBaseline,
  ListItemIcon,
  GlobalStyles,
  Typography,
  styled,
  ThemeProvider,
  Chip,
} from "@mui/material";
import {
  HomeOutlined,
  MemoryOutlined,
  PersonOutline,
  GroupOutlined,
  ApartmentOutlined,
  Menu,
  Wysiwyg,
  BubbleChart,
  NotificationImportant,
  Router,
} from "@mui/icons-material";

import { AccountMenu } from "./vetchip/Common/AccountMenu";
import { AppBody } from "./vetchip/Common/Body";
import { VetChipLogo } from "./vetchip/Common/VetChipLogo";
import { CopyrightFooter } from "./vetchip/Common/CopyrightFooter";
import { NotificationIcon } from "./vetchip/Common/NotificationIcon";
import { useStorage } from "./hooks/use-storage";

import { darkTheme, lightTheme } from "./theme";

import VcLogo from "./Icons/logo_wide_light_white.png";

import "roboto-fontface";

interface RouteDesc {
  name: string;
  icon: any;
  route: string;
}

const drawerData = [
  { name: "Home", icon: <HomeOutlined />, route: "/" },
  { name: "Organisations", icon: <ApartmentOutlined />, route: "/orgs" },
  { name: "Groups", icon: <GroupOutlined />, route: "/groups" },
  { name: "Users", icon: <PersonOutline />, route: "/users" },
  { name: "Implants", icon: <MemoryOutlined />, route: "/implants" },
  { name: "Gateways", icon: <Router/>, route: "/gateways"},
  { name: "Firmware", icon: <Wysiwyg />, route: "/firmware" },
  {
    name: "Alert Settings",
    icon: <NotificationImportant />,
    route: "/health-alert-settings",
  },

  {
    name: "Raw Readings",
    icon: <BubbleChart />,
    route: "/raw-readings-search",
  },
];

const DrawerRow: FC<RouteDesc> = ({ name, icon, route }) => {
  let navigate = useNavigate();
  let current = useLocation();

  return (
    <ListItem
      button
      onClick={() => {
        navigate(route, { replace: false });
      }}
      sx={{ marginTop: "1rem" }}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText
        primary={
          current.pathname === route ? <b>{name}</b> : <span>{name}</span>
        }
      />
    </ListItem>
  );
};

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  height: "100vh",
  transition: theme.transitions.create("padding", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  paddingLeft: `calc(${drawerWidth}px + 1rem)`,
  ...(!open && {
    transition: theme.transitions.create("padding", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    paddingLeft: 0,
  }),
  paddingTop: "var(--header-height)",
  paddingBottom: "var(--footer-height)",
  paddingRight: "1rem",
}));

const AppTitle: FC<{ title: string }> = ({ title }) => (
  <Typography variant="h4" color="inherit" justifyContent="center" ml={1}>
    | {title}
  </Typography>
);

export const AppApollo: FC<{ uri: string }> = ({ uri }) => {
  const httpLink = createHttpLink({
    uri,
  });

  const authLink = setContext(async (_, { headers }) => {
    // get the authentication token from local storage if it exists
    const session = await Auth.currentSession();
    const token = session.getAccessToken();
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token.getJwtToken()}` : "",
      },
    };
  });

  const subscriptionLink = createSubscriptionHandshakeLink(
    {
      url: uri,
      auth: {
        type: "AMAZON_COGNITO_USER_POOLS",
        jwtToken: async () => {
          const session = await Auth.currentSession();
          const token = session.getAccessToken();
          return token.getJwtToken();
        },
      },
      region: "ap-southeast-2",
    },
    new HttpLink({ uri })
  );

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      // console.debug("graphql", definition, (
      //   definition.kind === 'OperationDefinition' &&
      //   definition.operation === 'subscription'
      // ));
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    subscriptionLink,
    authLink.concat(httpLink)
  );

  const client = new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache({}),
  });

  return (
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  );
};

function App() {
  const [isLightMode, setIsDarkMode] = useStorage("theme", false);
  const [drawerOpen, setDrawerOpen] = useState(true);

  return (
    <BrowserRouter>
      <ThemeProvider theme={isLightMode ? darkTheme : lightTheme}>
        <CssBaseline />

        <AppBar
          position="fixed"
          sx={{
            top: 0,
            left: 0,
            right: 0,
            zIndex: (theme) => theme.zIndex.drawer + 100,
          }}
        >
          <Toolbar variant="regular">
            <Box display="flex" flexGrow={1} alignItems="center">
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={() => setDrawerOpen(!drawerOpen)}
              >
                <Menu />
              </IconButton>
              <VetChipLogo />
              {window.location.href.includes("dev") && (
                <Chip
                  variant="filled"
                  color="success"
                  label={<Typography variant="h5">Sandbox</Typography>}
                />
              )}
              {window.location.href.includes("localhost") && (
                <Chip
                  variant="filled"
                  color="warning"
                  label={<Typography variant="h5">localhost</Typography>}
                />
              )}
              <Routes>
                <Route path="/" element={null} />
                <Route
                  path="/settings"
                  element={<AppTitle title="Settings" />}
                />
                <Route path="/users" element={<AppTitle title="Users" />} />
                <Route
                  path="/users/:pool/:userid/:email"
                  element={<AppTitle title="User Details" />}
                />
                <Route
                  path="/add-user"
                  element={<AppTitle title="Add New User" />}
                />
                <Route path="/profile" element={<AppTitle title="Profile" />} />
                <Route path="/groups" element={<AppTitle title="Groups" />} />
                <Route
                  path="/groups/:groupid"
                  element={<AppTitle title="Group Details" />}
                />
                <Route
                  path="/orgs"
                  element={<AppTitle title="Organisations" />}
                />
                <Route
                  path="/orgs/:orgId"
                  element={<AppTitle title="Organisation Details" />}
                />
                <Route
                  path="/implants"
                  element={<AppTitle title="Implants" />}
                />
                <Route
                  path="/implant/:implantid"
                  element={<AppTitle title="Implant Details" />}
                />
                <Route 
                  path="/gateways"
                  element={<AppTitle title="Gateways" />}
                />
                <Route 
                  path="/gateway/:gatewayid"
                  element={<AppTitle title="Gateway Details" />}
                />
                <Route
                  path="/health-alert-settings"
                  element={<AppTitle title="Alert Settings" />}
                />
                <Route
                  path="/firmware/"
                  element={<AppTitle title="Firmware Versions" />}
                />
                <Route
                  path="/add-firmware/"
                  element={<AppTitle title="Add Firmware Version" />}
                />
                <Route
                  path="/raw-readings-search"
                  element={<AppTitle title="Raw Readings" />}
                />
                <Route
                  path="*"
                  element={<AppTitle title="404 Page Not Found" />}
                />
              </Routes>
              <Box display="flex" flexGrow={2}></Box>
            </Box>
            <NotificationIcon />
            <AccountMenu setIsLightMode={setIsDarkMode} />
          </Toolbar>
        </AppBar>

        <Main
          open={drawerOpen}
          sx={{
            height: "100vh",
            pt: "var(--header-height)",
          }}
        >
          <Drawer
            variant="persistent"
            open={drawerOpen}
            anchor="left"
            sx={{
              flexShrink: 0,
            }}
            onClose={() => setDrawerOpen(false)}
          >
            <Box height="var(--header-height)" />
            <Box width={drawerWidth}>
              {drawerData.map((item, index) => (
                <DrawerRow key={index} {...item} />
              ))}
            </Box>
          </Drawer>
          <AppBody />
        </Main>
        <Box
          position="fixed"
          bottom={0}
          left={0}
          right={0}
          padding="1rem"
          zIndex={(theme) => theme.zIndex.drawer}
        >
          <CopyrightFooter isLightMode={isLightMode} />
        </Box>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export const AppAuthWrapper: FC<PropsWithChildren> = ({ children }) => (
  <Authenticator
    hideSignUp={true}
    components={{
      Header() {
        const { tokens } = useTheme();
        return (
          <View
            textAlign="center"
            justify="center"
            padding={tokens.space.large}
          >
            <GlobalStyles
              styles={{
                "[data-amplify-authenticator]": {
                  " --amplify-colors-brand-primary-100": "#1070b6",
                  "--amplify-components-button-primary-background-color":
                    "#1070b6",
                  "--amplify-components-button-primary-hover-background-color":
                    "#4c9fdb",
                },
                body: {
                  backgroundColor: "#323232",
                },
              }}
            />

            <Box
              height={100}
              sx={{
                backgroundImage: `url(${VcLogo})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
                backgroundPosition: "center center",
              }}
            />
            <Typography variant="h5" color="white">
              Administration Management System
            </Typography>
          </View>
        );
      },
    }}
  >
    {children}
  </Authenticator>
);

export default AppAuthWrapper;
