import { gql, useMutation } from "@apollo/client";
import { UserAnimalsAccess } from "../user/use-user-details";
import { useGroupAnimalsQuery } from "./use-group-animals";

export const useRemoveGroupAnimal = (
  groupId: string,
  animalId: string,
  onDone: (data: any) => void = () => {}
) => {
  const [execute, { loading, data, ...mutationResult }] = useMutation(
    gql`
      mutation RemoveGroupAnimal($animalId: ID!, $groupId: ID!) {
        removeGroupAnimal(animalId: $animalId, groupId: $groupId) {
          access
        }
      }
    `,
    {
      variables: { groupId, animalId },
      onCompleted: onDone,

      refetchQueries: [
        { query: gql(useGroupAnimalsQuery), variables: { groupId } },
      ],
    }
  );
  const access: UserAnimalsAccess = data?.removeGroupAnimal;
  return { access, execute, loading, ...mutationResult };
};
