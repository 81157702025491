import { DomainAdd, PersonAdd } from "@mui/icons-material";
import {
  Alert,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  TextField,
  Tooltip,
} from "@mui/material";
import { FC, useState } from "react";
import { useAdminAddOrgUser } from "../../hooks/orgs/use-admin-add-org-user";
import { HistoryType, TextFieldHistory } from "./TextFieldHistory";

export const AddOrgUserButton: FC<{
  orgId?: string;
  orgName?: string;
  email?: string;
  userId?: string;
  pool?: string;
}> = ({ orgId, orgName, email, userId, pool }) => {
  const [errorMsg, setErrorMsg] = useState("");
  const [open, setOpen] = useState(false);
  const [emailInput, setEmailInput] = useState<string>(email ?? "");
  const [orgIdInput, setOrgIdInput] = useState<string>(orgId ?? "");
  const [reinvite, setReinvite] = useState<boolean>(false);

  const { loading, execute } = useAdminAddOrgUser(
    orgId ?? orgIdInput,
    email ?? emailInput,
    reinvite,
    () => setOpen(false),
    (error) => {
      setErrorMsg(error?.message ?? "An error occured.");
    },
    userId,
    pool
  );

  return (
    <div>
      <Tooltip
        title={
          orgId
            ? "Add user to this organisation."
            : "Link this user to an organisation."
        }
      >
        <Button
          variant="contained"
          onClick={() => {
            setOpen(!open);
          }}
          startIcon={orgId ? <PersonAdd /> : <DomainAdd />}
        >
          {email ? "Link Org" : "Add User"}
        </Button>
      </Tooltip>
      <Dialog open={open} fullWidth>
        <DialogTitle textAlign="center">Add User to Organisation</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>
            {orgId
              ? `Add user to ${orgName ?? orgId}.`
              : `Add ${email ?? "this user"} to an organisation.`}
          </DialogContentText>
          {/* <TextField
            autoFocus
            id="paramId"
            label={orgId ? "Email address" : "Org ID"}
            onChange={(event) => {
              orgId
                ? setEmailInput(event.target.value)
                : setOrgIdInput(event.target.value);
            }}
            fullWidth
          /> */}
          <TextFieldHistory
            type={orgId ? HistoryType.user : HistoryType.organisation}
            onSelect={(val) => {
              orgId ? setEmailInput(val) : setOrgIdInput(val);
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                onChange={(event) => setReinvite(event.target.checked)}
              />
            }
            label="Reinvite"
          />
        </DialogContent>
        {errorMsg !== "" && (
          <Alert
            sx={{ m: 2 }}
            severity="error"
            onClose={() => {
              setErrorMsg("");
            }}
          >
            {errorMsg}
          </Alert>
        )}
        <DialogActions sx={{ justifyContent: "space-between", p: 2 }}>
          <Button
            variant="contained"
            disabled={loading}
            onClick={() => {
              setOpen(false);
            }}
            color="error"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={
              loading ||
              (orgIdInput !== "" ? emailInput === "" : orgIdInput === "")
            }
            onClick={() => {
              execute();
            }}
            color="info"
          >
            {!loading ? "OK" : <CircularProgress size={20} color="info" />}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
