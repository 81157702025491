import { useQuery } from "../../graphql/Query";
import { Gateway } from "../../vetchip/Gateways/GatewayDetails";




export const adminGetGatewayDetailsQuery = `
query GetGatewayDetails($gatewayId: ID!) {
  getGatewayDetails(gatewayId: $gatewayId) {
    created
    gatewayId
    hwVersion
    name
    status
    updated
    lastOnline
    rebooted
  }
}
`;

export const useAdminGetGatewayDetails = (gatewayId: string) => {
  const {data, ...query} = useQuery(adminGetGatewayDetailsQuery, {
    gatewayId: gatewayId,
  });

  return {
    ...query,
    gatewayDetails: (data?.getGatewayDetails?.[0] as Gateway)
  }
}
