import { useMemo } from "react";
import { useQuery } from "../../graphql/Query";
import { FirmwareVersion } from "./use-admin-list-firmware";

export const adminGetFirmwareQuery = `query GetFirmware($animalId: ID!) {
  adminGetImplantFirmware(animalId: $animalId) {
    version
    firmware {
      appcore
      created
      hci
      lmp
      netcore
      status
      version
    }
  }
}

    `;
export const useAdminGetImplantFirmware = (animalId: string) => {
  const { data, ...query } = useQuery(
    adminGetFirmwareQuery,
    { animalId },
    animalId !== ""
  );
  const firmware: FirmwareVersion = useMemo(
    () => data?.adminGetImplantFirmware?.firmware ?? null,
    [data]
  );
  return { firmware, ...query };
};
