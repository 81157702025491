import { useMemo } from "react";
import { useQuery } from "../../graphql/Query";
import { LogRecord } from "./LogRecord";

const getUserLogsQuery = `
query getUserEditLogs($userIdString: String!) {
  getUserEditLogs(userIdString: $userIdString) {
    items {
      user {
        userpool
        username
        email
      }
      action
      object
      objectId
      log
      timestamp
    }
  }
}
`;

const getObjectLogsQuery = `
query getObjectEditLogs($objectIdString: String!) {
  getObjectEditLogs(objectIdString: $objectIdString) {
    items {
      user {
        userpool
        username
        email
      }
      action
      object
      objectId
      log
      timestamp
    }
  }
}
`;

const parseLog = (log: string) => {
  try {
    return JSON.parse(log);
  } catch (e) {
    return null;
  }
};

export const useUserLogs = (userIdString: string) => {
  const { data, loading, refresh, loadMore } = useQuery(getUserLogsQuery, {
    userIdString,
  });
  const logs: LogRecord[] = useMemo(() => {
    return (
      data?.getUserEditLogs?.items?.map(
        ({ user, action, object, objectId, log, timestamp }: any) => ({
          user,
          action,
          object,
          objectId,
          timestamp,
          log: parseLog(log),
        })
      ) ?? []
    );
  }, [data]);
  return {
    logs,
    loading,
    refresh,
    loadMore,
  };
};

export const useObjectLogs = (objectIdString: string) => {
  const { data, loading, refresh, loadMore } = useQuery(getObjectLogsQuery, {
    objectIdString,
  });
  const logs: LogRecord[] = useMemo(() => {
    return (
      data?.getObjectEditLogs?.items?.map(
        ({ user, action, object, objectId, log, timestamp }: any) => ({
          user,
          action,
          object,
          objectId,
          timestamp,
          log: parseLog(log),
        })
      ) ?? []
    );
  }, [data]);
  return {
    logs,
    loading,
    refresh,
    loadMore,
  };
};
