import { useEffect, useState } from "react";

export type UseStorageType<T> = [T, React.Dispatch<React.SetStateAction<T>>];

const loadDefault = (key: string) => {
  try {
    const rawValue = window.localStorage.getItem(key);
    if (rawValue != null) {
      return JSON.parse(rawValue);
    }
  } catch {
    //ignore
  }
};

export const useStorage = <T>(
  key: string,
  defaultValue: T
): UseStorageType<T> => {
  const [value, setValue] = useState<T>(loadDefault(key) ?? defaultValue);

  useEffect(() => {
    try {
      if (value === undefined) {
        window.localStorage.removeItem(key);
      } else {
        window.localStorage.setItem(key, JSON.stringify(value));
      }
    } catch {
      //ignore
    }
  }, [value, key]);

  return [value, setValue];
};
