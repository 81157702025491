export const decodeInt = (
  data: Uint8Array,
  offset: number,
  length: number
): number => {
  let result = 0;
  for (let i = 0; i < length; i++) {
    result |= data[offset + i] << (8 * i);
  }
  return result >>> 0;
};

export class McuImageHeader {
  loadAddress: number;
  headerSize: number;
  imageSize: number;
  flags: number;
  version: McuImageVersion;

  constructor(
    loadAddress: number,
    headerSize: number,
    imageSize: number,
    flags: number,
    version: McuImageVersion
  ) {
    this.loadAddress = loadAddress;
    this.headerSize = headerSize;
    this.imageSize = imageSize;
    this.flags = flags;
    this.version = version;
  }

  static decode(data: Uint8Array) {
    const magic = decodeInt(data, 0, 4);

    if (magic !== 0x96f3b83d) {
      throw new Error(`incorrect magic ` + magic + " expected: " + 0x96f3b83d);
    }

    return new McuImageHeader(
      decodeInt(data, 4, 4),
      decodeInt(data, 8, 2),
      decodeInt(data, 12, 4),
      decodeInt(data, 16, 4),
      McuImageVersion.decode(data.subarray(20, 28))
    );
  }
}

export class McuImageTLV {
  entries: Array<McuImageTLVEntry>;

  constructor(entries: Array<McuImageTLVEntry>) {
    this.entries = entries;
  }

  static decode(data: Uint8Array, offset: number) {
    const magic = decodeInt(data, offset, 2);
    if (magic !== 0x6907) {
      throw new Error("incorrect tlv magic");
    }

    const length = decodeInt(data, offset + 2, 2);
    const end = offset + length;
    offset += 4;

    const entries = new Array<McuImageTLVEntry>();
    while (offset < end) {
      var entry = McuImageTLVEntry.decode(data, offset, end);
      entries.push(entry);
      offset += entry.length + 4;
    }

    return new McuImageTLV(entries);
  }

  toString() {
    return "McuTlv{entries: " + this.entries + "}";
  }
}

export class McuImageTLVEntry {
  type: number;
  length: number;
  value: Uint8Array;

  constructor(type: number, length: number, value: Uint8Array) {
    this.length = length;
    this.type = type;
    this.value = value;
  }

  static decode(data: Uint8Array, start: number, end: number) {
    if (start + 4 > end) {
      throw new Error("tlv header doesn't fit");
    }
    const type = decodeInt(data, start, 1);
    const length = decodeInt(data, start + 2, 2);

    if (start + 4 + length > end) {
      throw new Error("tlv value doesn't fit");
    }

    const value = data.subarray(start + 4, start + 4 + length);
    return new McuImageTLVEntry(type, length, value);
  }

  toString() {
    return (
      "MCUImageTLVEntry{type: " +
      this.type +
      ", length: " +
      this.length +
      ", value: " +
      this.value +
      "}"
    );
  }
}

export class McuImageVersion {
  major: number;
  minor: number;
  revision: number;
  build: number;

  constructor(major: number, minor: number, revision: number, build: number) {
    this.major = major;
    this.minor = minor;
    this.build = build;
    this.revision = revision;
  }

  static decode(data: Uint8Array) {
    return new McuImageVersion(
      decodeInt(data, 0, 1),
      decodeInt(data, 1, 1),
      decodeInt(data, 2, 2),
      decodeInt(data, 4, 4)
    );
  }

  toString() {
    return (
      "v" +
      this.major +
      "." +
      this.minor +
      "." +
      this.revision +
      "." +
      this.build
    );
  }
}

export class McuImage {
  header: McuImageHeader;
  tlv: McuImageTLV;

  constructor(header: McuImageHeader, tlv: McuImageTLV) {
    this.header = header;
    this.tlv = tlv;
  }

  static decode(data: Uint8Array) {
    const header = McuImageHeader.decode(data);

    //TODO: fix this

    console.log();

    const tlv = McuImageTLV.decode(data, header.headerSize + header.imageSize);

    return new McuImage(header, tlv);
    // return new McuImage(header, new McuImageTLV([]));
  }

  getHash() {
    for (const entry of this.tlv.entries) {
      if (entry.type === 0x10) {
        return entry.value;
      }
    }
  }
}
