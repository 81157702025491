import { gql, useQuery, useSubscription } from "@apollo/client";
import { useMemo } from "react";
import { AnimalReading } from "../animal/types";

export interface RawGroupAnimal {
  access: string;
  animalId: string;
  customTags?: string[];
  lastActive?: Date;
  animal: {
    name: string;
    sex: string;
    species: string;
    created: string;
    updated: string;
  };
  readings: {
    booleanValue: boolean;
    datetime: string;
    floatValue: number;
    intValue: number;
    stringValue: string;
    type: string;
  }[];
}

export interface GroupAnimal {
  access: string;
  animalId: string;
  customTags?: string[];
  lastActive: Date;
  animal: {
    name: string;
    sex: string;
    species: string;
    created: string;
    updated: string;
  };
  readings: AnimalReading[];
}

export const useGroupAnimalsQuery = `query GetGroupAnimals($groupId: ID!) {
  getAnimalByGroup(groupId: $groupId) {
    items {
      access
      animalId
      customTags
      animal {
        name
        sex
        species
        created
        updated
      }
      readings {
        type
        datetime
        floatValue
        stringValue
        booleanValue
        intValue
      }
    }
  }
}`;

export const useGroupAnimals = (groupId?: string) => {
  const { data, loading, refetch, ...query } = useQuery(
    gql(useGroupAnimalsQuery),
    {
      variables: { groupId },
    }
  );

  useSubscription(
    gql`
      subscription GroupAnimalSubscription($groupId: ID!) {
        onAnimalEventByGroup(groupId: $groupId) {
          access
          animalId
          created
          groupId
          customTags
          animal {
            animalId
            breed
            colour
            created
            dob
            implantHwVersion
            implantModel
            implantSwVersion
            microchip
            name
            notes
            photo
            sex
            species
            updated
            weight
          }
          notifications {
            animalId
            body
            data
            notificationType
            resolved
            timestamp
            title
          }
          readings {
            animalId
            booleanValue
            datetime
            floatValue
            intValue
            stringValue
            type
          }
          vetLinks {
            animalId
            created
            orgId
            orgName
            vetId
          }
        }
      }
    `,
    {
      variables: { groupId },
      onData: (data) => refetch(),
    }
  );

  useSubscription(
    gql`
      subscription RemoveGroupAnimalSubscription($groupId: ID!) {
        onRemoveAnimalEventByGroup(groupId: $groupId) {
          access
          animalId
          created
          groupId
        }
      }
    `,
    { variables: { groupId }, onData: (data) => refetch() }
  );

  const groupAnimals: GroupAnimal[] = useMemo(
    () =>
      data?.getAnimalByGroup?.items.map((a: RawGroupAnimal) => ({
        ...a,
        lastActive: a.readings.length
          ? a.readings.reduce<Date>(
              (prev, next) =>
                new Date(next.datetime) > prev ? new Date(next.datetime) : prev,
              new Date(a.readings[0]?.datetime)
            )
          : undefined,
        readings: a.readings.map((r: AnimalReading | { datetime: string }) => ({
          ...r,
          datetime: new Date(r.datetime),
        })),
      })) ?? [],
    [data]
  );

  return { loading, groupAnimals, ...query, refresh: refetch };
};
