import React, { FC } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
  Theme,
  useTheme,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import { useRemoveAnimal } from "../../hooks/animal/use-remove-animal";

export interface RemoveAnimalButtonProps {
  animalID: string;
  onDone: (data: any) => void;
  onError: (data: Error) => void;
}

export const RemoveAnimalButton: FC<RemoveAnimalButtonProps> = ({
  animalID,
  onDone,
  onError,
}) => {
  const { loading, execute } = useRemoveAnimal(animalID, onDone, onError);
  const [open, setOpen] = React.useState(false);
  const theme: Theme = useTheme();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle textAlign="center" color="error">
          Warning
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            color={theme.palette.mode === "dark" ? "white" : "black"}
          >
            This will delete the animal record from the database. All reading
            and notifications records will be lost.
            <p />
            The animal must be removed from any groups and must not be shared
            with any other users.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleClose();
              execute();
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Tooltip title="Delete animal record">
        <Button
          disabled={loading}
          startIcon={
            loading ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              <DeleteIcon />
            )
          }
          variant="contained"
          color="error"
          onClick={(e) => {
            e.stopPropagation();
            setOpen(true);
          }}
        >
          {loading ? "Deleting" : "Delete"}
        </Button>
      </Tooltip>
    </>
  );
};
