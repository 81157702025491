import { useMemo } from "react";
import { useQuery } from "../../graphql/Query";

export interface AnimalNotification {
  title: string;
  body: string;
  data: string;
  timestamp: string;
  notificationType: string;
  resolved: boolean;
}

export const useAnimalNotifications = (animalId: string) => {
  const { data, loading } = useQuery(
    ` query GetAnimalNotifications($animalId: String!) {
        getAnimalNotifications(animalId: $animalId) {
          items {
            body
            data
            notificationType
            resolved
            timestamp
            title
          }
        }
      }
      `,
    { animalId }
  );
  const notifications: AnimalNotification[] = useMemo(() => data?.getAnimalNotifications?.items ?? [], [data]);
  return { loading, notifications };
};
