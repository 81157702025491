import { Typography } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridLinkOperator,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import { map, startCase } from "lodash";
import { FC, useMemo } from "react";
import { useAnimalReadingsType } from "../../hooks/animal/use-animal-readings";
import { QuickSearchToolbar } from "../Common/QuickSearchToolbarReadings";
import { formatDate } from "../Utils";

export const logColumns: GridColDef[] = [
  {
    field: "type",
    headerName: "Type",
    type: "string",
    align: "left",
    headerAlign: "left",
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography variant="body2" sx={{ cursor: "pointer" }}>
          {startCase(params.value)}
        </Typography>
      );
    },
  },
  {
    field: "stringValue",
    headerName: "Log Message",
    headerAlign: "left",
    type: "string",
    align: "left",
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography
          variant="body2"
          sx={{ cursor: "pointer" }}
          component={"span"}
        >
          {params.value}
        </Typography>
      );
    },
  },
  {
    field: "datetime",
    headerName: "Date",
    type: "dateTime",
    align: "left",
    headerAlign: "left",
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography variant="body2" sx={{ cursor: "pointer" }}>
          {formatDate(params.value)}
        </Typography>
      );
    },
  },
];

export interface ImplantLogHistoryProps {
  animalId: string;
}

export const ImplantLogHistory: FC<ImplantLogHistoryProps> = ({
  animalId,
}) => {
  const { readings, loading } = useAnimalReadingsType(animalId, "logMsg");

  const rows = useMemo(() =>
    readings?.map((r) => ({
      id: r.datetime,
      key: r.datetime,
      ...r,
    })) ?? [], [readings]);

  if (loading) {
    return <p>loading...</p>;
  }

  return (
    <DataGrid
      columns={logColumns}
      rows={rows}
      initialState={{
        filter: {
          filterModel: {
            items: [],
            quickFilterLogicOperator: GridLinkOperator.Or,
          },
        },
      }}
      components={{ Toolbar: QuickSearchToolbar }}
    />
  );
};
