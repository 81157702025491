import { useMemo, useState } from "react";
import {
  Box,
  Button,
  Chip,
  Dialog,
  Divider,
  Paper,
  Skeleton,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { UserImplants } from "./UserImplants";
import { UserGroups } from "./UserGroups";
import { usePreviousUsers } from "../../hooks/use-prev-users";
import { UserSetting, useUserDetails } from "../../hooks/user/use-user-details";
import {
  CheckCircle,
  SentimentDissatisfiedOutlined,
  PersonOff,
} from "@mui/icons-material";
import { UserOrgs } from "./UserOrgs";
import { CopyText } from "../Common/CopyText";
import { UserLog } from "./UserLogs";
import { TabPanel, a11yProps } from "../Common/Tabs";
import { UserRoleIcons } from "../Common/UserRoleIcons";
import { ToggleUserEnabledRow } from "./ToggleUserEnabledRow";
import { ToggleUserRoleRow } from "./ToggleUserRoleRow";
import { ResendInviteButton } from "./ResendInviteButton";
import { UserAvatar } from "./UserAvatar";
import { UserAnalytics } from "./UserAnalytics";

export function UserDetails() {
  const { userid, email, pool } = useParams();
  const { loading, userDetails, refresh } = useUserDetails(userid, pool, email);
  const { append } = usePreviousUsers();

  useMemo(() => {
    if (userDetails) {
      append({ username: userid!, email: email!, pool: pool! });
    }
  }, [append, email, userid, pool, userDetails]);

  const userGroups = useMemo(() => userDetails?.groups ?? [], [userDetails]);
  const eulaSettings = useMemo(
    () =>
      (userDetails?.settings?.filter(
        (setting) => setting.setting === "terms-of-service-date"
      ) as UserSetting[]) ?? [],
    [userDetails]
  );

  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [editDialog, showEditDialog] = useState(false);

  return (
    <Stack
      spacing={2}
      sx={{ px: 1, py: 2 }}
      height="100%"
      justifyContent="stretch"
    >
      <Stack
        direction="row"
        spacing={2}
        justifyContent="start"
        sx={{ m: 2 }}
        alignItems="flex-start"
      >
        {loading ? (
          <Skeleton variant="circular" width={40} height={40} />
        ) : userDetails ? (
          <Stack spacing={1} justifyContent="flex-start" alignItems="center">
            <UserAvatar email={userDetails.email} />
            {pool === "professional" && !!userid && (
              <>
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => showEditDialog((c) => !c)}
                >
                  Edit
                </Button>
                <Dialog open={editDialog} onClose={() => showEditDialog(false)}>
                  <Box p={4}>
                    <Typography variant="h4">{email}</Typography>
                    <Typography variant="subtitle1" textAlign="center" mt={1}>
                      Edit User Permissions
                    </Typography>
                    <Divider sx={{ my: 2 }} />

                    <ToggleUserEnabledRow
                      label="User Enabled"
                      username={userid}
                      defaultChecked={userDetails?.enabled}
                      onDone={() => refresh()}
                    />

                    <ToggleUserRoleRow
                      label="Owner Role"
                      username={userid}
                      role="groupadmin"
                      defaultChecked={userDetails?.roles?.includes(
                        "groupadmin"
                      )}
                      onDone={() => refresh()}
                    />

                    <ToggleUserRoleRow
                      label="Vet Role"
                      username={userid}
                      role="vet"
                      defaultChecked={userDetails?.roles?.includes("vet")}
                      onDone={() => refresh()}
                    />

                    <ToggleUserRoleRow
                      label="Researcher Role"
                      username={userid}
                      role="researcher"
                      defaultChecked={userDetails?.roles?.includes(
                        "researcher"
                      )}
                      onDone={() => refresh()}
                    />

                    <ToggleUserRoleRow
                      label="Dev User (VetChip)"
                      username={userid}
                      role="dev"
                      defaultChecked={userDetails?.roles?.includes("dev")}
                      onDone={() => refresh()}
                    />

                    <ToggleUserRoleRow
                      label="Survey Admin"
                      username={userid}
                      role="surveyadmin"
                      defaultChecked={userDetails?.roles?.includes(
                        "surveyadmin"
                      )}
                      onDone={() => refresh()}
                    />

                    {!userDetails?.roles?.includes("groupuser") && (
                      <ToggleUserRoleRow
                        label="Professional Role (Required)"
                        username={userid}
                        role="groupuser"
                        defaultChecked={false}
                        onDone={() => refresh()}
                      />
                    )}

                    <Divider sx={{ my: 2 }} />

                    <Stack
                      direction="row"
                      spacing={2}
                      justifyContent="space-evenly"
                    >
                      <Button onClick={() => showEditDialog(false)}>
                        Close
                      </Button>
                    </Stack>
                  </Box>
                </Dialog>
              </>
            )}
          </Stack>
        ) : (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            width="100%"
            marginTop="5rem"
          >
            <SentimentDissatisfiedOutlined
              fontSize="large"
              color="error"
              sx={{ fontSize: "60pt", mr: 1 }}
            />
            <Typography variant="h3" color="error">
              User Not Found
            </Typography>
          </Stack>
        )}
        {loading ? (
          <>
            <Stack spacing={1}>
              <Skeleton width={400} height={30} />
              <Skeleton height={20} width={400} />{" "}
              <Stack
                direction="row"
                spacing={2}
                sx={{ mt: 2 }}
                alignItems="baseline"
              >
                {/* <Typography width="8rem">Account Status:{` `}</Typography> */}
                <Skeleton height={20} width="200px" />{" "}
              </Stack>
              <Stack
                direction="row"
                spacing={2}
                sx={{ mt: 2 }}
                alignItems="baseline"
              >
                {/* <Typography width="8rem">User Pool:{` `}</Typography> */}
                <Skeleton height={20} width="200px" />{" "}
              </Stack>
              <Stack
                direction="row"
                spacing={2}
                sx={{ mt: 2 }}
                alignItems="baseline"
              >
                {/* <Typography width="8rem">Roles:{` `}</Typography> */}
                <Skeleton height={20} width="200px" />{" "}
              </Stack>
            </Stack>
          </>
        ) : (
          !!userDetails && (
            <Stack
              direction="column"
              spacing={0.25}
              justifyContent="left"
              alignContent="center"
            >
              <Typography
                component="div"
                variant="h6"
                color="inherit"
                justifyContent="flex-start"
                align="left"
              >
                {email ? <CopyText>{email}</CopyText> : "Email not specified"}
              </Typography>
              <Typography
                variant="body2"
                color="inherit"
                align="left"
                component="div"
              >
                <CopyText>{userDetails?.username ?? ""}</CopyText>
              </Typography>
              <Stack
                direction="row"
                spacing={2}
                sx={{ mt: 2 }}
                alignItems="baseline"
              >
                <Typography width="8rem">Account Status:{` `}</Typography>

                <Typography variant="body2" component="div">
                  {userDetails?.status === "CONFIRMED" ? (
                    <Chip
                      size="small"
                      icon={<CheckCircle />}
                      color="success"
                      label={userDetails?.status}
                      variant="outlined"
                    />
                  ) : (
                    <Stack direction="row" spacing={1}>
                      <Chip
                        size="small"
                        icon={<PersonOff />}
                        color="warning"
                        label={userDetails?.status ?? "UNKNOWN"}
                        variant="outlined"
                      />
                      <ResendInviteButton
                        email={userDetails?.email}
                        onDone={() => {}}
                      />
                    </Stack>
                  )}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                spacing={2}
                sx={{ mt: 2 }}
                alignItems="baseline"
              >
                <Typography width="8rem">User Pool: </Typography>{" "}
                <Typography justifySelf="bottom" variant="body2">
                  {pool?.toUpperCase()}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                spacing={2}
                sx={{ mt: 2 }}
                alignItems="baseline"
              >
                <Typography width="8rem">Roles:</Typography>
                <Typography variant="body2" component="div">
                  <UserRoleIcons pool={pool} roles={userDetails.roles} />
                </Typography>
              </Stack>
              <Stack
                direction="row"
                spacing={2}
                sx={{ mt: 2 }}
                alignItems="baseline"
              >
                <Typography width="8rem">Accepted EULA: </Typography>{" "}
                <Typography justifySelf="bottom" variant="body2">
                  {eulaSettings[0]?.value ?? "-"}
                </Typography>
              </Stack>
            </Stack>
          )
        )}
      </Stack>
      {userDetails && (
        <>
          <Divider sx={{ mt: 4 }} />
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={value} onChange={handleChange} aria-label="">
              <Tab label="Implants" {...a11yProps(0)} />

              <Tab label="Groups" {...a11yProps(0)} />

              <Tab label="Organisations" {...a11yProps(0)} />
              <Tab label="Activity" {...a11yProps(0)} />
              <Tab label="Logs" {...a11yProps(0)} />
            </Tabs>
          </Box>

          <TabPanel value={value} index={0} flex="1" m={0} pb={2}>
            <Paper sx={{ width: "100%", height: "100%", p: 2 }}>
              {loading || !userDetails ? (
                <Skeleton variant="rectangular" height="30rem" sx={{ m: 3 }} />
              ) : (
                <UserImplants userGroups={userGroups} />
              )}
            </Paper>
          </TabPanel>

          <TabPanel value={value} index={1} flex="1" m={0} pb={2}>
            <Paper sx={{ width: "100%", height: "100%", p: 2 }}>
              {loading || !userDetails ? (
                <Skeleton variant="rectangular" height="30rem" sx={{ m: 3 }} />
              ) : (
                <UserGroups user={userDetails} />
              )}
            </Paper>
          </TabPanel>

          <TabPanel value={value} index={2} flex="1" m={0} pb={2}>
            <Paper sx={{ width: "100%", height: "100%", p: 2 }}>
              {loading || !userDetails ? (
                <Skeleton variant="rectangular" height="30rem" sx={{ m: 3 }} />
              ) : (
                <UserOrgs
                  user={userDetails}
                  userid={userDetails.username}
                  pool={pool ?? "professional"}
                />
              )}
            </Paper>
          </TabPanel>

          <TabPanel
            value={value}
            index={3}
            flex="1"
            m={0}
            pb={2}
            overflow="auto"
          >
            <Paper sx={{ width: "100%", height: "100%", p: 2 }}>
              {pool && userid && <UserLog userpool={pool} username={userid} />}
            </Paper>
          </TabPanel>

          <TabPanel
            value={value}
            index={4}
            flex="1"
            m={0}
            pb={2}
            overflow="auto"
          >
            <Paper sx={{ width: "100%", height: "100%", p: 2 }}>
              {pool && userid && <UserAnalytics username={userid} />}
            </Paper>
          </TabPanel>
        </>
      )}
    </Stack>
  );
}
