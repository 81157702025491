import { FC, Fragment, useMemo, useState } from "react";
import { useAnimalReadingsType } from "../../hooks/animal/use-animal-readings";
import { sortBy, groupBy } from "lodash";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  LinearProgress,
  Typography,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { AnimalReading } from "../../hooks/animal/types";
import { formatRelativeDate } from "../Utils";

export const GroupSurveyManualReadingDates: FC<{
  animalId: string;
}> = ({ animalId }) => {
  const { lastManualReading, history, loading } =
    useSurveyManualReadings(animalId);

  const [open, setOpen] = useState(false);

  if (loading) {
    return <CircularProgress />;
  }

  if (!lastManualReading) return <Box>-</Box>;

  const daysAgo =
    (Date.now() - new Date(lastManualReading).getTime()) /
    (24 * 60 * 60 * 1000);

  return (
    <Box
      display="flex"
      alignItems="center"
      onClick={() => setOpen(true)}
      sx={{ cursor: "pointer" }}
    >
      <Typography
        variant="body2"
        sx={
          daysAgo > 4
            ? { backgroundColor: "red", color: "white", padding: "0.2rem" }
            : daysAgo > 2
            ? { backgroundColor: "amber", color: "white", padding: "0.2rem" }
            : undefined
        }
      >
        {formatRelativeDate(lastManualReading)}
      </Typography>
      <Dialog open={open} fullWidth={true}>
        <DialogTitle textAlign="center">Manual Readings</DialogTitle>
        <DialogContent>
          <Box maxHeight="20rem" sx={{ overflowY: "auto" }}>
            {loading && <LinearProgress />}
            <Grid2 container>
              {Object.keys(history ?? {})?.map((h) => (
                <Fragment key={`manual-reading-${h}`}>
                  <Grid2 xs={12}>
                    <strong>{h}</strong>
                  </Grid2>
                  <Grid2 xs={12}>
                    <Divider />
                  </Grid2>
                  {history?.[h].map((r, idx) => (
                    <Fragment key={`manual-reading-readings-${h}-${idx}`}>
                      <Grid2 xs={6}>
                        <strong>{r.type.substring(7)}</strong>
                      </Grid2>
                      <Grid2 xs={6}>{r.stringValue}</Grid2>
                      <Grid2 xs={12}>
                        <Divider />
                      </Grid2>
                    </Fragment>
                  ))}
                  <Grid2 xs={12}>&nbsp;</Grid2>
                </Fragment>
              ))}
            </Grid2>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            disabled={loading}
            onClick={(e) => {
              setOpen(false);
              e.stopPropagation();
            }}
            color="info"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

const useSurveyManualReadings = (animalId: string) => {
  const { readings: tempReadings, loading: tempLoading } =
    useAnimalReadingsType(animalId, "Manual-Temperature");
  const { readings: heartRateReadings, loading: heartRateLoading } =
    useAnimalReadingsType(animalId, "Manual-HeartRate");
  const { readings: respRateReadings, loading: respRateLoading } =
    useAnimalReadingsType(animalId, "Manual-RespiratoryRate");

  const [history, loading] = useMemo(() => {
    if (!tempLoading && !heartRateLoading && !respRateLoading) {
      return [
        groupBy(
          sortBy(
            [...tempReadings, ...heartRateReadings, ...respRateReadings],
            ["datetime", "type"]
          ),
          (a) =>
            new Date(a.datetime).toLocaleString([], {
              day: "numeric",
              month: "2-digit",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            })
        ) as Record<string, AnimalReading[]>,
        false,
      ];
    }
    return [undefined, true];
  }, [
    tempReadings,
    tempLoading,
    heartRateReadings,
    heartRateLoading,
    respRateReadings,
    respRateLoading,
  ]);

  const lastManualReading = useMemo(
    () =>
      history
        ? history[Object.keys(history)?.pop() ?? ""]?.[0]?.datetime
        : undefined,
    [history]
  );

  return { lastManualReading, history, loading };
};
