import React, { FC } from "react";
import { MonitorHeart, QuestionMark, Thermostat } from "@mui/icons-material";
import { Chip } from "@mui/material";

export const ImplantHwVersionIcon: FC<{ hwVersion: string }> = ({
  hwVersion,
}) => {
  return (
    <Chip
      variant="outlined"
      icon={
        hwVersion === "unknown" ? (
          <QuestionMark />
        ) : hwVersion.toLowerCase().includes("temperature") ? (
          <Thermostat />
        ) : (
          <MonitorHeart />
        )
      }
      label={hwVersion}
    />
  );
};
