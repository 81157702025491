import { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import {
  Alert,
  AlertTitle,
  Avatar,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  LinearProgress,
  Skeleton,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import {
  AddCircleOutline,
  CheckCircle,
  Key,
  KeyOff,
  Lock,
  RemoveCircleOutline,
  SentimentDissatisfied,
  Sms,
  SupportAgent,
  Unpublished,
} from "@mui/icons-material";

import "@aws-amplify/ui-react/styles.css";
import { UserAvatar } from "../Users/UserAvatar";
// import {QRCode} from "react-qr-code";
import { CopyText } from "./CopyText";
import { MuiOtpInput } from "mui-one-time-password-input";
import QRCode from "react-qr-code";

export interface AdminUserAttrs {
  sub: string;
  email_verified: Boolean;
  email: string;
}

enum MFAStep {
  scan,
  confirm,
  loading,
  done,
}

function matchIsNumeric(text: string | number) {
  if (typeof text === "number") {
    return true;
  }

  if (typeof text === "string") {
    if (text !== "" && !isNaN(Number(text))) {
      return true;
    }
  }

  return false;
}

const validateChar = (value: string | number, index: any) => {
  return matchIsNumeric(value) || value === "";
};

export function ProfileInfo() {
  const [attributes, setAttributes] = useState<AdminUserAttrs>();
  const [username, setUsername] = useState<string>();
  const [token, setToken] = useState<string | undefined>();
  const [showQRDialog, setShowQrDialog] = useState<boolean>(false);
  const [mfaCode, setMfaCode] = useState<string | undefined>(undefined);
  const [mfa, setMFA] = useState<string>();
  const [errorMsg, setErrorMsg] = useState<string | undefined>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [step, setStep] = useState<MFAStep>(MFAStep.scan);

  useEffect(() => {
    // load auth attributes...
    (async () => {
      try {
        setLoading(true);
        const user = await Auth.currentAuthenticatedUser();
        setAttributes(() => user.attributes as AdminUserAttrs);
        setUsername(user.username);
        setMFA(() => user.preferredMFA);
        setErrorMsg(undefined);
      } catch (e) {
        setError(true);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  async function setupTotpMFA() {
    const user = await Auth.currentAuthenticatedUser();
    // await Auth.setPreferredMFA(user, "TOTP");
    const retToken = await Auth.setupTOTP(user);
    setErrorMsg(undefined);
    setToken(retToken);
    if (retToken !== undefined) {
      setShowQrDialog(true);
    }
  }

  async function verifyMfa(code: string) {
    const user = await Auth.currentAuthenticatedUser();
    try {
      setStep(MFAStep.loading);
      const userSesh = await Auth.verifyTotpToken(user, code ?? "");
      const ret = await Auth.setPreferredMFA(user, "TOTP");
      setStep(MFAStep.done);
      const mfaStr = await Auth.getPreferredMFA(user);
      setMFA(mfaStr);
    } catch (e) {
      setErrorMsg("Error: Could not verify code");
      setMfaCode(undefined);
      setStep(MFAStep.confirm);
    }
  }

  async function removeMfa() {
    const user = await Auth.currentAuthenticatedUser();
    try {
      setStep(MFAStep.loading);
      const ret = await Auth.setPreferredMFA(user, "NOMFA");
      setErrorMsg(undefined);
      setStep(MFAStep.done);
      setErrorMsg(undefined);
      const mfaStr = await Auth.getPreferredMFA(user);
      setMFA(mfaStr);
    } catch (e) {
      setErrorMsg("Error: could not remove MFA method.");
    }
  }

  return (
    <div>
      {loading ? (
        <div>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="start"
            sx={{ m: 2 }}
          >
            <Skeleton variant="circular" width={40} height={40} />
            <Stack direction="column" spacing={1}>
              <Skeleton variant="text" width={250} sx={{ fontSize: "1rem" }} />
              <Skeleton
                variant="text"
                width={250}
                sx={{ fontSize: "0.8rem" }}
              />
            </Stack>
          </Stack>
          <Divider />
          <Skeleton variant="text" width={80} height={40} sx={{ m: 2 }} />
        </div>
      ) : (
        <div>
          {error ? (
            <Stack
              direction="row"
              spacing={2}
              justifyContent="start"
              sx={{ m: 2 }}
            >
              <SentimentDissatisfied color="error" />
              <Typography variant="h6">
                Oops! Something went wrong. Try reloading the page.
              </Typography>
            </Stack>
          ) : (
            <div>
              <Stack
                direction="row"
                spacing={2}
                justifyContent="start"
                sx={{ m: 2 }}
              >
                <IconButton>
                  {loading ? (
                    <Avatar />
                  ) : (
                    <UserAvatar email={attributes?.email ?? ""} />
                  )}
                </IconButton>
                <Stack direction="column" spacing={1}>
                  {" "}
                  <Typography
                    variant="h6"
                    color="inherit"
                    component="div"
                    align="left"
                  >
                    {attributes?.email}
                  </Typography>
                  <Typography variant="body2" color="inherit" component="div">
                    {attributes?.sub}
                  </Typography>
                </Stack>
              </Stack>
              <TableContainer>
                <Table size="small" sx={{ maxWidth: "40%" }}>
                  <TableBody>
                    <TableRow sx={{ "& td": { border: 0 } }}>
                      <TableCell>
                        <Typography>Account type: </Typography>
                      </TableCell>
                      <TableCell>
                        <Chip
                          label="Support"
                          variant="outlined"
                          icon={<SupportAgent />}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow sx={{ "& td": { border: 0 } }}>
                      <TableCell>
                        <Typography>Account status:</Typography>
                      </TableCell>
                      <TableCell>
                        {attributes?.email_verified ? (
                          <Chip
                            icon={<CheckCircle />}
                            color="success"
                            label="Verified"
                            variant="outlined"
                          />
                        ) : (
                          <Chip
                            icon={<Unpublished />}
                            color="warning"
                            label="Not Verified"
                            variant="outlined"
                          />
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow sx={{ "& td": { border: 0 } }}>
                      <TableCell>
                        <Typography>MFA:</Typography>
                      </TableCell>
                      <TableCell>
                        <div>
                          {mfa === "NOMFA" ? (
                            <Stack direction="row">
                              <Chip
                                icon={<KeyOff />}
                                color="error"
                                label="No MFA"
                                variant="outlined"
                              />
                              <Button
                                variant="contained"
                                color="success"
                                sx={{ ml: 2 }}
                                startIcon={<AddCircleOutline />}
                                onClick={() => {
                                  if (!showQRDialog) {
                                    // setShowQrDialog(true);
                                    setStep(MFAStep.scan);
                                    setMfaCode("");
                                    setErrorMsg(undefined);
                                    setupTotpMFA();
                                  }
                                }}
                              >
                                Add
                              </Button>
                            </Stack>
                          ) : (
                            <Stack direction="row">
                              <Chip
                                icon={
                                  mfa === "SOFTWARE_TOKEN_MFA" ? (
                                    <Lock />
                                  ) : mfa === "SMS_MFA" ? (
                                    <Sms />
                                  ) : (
                                    <Key />
                                  )
                                }
                                color="info"
                                label={
                                  mfa === "SOFTWARE_TOKEN_MFA"
                                    ? "Software Token"
                                    : mfa === "SMS_MFA"
                                    ? "SMS"
                                    : mfa
                                }
                                variant="outlined"
                              />
                              <Button
                                variant="contained"
                                color="error"
                                sx={{ ml: 2 }}
                                startIcon={
                                  step === MFAStep.loading ? (
                                    <CircularProgress
                                      size={"1rem"}
                                      color="inherit"
                                    />
                                  ) : (
                                    <RemoveCircleOutline />
                                  )
                                }
                                onClick={() => {
                                  if (!showQRDialog) {
                                    // setShowQrDialog(true);
                                    removeMfa();
                                    setErrorMsg(undefined);
                                  }
                                }}
                              >
                                Remove
                              </Button>
                            </Stack>
                          )}
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <Dialog
                open={showQRDialog}
                onClose={() => {
                  setShowQrDialog(false);
                }}
              >
                <DialogTitle textAlign="center">
                  Add Authenticator App MFA
                </DialogTitle>
                {step === MFAStep.scan && (
                  <DialogContent>
                    <DialogContentText>
                      Scan the QR Code with your authenticator app, or manually
                      add the token.
                    </DialogContentText>
                    <div
                      style={{
                        height: "auto",
                        margin: "0 auto",
                        maxWidth: 160,
                        width: "100%",
                      }}
                    >
                      <br />
                      {token !== undefined ? (
                        <div
                          style={{
                            background: "white",
                            padding: "16px",
                          }}
                        >
                          <QRCode
                            size={512}
                            style={{
                              height: "auto",
                              maxWidth: "100%",
                              width: "100%",
                            }}
                            value={
                              window.location.href.includes("dev")
                                ? `otpauth://totp/VetChip Admin (SANDBOX):${attributes?.email}?secret=${token}&issuer=https://admin-dev.vetchip.com.au/`
                                : window.location.href.includes("localhost")
                                ? `otpauth://totp/VetChip Admin (LOCALHOST):${attributes?.email}?secret=${token}&issuer=https://admin-dev.vetchip.com.au/`
                                : `otpauth://totp/VetChip Admin:${attributes?.email}?secret=${token}&issuer=https://admin.vetchip.com.au/`
                            }
                            viewBox={`0 0 512 512`}
                          />
                          <br />
                        </div>
                      ) : (
                        <Typography variant="subtitle1" color="error">
                          An error occurred
                        </Typography>
                      )}
                    </div>
                    <br />

                    {token && (
                      <DialogContentText>
                        {<CopyText>{token!}</CopyText>}
                      </DialogContentText>
                    )}
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        pb: 2,
                        pt: 2,
                      }}
                    >
                      <Box sx={{ flex: "1 1 auto" }} />
                      <Button
                        variant="contained"
                        sx={{ mr: 1 }}
                        onClick={() => {
                          setStep(MFAStep.confirm);
                        }}
                      >
                        Next
                      </Button>{" "}
                      <Box sx={{ flex: "1 1 auto" }} />
                    </Box>
                  </DialogContent>
                )}
                {step === MFAStep.confirm && (
                  <DialogContent>
                    <DialogContentText textAlign="center">
                      Enter the code generated by the authenticator app.
                    </DialogContentText>
                    <div
                      style={{
                        height: "auto",
                        margin: "0 auto",
                        maxWidth: "80%",
                        width: "100%",
                      }}
                    >
                      <br />
                      <MuiOtpInput
                        length={6}
                        value={mfaCode?.toString()}
                        validateChar={validateChar}
                        autoFocus
                        onChange={(value) => {
                          setErrorMsg(undefined);
                          setMfaCode(value);
                        }}
                        onComplete={(value) => {
                          verifyMfa(value);
                        }}
                      />
                    </div>
                  </DialogContent>
                )}

                {step === MFAStep.loading && (
                  <DialogContent>
                    <DialogContentText textAlign="center">
                      Enter the code generated by the authenticator app.
                    </DialogContentText>
                    <div
                      style={{
                        height: "auto",
                        margin: "0 auto",
                        maxWidth: "80%",
                        width: "100%",
                      }}
                    >
                      <br />
                      <LinearProgress />
                    </div>
                  </DialogContent>
                )}

                {step === MFAStep.done && (
                  <DialogContent>
                    <div
                      style={{
                        height: "auto",
                        margin: "0 auto",
                        maxWidth: "80%",
                        width: "100%",
                      }}
                    >
                      <Typography>Success!</Typography>
                      <br />
                      <Typography>
                        {`Multi-factor authentication is now enabled for ${
                          attributes?.email ?? "this account"
                        }.`}
                      </Typography>
                    </div>
                    <DialogActions>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          pb: 2,
                          pt: 2,
                        }}
                      >
                        <Box sx={{ flex: "1 1 auto" }} />
                        <Button
                          variant="contained"
                          sx={{ mr: 1 }}
                          onClick={() => {
                            setShowQrDialog(false);
                          }}
                        >
                          Ok
                        </Button>{" "}
                        <Box sx={{ flex: "1 1 auto" }} />
                      </Box>
                    </DialogActions>
                  </DialogContent>
                )}

                {!!errorMsg && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      pb: 2,
                      pt: 2,
                    }}
                  >
                    <Box sx={{ flex: "1 1 auto" }} />
                    <Alert severity="error" sx={{ flex: "2 2 auto" }}>
                      <AlertTitle>An error occurred</AlertTitle>
                      {errorMsg}
                    </Alert>
                    <Box sx={{ flex: "1 1 auto" }} />
                  </Box>
                )}
                <Stepper
                  activeStep={step}
                  sx={{ marginLeft: 5, marginRight: 5 }}
                >
                  <Step>
                    <StepLabel>Add</StepLabel>
                  </Step>
                  <Step>
                    <StepLabel>Verify</StepLabel>
                  </Step>
                  <Step>
                    <StepLabel>Done</StepLabel>
                  </Step>
                </Stepper>
                <br />
              </Dialog>

              <Box sx={{ width: "100%", height: "100%" }}>
                <Stack
                  direction="column"
                  spacing={2}
                  justifyContent="start"
                  sx={{ p: 2 }}
                >
                  <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="start"
                    alignContent="center"
                  >
                    {}
                  </Stack>
                </Stack>
              </Box>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
