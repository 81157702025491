import { FC, Fragment, useMemo, useState } from "react";
import { useAnimalReadingsType } from "../../hooks/animal/use-animal-readings";
import { sortBy } from "lodash";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  LinearProgress,
  Typography,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { GroupSurveyComment } from "./GroupSurveyComment";
import { MultiLineText } from "./GroupSurveyNotes";

export const GroupSurveyHealthStatus: FC<{
  animalId: string;
}> = ({ animalId }) => {
  const { lastHealth, history, loading } = useSurveyHealthStatus(animalId);

  const {
    readings: comments,
    loading: loadingComments,
    reload: reloadComments,
  } = useAnimalReadingsType(animalId, "Survey-Notes");

  const [open, setOpen] = useState(false);

  if (loading) {
    return <CircularProgress />;
  }

  if (!lastHealth) return <Box>-</Box>;

  return (
    <Box onClick={() => setOpen(true)} sx={{ cursor: "pointer" }}>
      <strong>{new Date(lastHealth?.datetime).toLocaleDateString()}</strong>
      <br />
      {lastHealth.stringValue}
      <Dialog open={open} fullWidth={true}>
        <DialogTitle textAlign="center">
          Responses to is your animal healthy?
        </DialogTitle>
        <DialogContent>
          <Box maxHeight="10rem" sx={{ overflowY: "auto" }} mb="1rem">
            {loading && <LinearProgress />}
            <Grid2 container>
              {history?.map((h, idx) => (
                <Fragment key={`healthystatus-${idx}`}>
                  <Grid2 xs={3}>
                    <strong>{new Date(h.datetime).toLocaleDateString()}</strong>
                  </Grid2>
                  <Grid2 xs={9}>
                    <MultiLineText text={h.stringValue} />
                  </Grid2>
                  <Grid2 xs={12}>
                    <Divider />
                  </Grid2>
                </Fragment>
              ))}
            </Grid2>
          </Box>
          {comments?.length > 0 && (
            <>
              <Typography variant="h6" textAlign="center" my="0.5rem">
                Health Notes
              </Typography>
              <Box maxHeight="10rem" sx={{ overflowY: "auto" }}>
                {loadingComments && <LinearProgress />}
                <Grid2 container>
                  {comments?.map((h, idx) => (
                    <Fragment key={`note-${idx}`}>
                      <Grid2 xs={3}>
                        <strong>
                          {new Date(h.datetime).toLocaleDateString()}
                        </strong>
                      </Grid2>
                      <Grid2 xs={9}>
                        <MultiLineText text={h.stringValue} />
                      </Grid2>
                      <Grid2 xs={12}>
                        <Divider />
                      </Grid2>
                    </Fragment>
                  ))}
                </Grid2>
              </Box>
            </>
          )}
          <GroupSurveyComment
            animalId={animalId}
            onAdd={() => reloadComments()}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            disabled={loading}
            onClick={(e) => {
              setOpen(false);
              e.stopPropagation();
            }}
            color="info"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

const useSurveyHealthStatus = (animalId: string) => {
  const { readings, loading } = useAnimalReadingsType(
    animalId,
    "Daily-Healthy"
  );

  const history = useMemo(() => {
    if (!loading) {
      return sortBy(readings, "datetime");
    }
    return undefined;
  }, [readings, loading]);

  const lastHealth = useMemo(
    () => history?.filter((r) => r.stringValue !== "Yes")?.pop(),
    [history]
  );

  return { lastHealth, history, loading };
};
