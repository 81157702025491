import { FC, PropsWithChildren } from "react";
import { ContentCopy } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import { CopyToClipboard } from "react-copy-to-clipboard";

export interface CopyTextProps {
  size?: string | number;
}

export const CopyText: FC<CopyTextProps & PropsWithChildren> = ({ children, size = "125%" }) => {
  return (
    <Box display="inline-flex" alignItems="center">
      <Box>{children}</Box>{" "}
      <CopyToClipboard text={children as string}>
        <Button
          sx={{ minWidth: "20px" }}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <ContentCopy sx={{ fontSize: size }} />
        </Button>
      </CopyToClipboard>
    </Box>
  );
};
