import { GroupAdd } from "@mui/icons-material";
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Tooltip,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useLinkAnimalToGroup } from "../../hooks/group/use-link-animal-to-group";
import { HistoryType, TextFieldHistory } from "./TextFieldHistory";

export const LinkGroupToAnimalButton: FC<{
  animalId: string;
  animalName?: string;
}> = ({ animalId, animalName }) => {
  const [errorMsg, setErrorMsg] = useState("");
  const [open, setOpen] = useState(false);
  const [groupId, setgroupId] = useState<string>("");

  const { loading, execute } = useLinkAnimalToGroup(
    groupId,
    animalId,
    () => {
      setOpen(false);
    },
    (error) => {
      setErrorMsg(error?.message ?? "An error occurred!");
    }
  );

  useEffect(() => {
    setErrorMsg("");
  }, [groupId]);

  return (
    <div>
      <Tooltip title="Link this animal to a group">
        <Button
          variant="contained"
          onClick={() => {
            setOpen(!open);
          }}
          startIcon={<GroupAdd />}
        >
          Link Group
        </Button>
      </Tooltip>
      <Dialog open={open} fullWidth>
        <DialogTitle textAlign="center">Link Animal to Group</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>
            {`Link "${animalName ?? animalId}" to a group.`}
          </DialogContentText>
          {/* <TextField
            autoFocus
            id="groupid"
            label={`Group ID`}
            onChange={(event) => {
              setgroupId(event.target.value);
            }}
            fullWidth
          ></TextField> */}
          <TextFieldHistory
            type={HistoryType.group}
            onSelect={(data) => {
              setgroupId(data);
            }}
          />
        </DialogContent>
        {errorMsg !== "" && (
          <Alert
            sx={{ m: 2 }}
            severity="error"
            onClose={() => {
              setErrorMsg("");
            }}
          >
            {errorMsg}
          </Alert>
        )}
        <DialogActions sx={{ justifyContent: "space-between", p: 2 }}>
          <Button
            variant="contained"
            disabled={loading}
            onClick={() => {
              setOpen(false);
            }}
            color="error"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={loading || animalId === null}
            onClick={() => {
              execute();
            }}
            color="info"
          >
            {!loading ? "OK" : <CircularProgress size={20} color="info" />}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
