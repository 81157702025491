import { Stack, Typography, Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { Refresh } from "@mui/icons-material";
import { ReactNodeLike } from "prop-types";

export function QuickSearchToolbar(props: {
  animalId: string;
  readingType: string;
  numRowsSelected?: number;
  onDelete?: () => void;
  onReload?: () => void;
  buttons: ReactNodeLike;
}) {
  return (
    <Stack
      spacing={1}
      direction="row"
      sx={{
        p: 0.5,
        pb: 0,
        pt: 1,
        flex: 1,
      }}
    >
      <Stack direction="row" justifyContent="start">
        {!!props.onReload && (
          <GridToolbarContainer sx={{ flex: 2 }}>
            <Button
              size="small"
              onClick={() => {
                props.onReload && props.onReload();
              }}
              aria-label="Reload Data"
              startIcon={<Refresh />}
            >
              Reload
            </Button>
          </GridToolbarContainer>
        )}
        <GridToolbarContainer sx={{ flex: 2 }}>
          <GridToolbarDensitySelector />
        </GridToolbarContainer>
        <GridToolbarContainer sx={{ flex: 2 }}>
          <GridToolbarFilterButton />
        </GridToolbarContainer>
        <GridToolbarContainer sx={{ flex: 2 }}>
          <GridToolbarExport
            csvOptions={{
              fileName: `${props.animalId}_${props.readingType}_exported`,
            }}
          />
        </GridToolbarContainer>
        {props.buttons}
      </Stack>
      {!!props.numRowsSelected && !!props.onDelete && (
        <Stack direction="row" justifyContent="end" sx={{ flex: 1, pr: 1 }}>
          <Button
            variant="outlined"
            size="small"
            color="error"
            onClick={() => {
              props.onDelete && props.onDelete();
            }}
            aria-label="delete selected rows"
          >
            <DeleteIcon />
            <Typography color="inherit" component="span" variant="subtitle2">
              Delete {props.numRowsSelected} reading
              {props.numRowsSelected > 1 ? "s" : ""}
            </Typography>
          </Button>
        </Stack>
      )}
    </Stack>
  );
}
