import { Link } from "@mui/icons-material";
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  Tooltip,
} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { FC, useEffect, useState } from "react";
import { useLinkAnimalToGroup } from "../../hooks/group/use-link-animal-to-group";
import { HistoryType, TextFieldHistory } from "../Widgets/TextFieldHistory";

export const LinkGroupAnimalButton: FC<{
  groupId: string;
  groupName?: string;
}> = ({ groupId, groupName }) => {
  const [errorMsg, setErrorMsg] = useState("");
  const [open, setOpen] = useState(false);
  const [animalId, setAnimalId] = useState<string>("");

  const { loading, execute } = useLinkAnimalToGroup(
    groupId,
    animalId,
    () => {
      setOpen(false);
    },
    (error) => {
      setErrorMsg(error?.message ?? "An error occurred!");
    }
  );

  useEffect(() => {
    setErrorMsg("");
  }, [animalId]);

  return (
    <div>
      <Tooltip title="Link animal to this group">
        <Button
          variant="contained"
          onClick={() => {
            setOpen(!open);
          }}
          startIcon={<Link />}
        >
          Link Animal
        </Button>
      </Tooltip>
      <Dialog open={open} fullWidth>
        <DialogTitle textAlign="center">Link Animal to Group</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>
            {`Link animal to "${groupName ?? groupId}".`}
          </DialogContentText>
          {/* <TextField
            autoFocus
            id="animalId"
            label={`Animal ID`}
            onChange={(event) => {
              setAnimalId(event.target.value);
            }}
            fullWidth
          ></TextField> */}
          <TextFieldHistory
            type={HistoryType.implant}
            onSelect={(data) => {
              setAnimalId(data);
            }}
          />
        </DialogContent>
        {errorMsg !== "" && (
          <Alert
            sx={{ m: 2 }}
            severity="error"
            onClose={() => {
              setErrorMsg("");
            }}
          >
            {errorMsg}
          </Alert>
        )}
        <DialogActions sx={{ justifyContent: "space-between", p: 2 }}>
          <Button
            variant="contained"
            disabled={loading}
            onClick={() => {
              setOpen(false);
            }}
            color="error"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={loading || animalId === null}
            onClick={() => {
              execute();
            }}
            color="info"
          >
            {!loading ? "OK" : <CircularProgress size={20} color="info" />}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
